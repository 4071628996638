import React from "react";
import { MenuItem, TextField } from "@material-ui/core";

const LanguageSelect = (props) => {
  const { form } = props;

  return (
    <TextField
      select
      fullWidth
      name="language"
      label="Default Language"
      variant="outlined"
      id="language"
      value={form.values.language}
      onChange={form.handleChange}
    >
      <MenuItem value={"english"}>English</MenuItem>
      <MenuItem value={"spanish"}>Spanish</MenuItem>
      <MenuItem value={"russian"}>Russian</MenuItem>
      <MenuItem value={"french_creole"}>French Creole</MenuItem>
      <MenuItem value={"italian"}>Italian</MenuItem>
      <MenuItem value={"hindi"}>Hindi</MenuItem>
      <MenuItem value={"bengali"}>Bengali</MenuItem>
    </TextField>
  );
};

export default LanguageSelect;
