import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {},

  agentChatWrapper: {
    margin: "0px 16px",
    padding: 8,
    borderRadius: "3px 24px 24px 24px",
    fontSize: 14,
  },
  customerChatWrapper: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "24px 3px 24px 24px",
    margin: "0px 16px",
    padding: 8,
    paddingBottom: 50,
    fontSize: 14,
    alignSelf: "flex-end",
  },
  chatBlurbWrap: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 18,
    maxWidth: 300,
  },
  facilityChatBlurb: {
    display: "inline-block",
    backgroundColor: "rgb(239, 241, 251)",
    padding: 12,
    borderRadius: "0 24px 24px 24px",
    fontSize: 16,
    alignSelf: "flex-start",
  },
  customerChatBlurb: {
    display: "inline-block",
    borderRadius: "24px 0 24px 24px",
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    fontSize: 16,
    color: "#FFFFFF",
    maxWidth: 300,
    alignSelf: "flex-end",
  },
  messageMeta: {
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 10,

    "& .MuiTypography-root": {
      color: "rgba(0,0,0,0.63)",
      fontSize: 13,
    },
  },
  messageBody: {
    overflowWrap: "break-word",
    overflow: "auto",
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: "red",
  },
}));
