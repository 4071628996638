import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import { KeyboardArrowLeft, KeyboardArrowRight } from "../../../icons";

const useArrowStyles = makeStyles((theme) => ({
  arrow: {
    "&.slick-prev": {
      left: "-3rem",
    },
    "&.slick-next": {
      right: 0,
    },

    "&.slick-disabled": {
      opacity: 0.2,
    },

    "&.slick-next, &.slick-prev": {
      color: "#000",
      width: "2em",
      height: "2em",
    },
    "&.slick-next:before, &.slick-prev:before": {
      content: "none",
    },
    "& svg": {
      width: "2em",
      height: "auto",
    },
  },
}));

export function NextArrow(props) {
  const { className, style, onClick } = props;
  const classes = useArrowStyles();

  return (
    <div className={clsx(className, classes.arrow)} style={{ ...style }} onClick={onClick}>
      <KeyboardArrowRight />
    </div>
  );
}

export function PrevArrow(props) {
  const { className, style, onClick } = props;
  const classes = useArrowStyles();

  return (
    <div className={clsx(className, classes.arrow)} style={{ ...style }} onClick={onClick}>
      <KeyboardArrowLeft />
    </div>
  );
}
