import React, { useCallback, useMemo, useRef, useState } from "react";
import { Paper, LinearProgress, TableRow, Box, Button } from "@material-ui/core";
import saveAs from "file-saver";
import {
  RowDetailState,
  PagingState,
  SortingState,
  SearchState,
  FilteringState,
  IntegratedPaging,
  CustomPaging,
  IntegratedSorting,
  IntegratedFiltering,
  DataTypeProvider,
  Column,
  IntegratedSelection,
  Filter,
} from "@devexpress/dx-react-grid";
import { SelectionState } from "@devexpress/dx-react-grid";
import {
  Grid,
  Table as GridTable,
  TableHeaderRow,
  TableColumnVisibility,
  ColumnChooser,
  Toolbar,
  TableRowDetail,
  PagingPanel,
  TableSelection,
} from "@devexpress/dx-react-grid-material-ui";
import { GridExporter } from "@devexpress/dx-react-grid-export";

import { renderActionsCell } from "../TableActionsCell";
import {
  ROWS_PER_PAGE_OPTIONS,
  TABLE_DEFAULT_SORTING,
  DEFAULT_SORTING_EXTENSIONS,
  TABLE_COLUMN_EXTENSIONS_DEFAULT,
} from "../../../constants";
import { RouterLink } from "../../../lib";
import { getRowId, currencyFormatter } from "../../../utils";
import ExportButton from "../../buttons/ExportButton";
import { ArrowDropDownIcon, ViewColumnOutlinedIcon } from "../../icons";

const CellComponent = ({ column, ...rest }: any) => {
  return (
    <TableHeaderRow.Cell {...rest} style={{ width: column.width ? column.width : undefined }} />
  );
};

const CustomColumnVisibilityButton = ({
  onToggle,
  getMessage,
  buttonRef,
  active,
  ...restProps
}) => (
  <Button onClick={onToggle} ref={buttonRef} {...restProps} style={{ color: "#a41717" }}>
    <ViewColumnOutlinedIcon /> Columns <ArrowDropDownIcon />
  </Button>
);

interface TableProps {
  loading?: boolean;
  showSelection?: boolean;
  rows: any[];
  columns: Column[];
  selection?: [];
  onSelectionChange?: any;
  actions?: string[];
  editHandler?: any;
  deleteHandler?: any;
  downloadHandler?: any;
  exportHandler?: any;
  checkinHandler?: any;
  viewHandler?: any;
  searchValue?: string;
  rowDetailComponent?: any;
  selectedFilters?: Filter[];
  withHeaderRow?: boolean;
  withDetail?: boolean;
  withPagination?: boolean;
  withFilters?: boolean;
  tableColumnExtensions?: any[];
  tableFiltersExtensions?: any[];
  sortingColumnExtensions?: any[];
  defaultSorting?: any[];
  rowBaseUrl?: string;
  exportEnabled?: boolean;
  exportedFileName?: string;
  defaultHiddenColumnNames?: string[];
  customPagination?: boolean;
  onPageSizeChange?: any;
  onCurrentPageChange?: any;
  totalCount?: number;
  onSortChange?: any;
  onFiltersChange?: (filters: Filter[]) => void;
}

const CurrencyTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={currencyFormatter} {...props} />
);

const TableHeaderContentBase = ({ column, children, align, ...restProps }) => {
  // console.log({ column, children, align, restProps });

  return (
    <TableHeaderRow.Content column={column} align={align} children={children} {...restProps} />
  );
};

const onSave = ({ exportedFileName, ...workbook }) => {
  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(new Blob([buffer], { type: "application/octet-stream" }), exportedFileName);
  });
};

const Table = ({
  showSelection = false,
  loading = false,
  rows,
  columns,
  selection = [],
  onSelectionChange = undefined,
  actions = [],
  editHandler = (f) => f,
  deleteHandler = (f) => f,
  downloadHandler = (f) => f,
  checkinHandler = (f) => f,
  exportHandler = (f) => f,
  viewHandler = (f) => f,
  searchValue = "",
  rowDetailComponent = null,
  selectedFilters,
  withHeaderRow = true,
  withDetail = false,
  withFilters = false,
  withPagination = true,
  exportEnabled = false,
  exportedFileName = "DataGrid.csv",
  tableColumnExtensions = TABLE_COLUMN_EXTENSIONS_DEFAULT,
  defaultSorting = TABLE_DEFAULT_SORTING,
  tableFiltersExtensions = [],
  sortingColumnExtensions = DEFAULT_SORTING_EXTENSIONS,
  rowBaseUrl,
  defaultHiddenColumnNames = [],
  customPagination = false,
  onPageSizeChange = undefined,
  onCurrentPageChange = undefined,
  totalCount = 0,
  onSortChange = undefined,
  onFiltersChange = undefined,
}: TableProps) => {
  const exporterRef: React.MutableRefObject<any> = useRef(null);
  const tableActions = useMemo(
    () =>
      actions.length && [
        {
          name: "actions",
          title: " ",
          width: 500,
          getCellValue: (row) =>
            renderActionsCell({
              row,
              actions,
              editHandler,
              deleteHandler,
              checkinHandler,
              downloadHandler,
              viewHandler,
            }),
        },
      ],
    [actions, editHandler, deleteHandler, checkinHandler, downloadHandler, viewHandler],
  );
  const extendedColumns = useMemo(
    () => [...columns, ...(tableActions || [])],
    [columns, tableActions],
  );

  const filteringColumnExtensions = useMemo(() => tableFiltersExtensions, [tableFiltersExtensions]);
  // TODO: find out if they specifically want 25 rows per table before showing pagination, or show after 10 (smallest amount in dropdown)
  const [pageSize, setPageSize] = useState(ROWS_PER_PAGE_OPTIONS[0]);
  const [currentPage, setCurrentPage] = useState(0);
  const [sorting, setSorting] = useState(defaultSorting);
  const handlePageSizeChange = (e) => {
    setPageSize(e);
    if (customPagination) {
      onPageSizeChange(e);
    }
  };
  const handleCurrentPageChange = (e) => {
    setCurrentPage(e);
    if (customPagination) {
      onCurrentPageChange(e);
    }
  };
  const handleSortChange = (e) => {
    setSorting(e);
    if (customPagination) {
      onSortChange(e);
    }
  };
  const showPagination = customPagination || (withPagination && rows.length >= pageSize);

  const gridTableAdditionalProps = Boolean(rowBaseUrl)
    ? {
        rowComponent: ({ row, ...props }) => (
          <TableRow hover component={RouterLink} to={`${rowBaseUrl}/${row.id}`}>
            {props.children}
          </TableRow>
        ),
      }
    : {};

  const currencyColumns = ["charge", "amount", "currency", "rate"];

  const isDetailEnabled = withDetail && rowDetailComponent;
  const handleExport = useCallback(
    (rows, columns) => {
      const columnNames = columns.map((col) => col.name);
      const filteredRows = rows.map((row) => {
        const newRow = {};
        columnNames.forEach((name) => {
          const col = columns.find((col) => col.name === name);
          newRow[name] = col.getExportValue
            ? col.getExportValue(row)
            : col.getCellValue && typeof col.getCellValue(row) !== "object"
            ? col.getCellValue(row)
            : row[name];
        });
        return newRow;
      });
      let csvOfRows = "";
      columns.forEach((col, i, arr) => {
        csvOfRows += `${col.title}`;
        if (i !== arr.length - 1) {
          csvOfRows += ",";
        }
      });

      filteredRows.forEach((row) => {
        csvOfRows += "\n";
        csvOfRows += Object.values(row.getCellValue ? row.getCellValue() : row).join(",");
      });

      const blob = new Blob([csvOfRows], { type: "text/csv;charset=utf-8;" });
      saveAs(blob, exportedFileName + ".csv");
    },
    [exportedFileName],
  );
  const [hiddenColumnNames, setHiddenColumnNames] = useState(defaultHiddenColumnNames);

  const handleExportClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      if (customPagination) {
        exportHandler(handleExport);
      } else {
        handleExport(rows, columns);
      }
    },
    [columns, customPagination, exportHandler, handleExport, rows],
  );
  return (
    <Paper style={{ position: "relative", boxShadow: "none" }}>
      {exportEnabled && (
        <Box
          style={{
            padding: "16px 170px 8px 16px",
            borderTop: "1px solid lightgray",
            display: "flex",
            justifyContent: "end",
            pointerEvents: "none",
            zIndex: 1000,
            marginBottom: -58,
            position: "relative",
          }}
        >
          <ExportButton onClick={handleExportClick} style={{ pointerEvents: "all" }} />
        </Box>
      )}
      <Grid rows={rows} columns={extendedColumns} getRowId={getRowId}>
        <SelectionState selection={selection} onSelectionChange={onSelectionChange} />
        <IntegratedSelection />

        <CurrencyTypeProvider for={currencyColumns} />

        <SearchState value={searchValue} />

        {showPagination && (
          <PagingState
            defaultCurrentPage={0}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageSizeChange={(e) => handlePageSizeChange(e)}
            onCurrentPageChange={(e) => handleCurrentPageChange(e)}
          />
        )}
        {/** onFiltersChange does not seem to work */}
        {withFilters && !customPagination && (
          <FilteringState filters={selectedFilters} onFiltersChange={onFiltersChange} />
        )}

        <IntegratedFiltering columnExtensions={filteringColumnExtensions} />

        <SortingState
          defaultSorting={defaultSorting}
          sorting={sorting}
          onSortingChange={(e) => handleSortChange(e)}
          columnExtensions={sortingColumnExtensions}
        />
        {!customPagination && <IntegratedSorting columnExtensions={sortingColumnExtensions} />}

        {customPagination ? (
          <CustomPaging totalCount={totalCount} />
        ) : (
          showPagination && <IntegratedPaging />
        )}
        {showPagination && <PagingPanel pageSizes={ROWS_PER_PAGE_OPTIONS} />}

        {isDetailEnabled && <RowDetailState />}

        <GridTable columnExtensions={tableColumnExtensions} {...gridTableAdditionalProps} />

        {/*{withHeaderRow && <TableColumnResizing defaultColumnWidths={[]} />}*/}
        {withHeaderRow && (
          <TableHeaderRow
            showSortingControls={true}
            contentComponent={TableHeaderContentBase}
            cellComponent={CellComponent}
          />
        )}
        <TableColumnVisibility
          defaultHiddenColumnNames={defaultHiddenColumnNames}
          hiddenColumnNames={hiddenColumnNames}
          onHiddenColumnNamesChange={setHiddenColumnNames}
        />
        <Toolbar />

        <ColumnChooser toggleButtonComponent={CustomColumnVisibilityButton} />
        {isDetailEnabled && <TableRowDetail contentComponent={rowDetailComponent} />}
        {/* showSelectAll flag will give a checkbox to select all but wasn't working when filtering to only select the filtered rows  */}
        {showSelection && (
          <TableSelection
            cellComponent={(props) =>
              props.tableRow.row.patient?.mrn ? (
                <TableSelection.Cell {...props} />
              ) : (
                <GridTable.StubCell {...props} />
              )
            }
          />
        )}
      </Grid>

      {exportEnabled && (
        <GridExporter
          ref={exporterRef}
          columns={columns}
          rows={rows}
          onSave={(workbook) => {
            onSave({
              exportedFileName,
              ...workbook,
            });
          }}
        />
      )}

      {loading && (
        <div style={{ position: "absolute", top: 0, width: "100%" }}>
          <LinearProgress />
        </div>
      )}
    </Paper>
  );
};

export default React.memo(Table);
