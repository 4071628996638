import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Collapse, Grid } from "@material-ui/core";
import qs from "query-string";
import Table from "../Table";
import PatientDetail from "./PatientDetail";
import PatientsFilters from "./PatientsFilters";

import {
  TABLE_ROW_EDIT_ACTION,
  PATIENTS_FILTERS,
  PATIENTS_FILTERS_EXTENSIONS,
  PATIENTS_DEFAULT_SORTING,
  AGENCY_PATIENTS_TABLE_COLUMNS,
  SUPER_ADMIN_PATIENTS_TABLE_COLUMNS,
  AGENCY_PATIENTS_TABLE_COLUMN_EXTENSIONS,
  FACILITY_PATIENTS_TABLE_COLUMNS,
} from "../../../constants";
import { useRole } from "../../../hooks";
import { Navigation } from "../../../lib";
import {
  useDispatch,
  agencyActions,
  useSelector,
  agencySelectors,
  authSelectors,
} from "../../../state";

import { useStyles } from "./PatientsTable.styles";

interface Props {
  view: "super_admin" | "agency" | "facility" | "global";
  isFiltersVisible: boolean;
  tabView?: any;
  entity?: any;
}

// TODO add types and interfaces
const PatientsTable = (props: Props) => {
  const classes = useStyles(props);
  const { id }: any = useParams();
  const { isFacilityRole, isAgencyBiller } = useRole();
  const dispatch = useDispatch();
  const { view, isFiltersVisible }: Props = props;

  //** SERVER PAGINATION START */
  // const [filters, setFilters] = useState<any[]>(PATIENTS_FILTERS);
  const [filters, setFilters] = useState(PATIENTS_FILTERS);
  const totalCount = useSelector(agencySelectors.patientsCount);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [order, setOrder] = useState("datetime");
  const [sort, setSort] = useState("DESC");
  //** SERVER PAGINATION END */

  const patients = useSelector(agencySelectors.patients);
  const patientsLoading = useSelector(agencySelectors.patientsLoading);
  const searchQuery = useSelector(agencySelectors.searchQuery);
  const activeEntityId = useSelector(authSelectors.activeEntityId);

  const handleRemoveFilter = useCallback(
    (status) => {
      const updatedFilters = filters.map((filter) => ({
        ...filter,
        value:
          typeof filter.value !== "string"
            ? filter.value.filter((filterValue) => status !== filterValue)
            : [],
      }));
      setFilters(updatedFilters);
    },
    [filters],
  );

  const handleFilterChange = useCallback(
    (fieldName, value) => {
      const updatedFilters = filters.map((filter) =>
        filter.columnName === fieldName ? { ...filter, value } : filter,
      );
      setFilters(updatedFilters);
    },
    [filters],
  );

  useEffect(() => {
    const entityId = view === "super_admin" ? props.entity?.id : activeEntityId;
    const _view =
      view === "global"
        ? "global"
        : view === "super_admin" || view === "agency"
        ? "agency"
        : "facility";

    const params = {
      offset,
      pageSize,
      order,
      sort,
      range: [currentPage, pageSize],
      filters: JSON.stringify(filters),
      q: searchQuery,
    };
    dispatch(agencyActions.getPatients(entityId, _view, qs.stringify(params)));
  }, [
    view,
    id,
    dispatch,
    props.entity?.id,
    activeEntityId,
    offset,
    pageSize,
    order,
    sort,
    filters,
    currentPage,
    searchQuery,
  ]);

  const handleEditPatient = useCallback(
    (patient) => {
      dispatch(agencyActions.setEditablePatient(patient));
      Navigation.go(`/${isFacilityRole ? "facility" : "agency"}/patients/${patient.id}`);
    },
    [dispatch, isFacilityRole],
  );

  const handleSetPageSize = useCallback(
    (value) => {
      setPageSize(value);
      setOffset(currentPage * value);
    },
    [currentPage],
  );

  const handleSetCurrentPage = useCallback(
    (value) => {
      setCurrentPage(value);
      setOffset(value * pageSize);
    },
    [pageSize],
  );

  const handleSetSort = useCallback((value) => {
    const filtered = value.filter(
      (row) => row.columnName !== "services" && row.columnName !== "total",
    );
    const column = filtered[0]?.columnName;
    const direction = filtered[0]?.direction;
    setOrder(column);
    setSort(direction);
  }, []);

  return (
    <>
      <Collapse in={isFiltersVisible} timeout="auto" unmountOnExit className={classes.container}>
        <Grid container>
          <PatientsFilters
            view={view}
            selectedFilters={filters}
            handleFilterChange={handleFilterChange}
            handleRemoveFilter={handleRemoveFilter}
          />
        </Grid>
      </Collapse>
      <Table
        {...props}
        loading={patientsLoading}
        rows={patients}
        columns={
          view === "global"
            ? SUPER_ADMIN_PATIENTS_TABLE_COLUMNS
            : view === "facility"
            ? FACILITY_PATIENTS_TABLE_COLUMNS
            : AGENCY_PATIENTS_TABLE_COLUMNS
        }
        actions={
          !isAgencyBiller && view !== "super_admin" && view !== "global" && [TABLE_ROW_EDIT_ACTION]
        }
        editHandler={!isAgencyBiller && handleEditPatient}
        searchValue={searchQuery}
        withFilters={true}
        withPagination={true}
        selectedFilters={filters.map(({ columnName, value }) => ({
          columnName,
          value: Array.isArray(value) ? value.join(",") : value,
        }))}
        withDetail={true}
        rowDetailComponent={PatientDetail}
        tableColumnExtensions={AGENCY_PATIENTS_TABLE_COLUMN_EXTENSIONS}
        tableFiltersExtensions={PATIENTS_FILTERS_EXTENSIONS}
        defaultSorting={PATIENTS_DEFAULT_SORTING}
        exportEnabled={view !== "global"}
        exportedFileName="Caregivers"
        customPagination={true}
        onPageSizeChange={handleSetPageSize}
        onCurrentPageChange={handleSetCurrentPage}
        onSortChange={handleSetSort}
        totalCount={totalCount}
      />
    </>
  );
};

export default React.memo(PatientsTable);
