import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import MoreVertTwoToneIcon from "@material-ui/icons/MoreVertTwoTone";
import {
  APPOINTMENT_STATUSES_STATES,
  RATE_CODE_STATUSES_STATES,
} from "../../../../../../../constants";
import {
  useAction,
  agencyActions,
  useSelector,
  agencySelectors,
  authSelectors,
  useDispatch,
} from "../../../../../../../state";
import { useStyles, AccordionSummary } from "./Summary.styles";
import { REACT_APP_API_URL } from "../../../../../../../config";
import { RCStatusStateTag } from "../../../../../../../components/tags/StatusTag";
import { useRole } from "../../../../../../../hooks";
import LocationUpdateConfirmationModal from "../../LocationUpdateConfirmationModal";
import { formatToDate } from "../../../../../../../utils";
import { DueDateModal } from "../../../../../../../components/modals/Modal/DueDateModal";
import DueDate from "../../../../../../../components/tables/AppointmentsTable/DueDate";
import { DownloadOutlinedIcon } from "../../../../../../../components";

interface Props {
  service: any;
  downloadHandler?: any;
  setManualOpen?: any;
  manualOpen?: any;
  isBundle: boolean;
}

const Summary = (props: Props) => {
  const dispatch = useDispatch();
  const { service, isBundle } = props;
  const classes = useStyles();
  const { isFacilityRole } = useRole();
  const { id: appointmentId }: any = useParams();
  const code = service.home_health_agency_rate_code?.default_rate_code?.code;
  const description =
    service.type === "bundle"
      ? service.home_health_agency_bundle?.description
      : service.home_health_agency_rate_code?.default_rate_code?.description;
  const formRequired =
    service.type === "bundle"
      ? service.home_health_agency_bundle?.form_required
      : service.home_health_agency_rate_code?.form_required;
  const dueDate = service.home_health_agency_rate_code?.due_date;
  const openedAppointment = useSelector(agencySelectors.openedAppointment);
  const view = useSelector(authSelectors.activeEntityType);
  const activeEntityId = useSelector(authSelectors.activeEntityId);
  const { isAgencyRole, isSuperAdmin } = useRole();
  const [isCheckBoxDisabled, setIsCheckboxDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [keepOriginalLocation, setKeepOriginalLocation] = useState(false);
  const [openDueDate, setOpenDueDate] = useState(false);
  const getAppointmentByID = useAction(agencyActions.getAppointmentByID);

  const handleCheck = useCallback(
    async (updateLocation = false) => {
      // Get only necessary fields for payload
      const { id, form_location, appointment_id, rate, rate_code_status_id } = service;
      const newStatus =
        rate_code_status_id === RATE_CODE_STATUSES_STATES.PROVIDED ||
        rate_code_status_id === RATE_CODE_STATUSES_STATES.PENDING
          ? RATE_CODE_STATUSES_STATES.SCHEDULED
          : service.home_health_agency_rate_code?.form_required === true &&
            // if there are no forms, or not all forms are approved, set status to pending
            (!service.forms.length || !service.forms?.every((f) => f.approved))
          ? RATE_CODE_STATUSES_STATES.PENDING
          : RATE_CODE_STATUSES_STATES.PROVIDED;
      if (updateLocation) {
        openedAppointment["updateLocationId"] = activeEntityId;
      }
      await dispatch(
        agencyActions.updateAppointmentRateCode({
          openedAppointment,
          rateCode: {
            // Set only necessary fields for payload
            id,
            hha_service_id: service.home_health_agency_rate_code?.id,
            description,
            form_location,
            appointment_id,
            rate,
            rate_code_status_id: newStatus,
          },
          activeEntityId,
        }),
      );
      getAppointmentByID(activeEntityId, appointmentId, view);
    },
    [
      service,
      dispatch,
      openedAppointment,
      description,
      activeEntityId,
      getAppointmentByID,
      appointmentId,
      view,
    ],
  );

  const handleCheckClick = useCallback(async () => {
    if (
      activeEntityId !== openedAppointment.medical_facility_id &&
      openedAppointment.appointment_status_id === APPOINTMENT_STATUSES_STATES.SCHEDULED &&
      !keepOriginalLocation &&
      isFacilityRole
    ) {
      setOpen(true);
    } else {
      handleCheck();
    }
  }, [activeEntityId, handleCheck, isFacilityRole, openedAppointment, keepOriginalLocation]);

  const handleSetLocation = (updateLocation) => {
    setKeepOriginalLocation(!updateLocation);
    handleCheck(updateLocation);
    setOpen(false);
  };

  useEffect(() => {
    if (openedAppointment) {
      if (
        isBundle ||
        isAgencyRole ||
        openedAppointment.appointment_status_id === APPOINTMENT_STATUSES_STATES.UNSCHEDULED
      ) {
        setIsCheckboxDisabled(true);
      } else {
        setIsCheckboxDisabled(false);
      }
    }
  }, [isAgencyRole, isBundle, openedAppointment]);

  const handleCancel = useCallback(async () => {
    // Get only necessary fields for payload
    const { id, form_location, home_health_agency_rate_code_id, appointment_id, rate } = service;
    await dispatch(
      agencyActions.updateAppointmentRateCode({
        openedAppointment,
        rateCode: {
          // Set only necessary fields for payload
          id,
          home_health_agency_rate_code_id,
          description,
          form_location,
          appointment_id,
          rate,
          rate_code_status_id: RATE_CODE_STATUSES_STATES.CANCELED,
        },
        activeEntityId,
      }),
    );
    handleClose();
    getAppointmentByID(activeEntityId, appointmentId, view);
  }, [
    service,
    dispatch,
    openedAppointment,
    description,
    activeEntityId,
    getAppointmentByID,
    appointmentId,
    view,
  ]);

  let form_location = !isBundle
    ? service.home_health_agency_rate_code?.form_location
    : service.home_health_agency_bundle.filenames_id;
  const token = useSelector(authSelectors.token);
  const providedStatuses = [2, 3, 5];
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleUploaderOpen = () => {
    props.setManualOpen([...props.manualOpen, service.id]);
    handleClose();
  };
  const handleDueDateOpen = useCallback(() => {
    setOpenDueDate(true);
    handleClose();
  }, []);

  return (
    <AccordionSummary className={classes.rateCodeSummary}>
      <Grid container component="header" justifyContent="space-between" alignItems="center">
        <Grid item xs={12} sm={7}>
          <Typography className={classes.rateCode}>
            <Box style={{ display: "flex", gap: 8, alignItems: "center", fontSize: 14 }}>
              <Checkbox
                checked={providedStatuses.includes(service.rate_code_status_id)}
                onChange={isBundle ? null : handleCheckClick}
                color="primary"
                disabled={isCheckBoxDisabled}
                style={{
                  cursor: isBundle ? "default" : "pointer",
                  visibility:
                    isBundle && !providedStatuses.includes(service.rate_code_status_id)
                      ? "hidden"
                      : "visible",
                }}
                className={classes.checkbox}
              />
              <Box style={{ minWidth: 55 }} className={classes.code}>
                {code}
              </Box>
              <Box style={{ minWidth: 260 }}>{description}</Box>
              {view !== "facility" && (
                <DueDate
                  service={service}
                  appointment={openedAppointment}
                  dueDate={service.due_date}
                />
              )}
              {formRequired && (
                <Box style={{ color: "#a41717", fontSize: 10, paddingLeft: 8 }}>
                  * form required
                </Box>
              )}
              {dueDate}
            </Box>
          </Typography>
        </Grid>
        <Grid container item xs={12} sm={5} justifyContent="flex-end" alignItems="center">
          {!!form_location && (
            <Button
              onClick={() => {
                window.open(
                  `${REACT_APP_API_URL}/download/${form_location}?token=${token}`,
                  "_blank",
                );
              }}
            >
              Download Agency Form
              <DownloadOutlinedIcon style={{ marginLeft: "3px" }} />
            </Button>
          )}
          <Grid item xs={3}>
            <RCStatusStateTag
              status={service.rate_code_status_id}
              documents_pending={isSuperAdmin && !!service?.forms?.length}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.rateCode}>
              {service?.completed_at && formatToDate(service?.completed_at).toString()}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Box style={{ minWidth: 48 }}>
              <IconButton onClick={handleClick}>
                <MoreVertTwoToneIcon />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {!isAgencyRole && (
                  <MenuItem
                    onClick={handleUploaderOpen}
                    disabled={
                      openedAppointment.appointment_status_id ===
                      APPOINTMENT_STATUSES_STATES.UNSCHEDULED
                    }
                  >
                    Upload
                  </MenuItem>
                )}
                {view !== "facility" && !isBundle && (
                  <MenuItem
                    onClick={handleDueDateOpen}
                    disabled={service.rate_code_status_id === RATE_CODE_STATUSES_STATES.CANCELED}
                  >
                    Due Date
                  </MenuItem>
                )}
                {!isBundle && !isAgencyRole && (
                  <MenuItem
                    onClick={handleCancel}
                    disabled={
                      service.rate_code_status_id === RATE_CODE_STATUSES_STATES.COMPLETED ||
                      openedAppointment.appointment_status_id ===
                        APPOINTMENT_STATUSES_STATES.UNSCHEDULED
                    }
                  >
                    Cancel
                  </MenuItem>
                )}
              </Menu>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <LocationUpdateConfirmationModal
        open={open}
        setOpen={setOpen}
        setUpdateLocation={handleSetLocation}
      />
      <DueDateModal open={openDueDate} setOpen={setOpenDueDate} service={service} view={view} />
    </AccordionSummary>
  );
};

export default React.memo(Summary);
