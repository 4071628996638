import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box, Button, Grid, TextField, FormHelperText, MenuItem } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";
import { useRole } from "../../../hooks";
import { Form, useFormik, Navigation } from "../../../lib";
import { CREATE_PATIENT_INITIAL_VALUES } from "../../../constants";
import { CREATE_PATIENT_VALIDATION_SCHEMA as validationSchema } from "../../../utils";
import {
  useAction,
  agencyActions,
  useSelector,
  agencySelectors,
  authSelectors,
  useDispatch,
} from "../../../state";
import { useStyles } from "./CreatePatientForm.styles";
import { LanguageSelect } from "../../inputs";
import { zonedTimeToUtc } from "date-fns-tz";

const CreatePatientForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<any>();
  const { isFacilityRole } = useRole();
  const [isMrnDuplicate, setIsMrnDuplicate] = useState(false);
  const patientIdFromParams = params?.id;
  const editPatient = useAction(agencyActions.editPatient);
  const createPatient = useAction(agencyActions.createPatient);
  const getPatientByID = useAction(agencyActions.getPatientByID);
  const setEditablePatient = useAction(agencyActions.setEditablePatient);
  const editablePatient = useSelector(agencySelectors.editablePatient);

  const isEdit = Boolean(patientIdFromParams);
  const initialValues = isEdit ? editablePatient : CREATE_PATIENT_INITIAL_VALUES;

  const activeEntity = useSelector(authSelectors.activeEntity);
  const view = activeEntity.type === "home_health_agency" ? "agency_admin" : "facility_admin";
  const activeEntityType = useSelector(authSelectors.activeEntityType);

  const form = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    async onSubmit(values) {
      if (isMrnDuplicate) {
        return;
      }
      const patientAction = isEdit ? editPatient : createPatient;
      await patientAction(values, view, activeEntity.id, false);
      Navigation.go(`/${isFacilityRole ? "facility" : "agency"}/patients`);
    },
  });

  const classes = useStyles({ isInsuredError: !!form?.errors?.insured });

  const [insured, setInsured] = useState<boolean | null>(initialValues?.insured);
  const [selectedDate, setSelectedDate] = useState(initialValues?.date_of_birth);

  async function handleMRNBlur(
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>,
    mrn: string,
  ) {
    const mrnDuplicate: any = await dispatch(
      agencyActions.checkMrn(mrn, activeEntity.id, activeEntityType),
    );
    setIsMrnDuplicate(mrnDuplicate);
  }

  // const handleGenderChange = useCallback((event) => {
  //   form.setFieldValue("gender", event.target.value);
  // }, []);

  useEffect(() => {
    if (Boolean(patientIdFromParams) && !Boolean(editablePatient?.id)) {
      const patient = getPatientByID(patientIdFromParams);
      setEditablePatient(patient);
    }
  }, [patientIdFromParams, editablePatient, getPatientByID, setEditablePatient]);

  const handleInsuredChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, newInsured: boolean | null) => {
      if (newInsured !== null) {
        setInsured(newInsured);
        form.setFieldValue("insured", newInsured);
      }
    },
    [form],
  );

  const isValidDate = (d: any) => {
    if (Object.prototype.toString.call(d) === "[object Date]") {
      if (isNaN(d.getTime())) {
        // d.valueOf() could also work
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const handleDateChange = (date) => {
    if (isValidDate(date)) {
      setSelectedDate(date);
      form.setFieldValue("date_of_birth", zonedTimeToUtc(date, "America/New_York"));
    } else {
      //TODO: figure out why it is not working
      setSelectedDate("");
      form.setFieldValue("date_of_birth", undefined);
    }
  };

  const handleReset = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Form form={form} className={classes.form}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item className={classes.controlsWrap}>
          <Grid container>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="first_name"
                name="first_name"
                label="First Name"
                value={form.values.first_name}
                onChange={form.handleChange}
                error={form.touched.first_name && Boolean(form.errors.first_name)}
                helperText={form.touched.first_name && form.errors.first_name}
              />
            </Grid>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="last_name"
                name="last_name"
                label="Last Name"
                value={form.values.last_name}
                onChange={form.handleChange}
                error={form.touched.last_name && Boolean(form.errors.last_name)}
                helperText={form.touched.last_name && form.errors.last_name}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="phone"
                name="phone"
                label="Phone number"
                value={form.values.phone}
                onChange={form.handleChange}
                error={form.touched.phone && Boolean(form.errors.phone)}
                helperText={form.touched.phone && form.errors.phone}
              />
            </Grid>
            <Grid item className={classes.formControl}>
              <DatePicker
                disableFuture
                openTo="year"
                inputFormat="MM/dd/yyyy"
                label="Date Of Birth"
                views={["year", "month", "date"]}
                value={selectedDate}
                onChange={handleDateChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={form.handleChange}
                    fullWidth
                    variant="outlined"
                    id="date_of_birth"
                    name="date_of_birth"
                    error={form.touched.date_of_birth && Boolean(form.errors.date_of_birth)}
                    helperText={form.touched.date_of_birth && form.errors.date_of_birth}
                  />
                )}
              />
            </Grid>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="email"
                name="email"
                type="email"
                label="Email"
                value={form.values.email}
                onChange={form.handleChange}
                error={form.touched.email && Boolean(form.errors.email)}
                helperText={form.touched.email && form.errors.email}
              />
            </Grid>
            <Grid item className={classes.formControl}>
              <TextField
                select
                fullWidth
                name="gender"
                label="Gender"
                variant="outlined"
                id="gender"
                value={form.values.gender}
                onChange={form.handleChange}
              >
                <MenuItem value={"male"}>Male</MenuItem>
                <MenuItem value={"female"}>Female</MenuItem>
                <MenuItem value={"other"}>Other</MenuItem>
              </TextField>
            </Grid>

            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="employee_id"
                name="employee_id"
                label="Caregiver code"
                value={form.values.employee_id}
                onChange={form.handleChange}
              />
            </Grid>
            <Grid item className={classes.formControl}>
              <LanguageSelect form={form} />
            </Grid>

            {activeEntityType === "facility" && (
              <Grid item className={classes.formControl}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="mrn"
                  name="mrn"
                  label="MRN #"
                  value={form.values.mrn}
                  onChange={form.handleChange}
                  error={form.touched.mrn && Boolean(form.errors.mrn || isMrnDuplicate)}
                  helperText={
                    form.touched.mrn && (!!form.errors.mrn || isMrnDuplicate) && isMrnDuplicate
                      ? "Caregiver MRN  already exists"
                      : form.errors.mrn
                  }
                  onBlur={(e) => {
                    handleMRNBlur(e, form.values.mrn);
                  }}
                />
              </Grid>
            )}
            {/* {
              activeEntityType === "agency" && (
                <Grid item className={classes.formControl}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="employee_id"
                    name="employee_id"
                    label="Employee Id"
                    value={form.values.employee_id}
                    onChange={form.handleChange}
                    error={form.touched.employee_id && Boolean(form.errors.employee_id || isEmployeeIdDuplicate)}
                    helperText={form.touched.employee_id && (!!form.errors.employee_id || isEmployeeIdDuplicate) && isEmployeeIdDuplicate ? "Employee Id already exists for this facility" : form.errors.employee_id}
                  />
                </Grid>
              )
            } */}
          </Grid>
          {activeEntity?.accepts_insurance && (
            <Grid container justifyContent="flex-start">
              <Grid container className={classes.note}>
                <ToggleButtonGroup
                  className={classes.switcherGroup}
                  exclusive
                  size="large"
                  value={insured}
                  onChange={handleInsuredChange}
                >
                  <ToggleButton value={false} className={classes.switcher}>
                    Uninsured
                  </ToggleButton>
                  <ToggleButton value={true} className={classes.switcher}>
                    Insured
                  </ToggleButton>
                </ToggleButtonGroup>
                {form.errors && !!form.errors.insured && (
                  <FormHelperText error id="insured-type" variant="outlined">
                    {form.errors.insured}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          )}

          <Box className={classes.note}>
            <TextField
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              id="notes"
              name="notes"
              label="Notes about caregiver (optional)"
              value={form.values.notes || ""}
              onChange={form.handleChange}
              error={form.touched.notes && Boolean(form.errors.notes)}
              helperText={form.touched.notes && form.errors.notes}
            />
          </Box>

          <Grid container justifyContent="flex-end" className={classes.formActions} spacing={4}>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                type="reset"
                onClick={handleReset}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={!!Object.keys(form.errors).length || isMrnDuplicate}
                variant="contained"
                color="primary"
                size="large"
                type="submit"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

export default React.memo(CreatePatientForm);
