import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { ExpandLessIcon, ExpandMoreIcon } from "../../icons";

import { useSelector, uiSelectors, authSelectors, agencySelectors } from "../../../state";

import { useStyles } from "./MessagesHeader.styles";

const MessagesHeader = ({ handleOpenMessages }) => {
  const classes = useStyles();
  const oldAuth = useSelector(authSelectors.oldAuth);
  const activeEntityType = useSelector(authSelectors.activeEntityType);
  const facilityUnreads = useSelector(agencySelectors.facilityUnreads);
  const agencyUnreads = useSelector(agencySelectors.agencyUnreads);
  const superAdminUnreads = useSelector(agencySelectors.superAdminUnreads);
  const expandedMessages = useSelector(uiSelectors.messages);
  const [unreadsCount, setUnreadsCount] = useState(0);

  useEffect(() => {
    const unreads: any =
      (activeEntityType === "super_admin" || !!oldAuth) && superAdminUnreads
        ? superAdminUnreads
        : activeEntityType === "facility" && facilityUnreads
        ? facilityUnreads
        : activeEntityType === "agency" && agencyUnreads
        ? agencyUnreads
        : 0;
    setUnreadsCount(unreads);
  }, [activeEntityType, facilityUnreads, agencyUnreads, superAdminUnreads, oldAuth]);

  const Icon = expandedMessages ? ExpandMoreIcon : ExpandLessIcon;

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      className={classes.root}
      onClick={handleOpenMessages}
    >
      <Grid item>
        <Grid container alignItems="center" justifyContent="space-between">
          <Typography className={classes.title}>{`Messages ${
            unreadsCount && unreadsCount !== 0 ? `(${unreadsCount})` : ""
          }`}</Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container alignItems="center" justifyContent="flex-end">
          <Icon fontSize="large" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MessagesHeader;
