import React, { useCallback, useEffect, useState } from "react";
import { useFormikContext } from "formik";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { formatToDate } from "../../../../utils";
import {
  agencyActions,
  agencySelectors,
  authSelectors,
  useAction,
  useSelector,
} from "../../../../state";
import qs from "query-string";
import { debounce } from "../../../../lib";

const ExistingPatientAutoComplete = () => {
  const patients = useSelector(agencySelectors.patients);
  const activeEntityId = useSelector(authSelectors.activeEntityId);
  const { setFieldValue, errors, touched, values } = useFormikContext<any>();
  const getPatients = useAction(agencyActions.getPatients);
  const [inputValue, setInputValue] = useState("");

  const handleAutoCompleteSelect = useCallback(
    (e, value) => {
      setFieldValue("patient_id", value?.id);
    },
    [setFieldValue],
  );

  useEffect(() => {
    console.log({ inputValue });

    const params = {
      // offset,
      // pageSize,
      // order,
      // sort,
      // range: [currentPage, pageSize],
      // filters: JSON.stringify(filters),
      q: inputValue,
    };
    getPatients(activeEntityId, "agency", qs.stringify(params));
  }, [activeEntityId, getPatients, inputValue]);

  const handleInputChange = useCallback((e, v, r) => {
    console.log({ v, r });
    if (r === "input") {
      setInputValue(v);
    }
  }, []);

  return (
    <Autocomplete
      fullWidth
      options={patients}
      value={patients.find((p) => p.id === values.patient_id) ?? null}
      getOptionLabel={({ first_name, last_name, date_of_birth }) =>
        `${first_name} ${last_name}   |   ${formatToDate(date_of_birth)}`
      }
      //TODO: format nicer
      // renderOption={(option, state) => (
      //   <Grid container>
      //     <Grid item>{`${option.first_name} ${option.last_name}`}</Grid>
      //     <Grid> {`${formatToDate(option.date_of_birth)}`}</Grid>
      //   </Grid>
      // )}
      onInputChange={debounce(handleInputChange, 250)}
      style={{ maxWidth: 536 }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select an existing caregiver"
          variant="outlined"
          error={touched.patient_id && Boolean(errors.patient_id)}
          helperText={touched.patient_id && errors.patient_id}
        />
      )}
      onChange={handleAutoCompleteSelect}
    />
  );
};

export default React.memo(ExistingPatientAutoComplete);
