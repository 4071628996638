import React from "react";
import { Typography } from "@material-ui/core";

import { useStyles } from "./NewMessageBadge.styles";

const NewMessageBadge = () => {
  const classes = useStyles();
  return <Typography className={classes.new}>NEW</Typography>;
};

export default NewMessageBadge;
