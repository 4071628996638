import { createSelector } from "@reduxjs/toolkit";

import { AppState } from "../types";

export const ROLES: any = {
  SUPER_ADMIN: "super_admin",
  AGENCY_ADMIN: "agency_admin",
  AGENCY_SCHEDULER: "agency_scheduler",
  AGENCY_BILLER: "agency_biller",
  FACILITY_ADMIN: "facility_admin",
  FACILITY_INTAKE: "facility_intake",
  FACILITY_BILLER: "facility_biller",
  FACILITY_PROVIDER: "facility_provider",
};

const _state = (state: AppState) => state.auth;
const _roles = (state: AppState) => {
  return state.auth.entities && state.auth.entities.length
    ? [state.auth.entities?.find((e) => e.id === state.auth.activeEntityId)?.entity_roles[0]]
    : [];
};
const _token = (state: AppState) => state.auth.token ?? null;
const _isAdmin = createSelector(
  _roles,
  (roles) => !!roles.find((r) => r.name === ROLES.SUPER_ADMIN),
);
const _isSuperAdmin = createSelector(
  _roles,
  (roles) => !!roles.find((r) => r.name === ROLES.SUPER_ADMIN),
);
const _isLoggedIn = createSelector(_state, (state) => !!state.userId);

const _oldAuth = createSelector(_state, (state) => state.oldAuth ?? null);
const _user = createSelector(_state, (state) => state.user ?? null);
const _userId = createSelector(_state, (state) => state.userId ?? 0);
const _userFirstName = createSelector(_state, (state) => state.user?.first_name ?? "");
const _userEntity: any = createSelector(_state, (state) => state.user?.entities?.[0] ?? {});
const _entities = createSelector(_state, (state) => state.entities);
const _activeEntityId = createSelector(_state, (state) => state.activeEntityId);
//TODO: make sure entities only have 1 role
const _activeEntity = createSelector(_state, (state) =>
  state.entities?.find((e) => e.id === state.activeEntityId),
);
const _activeEntityType = createSelector(_state, (state) => {
  const type = state.entities?.find((e) => e.id === state.activeEntityId)?.type;
  switch (type) {
    case "home_health_agency":
      return "agency";
    case "medical_facility":
      return "facility";
    case "global":
      return "super_admin";
    case undefined:
      return "undefined";
    default:
      throw new Error("We did not handle for this type -> " + type);
  }
});
const _activeRole = createSelector(_state, (state) => state.user?.roles?.[0]?.name);

const _missed = createSelector(_state, (state) => state.user?.missed ?? 0);
const _history = createSelector(_state, (state) => state.user?.history ?? []);

export const authSelectors = {
  oldAuth: _oldAuth,
  isAdmin: _isAdmin,
  isSuperAdmin: _isSuperAdmin,
  isLoggedIn: _isLoggedIn,
  roles: _roles,
  token: _token,
  user: _user,
  userId: _userId,
  userFirstName: _userFirstName,
  userEntity: _userEntity,
  history: _history,
  missed: _missed,
  entities: _entities,
  activeEntityId: _activeEntityId,
  activeEntity: _activeEntity,
  activeEntityType: _activeEntityType,
  activeRole: _activeRole,

  /** Selector used for routing */
  route: createSelector(_isLoggedIn, _roles, _activeRole, (isLoggedIn, roles, activeRole) => ({
    isAdmin: roles.includes(ROLES.SUPER_ADMIN),
    isLoggedIn,
    roles,
    activeRole,
  })),
  /** Selector used for getting the entire auth state. */
  state: createSelector(
    _isAdmin,
    _isLoggedIn,
    _roles,
    _user,
    _userId,
    _userFirstName,
    _history,
    _missed,
    (isAdmin, isLoggedIn, roles, user, userId, userFirstName, missed) => ({
      isAdmin,
      isLoggedIn,
      roles,
      user,
      userId,
      userFirstName,
      missed,
    }),
  ),
};
