import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
  TextField,
} from "@material-ui/core";
import clsx from "clsx";
import NumberFormat from "react-number-format";
import { RouterLink } from "../../../../lib";
import {
  useAction,
  agencyActions,
  useSelector,
  agencySelectors,
  authSelectors,
} from "../../../../state";
import { useStyles } from "./Review.styles";
import { formatDateTZ } from "../../../../lib";
import { formatTimeSlot } from "../../../../utils/dateUtils";

const Review = ({ handleReset }) => {
  const classes = useStyles();

  const [notes, setNote] = useState("");

  const updateAppointment = useAction(agencyActions.updateAppointment);
  const clearAppointmentPatient = useAction(agencyActions.clearAppointmentPatient);

  const regions = useSelector(agencySelectors.regions);
  const appointmentPatient = useSelector(agencySelectors.appointmentPatient);
  const appointmentServices = useSelector(agencySelectors.appointmentServices);
  const appointmentTimeslot = useSelector(agencySelectors.appointmentTimeslot);
  const confirmedAppointment = useSelector(agencySelectors.confirmedAppointment);
  const activeAppointment = useSelector(agencySelectors.activeAppointment);
  const activeEntityType = useSelector(authSelectors.activeEntityType);
  const activeEntityId = useSelector(authSelectors.activeEntityId);

  const location = regions.find((region) => region.id === appointmentTimeslot.location);
  const { entity: facility, timezone } = location?.medical_facility_profiles?.find(
    (facility) => facility.entity_id === appointmentTimeslot?.facility,
  );

  useEffect(() => {
    return () => {
      clearAppointmentPatient();
    };
  }, [clearAppointmentPatient]);

  const handleAppointmentSave = useCallback(() => {
    handleReset();
    clearAppointmentPatient();
  }, [clearAppointmentPatient, handleReset]);

  const handleChange = useCallback((e) => {
    setNote(e.target.value);
  }, []);

  const handleSaveNotes = useCallback(() => {
    updateAppointment(
      {
        ...confirmedAppointment,
        notes,
      },
      activeEntityType,
      activeEntityId,
    );
  }, [updateAppointment, confirmedAppointment, notes, activeEntityType, activeEntityId]);

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item className={classes.reviewWrap}>
        <Grid container justifyContent="center" spacing={4} className={classes.reviewItemsWrap}>
          <Grid item className={classes.reviewItem}>
            <Card variant="outlined" className={clsx(classes.card, classes.cardWithCode)}>
              <CardContent>
                <Box className={classes.cardWithCodeContent}>
                  <Typography className={classes.name}>
                    <strong>
                      {appointmentPatient?.first_name} {appointmentPatient?.last_name}
                    </strong>
                  </Typography>
                  <Typography className={classes.phone}>
                    <NumberFormat
                      format="+1 (###) ###-####"
                      mask="_"
                      value={appointmentPatient?.phone}
                      displayType="text"
                    />
                  </Typography>
                </Box>
                <Divider variant="middle" />
                <Box className={classes.cardWithCodeContent}>
                  <Typography variant="h5">
                    <strong>Code:</strong> {confirmedAppointment?.patient_id}
                  </Typography>
                </Box>
                <Divider variant="middle" />
                <Box className={classes.cardWithCodeContent}>
                  <Typography variant="h5">
                    <strong>Payer:</strong> {activeAppointment.payer}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item className={classes.reviewItem}>
            <Card variant="outlined" className={clsx(classes.card, classes.cardWithDetails)}>
              <Box className={classes.facility}>
                <Typography>
                  <strong>
                    {facility?.name} - {location.name}
                  </strong>
                </Typography>
                <Typography>
                  {facility?.address}
                  <br />
                  <NumberFormat
                    format="+1 (###) ###-####"
                    mask="_"
                    value={facility?.phone}
                    displayType="text"
                  />
                </Typography>
              </Box>
              <Box>
                <Typography align="center">
                  <strong>
                    {appointmentTimeslot?.timeframe &&
                      formatDateTZ(appointmentTimeslot?.timeframe, timezone, "EEEE - M/d/yyyy")}
                  </strong>
                </Typography>
                <Box className={classes.timeSlotWrap}>
                  <Box className={classes.timeSlot}>
                    <Typography className={classes.timeSlotText}>
                      {appointmentTimeslot &&
                        formatTimeSlot(new Date(appointmentTimeslot.timeframe)).toString()}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Grid container direction="column">
                    {(appointmentServices?.appointment_rate_codes || []).map((code) => (
                      <Grid key={`service-${code.id}`} item>
                        <Typography className={classes.service}>
                          <strong>{code.description}</strong>
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>

        <Grid container justifyContent="center" className={classes.notesWrap}>
          <Card variant="outlined" className={classes.notes}>
            <Grid
              container
              component="header"
              alignItems="center"
              justifyContent="space-between"
              className={classes.title}
            >
              <Grid item>
                <Typography>Appointment Notes</Typography>
              </Grid>
            </Grid>

            <Box className={classes.notesText}>
              <TextField
                multiline
                rows={4}
                fullWidth
                variant="outlined"
                id="notes"
                name="notes"
                label="Notes"
                value={notes}
                onChange={handleChange}
              />

              <Grid container justify="flex-end" className={classes.actionsWrap} spacing={3}>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSaveNotes}
                    disabled={!notes.length}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>

        <Grid container justifyContent="flex-end" className={classes.actions} spacing={4}>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              type="button"
              onClick={handleReset}
            >
              Book Another
            </Button>
          </Grid>
          <Grid item>
            <Button
              id="done"
              variant="contained"
              color="primary"
              size="large"
              onClick={handleAppointmentSave}
              component={RouterLink}
              to="/agency/appointments"
            >
              Done
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(Review);
