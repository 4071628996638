import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  title: {
    marginRight: 10,
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 20,
    fontWeight: 500,
    lineHeight: "45px",
  },
}));
