import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import AppointmentsTable from "../../../components/tables/AppointmentsTable";
import {
  SUPER_ADMIN_ALL_APPOINTMENTS_TABLE_COLUMNS as superAdminColumns,
  SUPER_ADMIN_ALL_APPOINTMENTS_COLUMN_EXTENSIONS as extensions,
  SUPER_ADMIN_APPOINTMENTS_TABLE_SORTING as sortingOptions,
  SUPER_ADMIN_ALL_APPTS_DEFAULT_HIDDEN_COLUMNS as defaultHiddenColumns,
} from "../../../constants";
import { useInputDebounced } from "../../../lib";
import {
  useAction,
  superAdminActions,
  useSelector,
  superAdminSelectors,
  agencyActions,
  useDispatch,
} from "../../../state";
import { useStyles } from "./AppointmentsPage.styles";
import { SearchField } from "../../../components/inputs";
import { FilterButton } from "../../../components";
import ApptRateCodeDetails from "../../../components/tables/AppointmentsTable/ApptRateCodeDetails";

const SuperAdminAppointmentsPage = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [searchQuery, debouncedSearchQuery, handleChange] = useInputDebounced("", 250);
  const setSearchQuery = useAction(agencyActions.setSearchQuery);
  const totalCount = useSelector(superAdminSelectors.appointmentTotalCount);
  const dispatch: any = useDispatch();
  const [selection, setSelection]: any = useState([]);
  const markAppointmentsAsReviewed = useAction(superAdminActions.markAppointmentsAsReviewed);
  const [pageSize, setPageSize]: any = useState(10);
  const [currentPage, setCurrentPage]: any = useState(0);
  const [offset, setOffset]: any = useState(0);
  const [order, setOrder]: any = useState("datetime");
  const [sort, setSort]: any = useState("DESC");
  const [filters, setFilters]: any = useState([]);
  const [filterDocs, setFilterDocs]: any = useState(false);

  const defaultParams = useMemo(
    () => ({
      filters: filters,
      filterDocs: filterDocs,
      offset: offset,
      limit: pageSize,
      order: order,
      sort: sort,
      q: debouncedSearchQuery,
      isExport: false,
    }),
    [debouncedSearchQuery, filterDocs, filters, offset, order, pageSize, sort],
  );

  const handleExpandClick = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const refreshAppointments = useCallback(
    async (params) => {
      const { filters, filterDocs, ...rest } = params;
      const results = await dispatch(
        superAdminActions.getAllAppointments({
          filters: JSON.stringify([{ columnName: "filterDocs", value: filterDocs }, ...filters]),
          ...rest,
        }),
      );
      return results;
    },
    [dispatch],
  );

  const setSuperAdminFilters = useCallback(
    (filters: any[]) => {
      setFilters(filters);
      refreshAppointments({ ...defaultParams, filters: filters });
    },
    [defaultParams, refreshAppointments],
  );

  const setSuperAdminFilterDocs = useCallback(
    (value) => {
      setFilterDocs(value);
      refreshAppointments({ ...defaultParams, filterDocs: value });
    },
    [defaultParams, refreshAppointments],
  );

  const handleMarkAsReviewed = useCallback(() => {
    const { filters, filterDocs, ...rest } = defaultParams;
    markAppointmentsAsReviewed(selection, {
      ...rest,
      filters: JSON.stringify([{ columnName: "filterDocs", value: filterDocs }, ...filters]),
    });
    setSelection([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markAppointmentsAsReviewed, selection]);

  const handleSetPageSize = useCallback(
    (value) => {
      setPageSize(value);
      setOffset(currentPage * value);
      refreshAppointments({ ...defaultParams, limit: value, offset: currentPage * value });
    },
    [currentPage, defaultParams, refreshAppointments],
  );
  const handleSetCurrentPage = (value) => {
    setCurrentPage(value);
    setOffset(value * pageSize);
    refreshAppointments({ ...defaultParams, offset: value * pageSize });
  };
  const handleSetSort = (value) => {
    const filtered = value.filter(
      (row) => row.columnName !== "services" && row.columnName !== "total",
    ); //sort is disabled for services and total charged
    const column = filtered[0]?.columnName;
    const direction = filtered[0]?.direction;
    setOrder(column);
    setSort(direction);
    refreshAppointments({ ...defaultParams, order: column, sort: direction });
  };
  const exportHandler = async (_handleExport) => {
    await refreshAppointments({ ...defaultParams, isExport: true });
    //handleExport(rows, superAdminColumns); Removed - this happens in the dispatch
  };
  useEffect(() => {
    setSearchQuery(debouncedSearchQuery);
    refreshAppointments({ ...defaultParams, q: debouncedSearchQuery });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery, setSearchQuery]);

  return (
    <Box>
      <Typography variant="subtitle2">All Appointments</Typography>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        classes={{ container: classes.filterRow }}
      >
        <Grid item xs={3}>
          <SearchField
            id="search"
            label="Search appointment ID, caregiver ID, name, MRN, phone"
            value={searchQuery}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={9} container style={{ justifyContent: "flex-end" }}>
          <FilterButton value={expanded} selected={expanded} onChange={handleExpandClick} />
          <Button
            color="primary"
            variant="contained"
            disabled={!selection.length}
            onClick={handleMarkAsReviewed}
          >
            Mark as reviewed
          </Button>
        </Grid>
      </Grid>

      <AppointmentsTable
        view={"global"}
        tabView={false}
        isFiltersVisible={expanded}
        columns={superAdminColumns}
        extensions={extensions}
        selection={selection}
        onSelectionChange={setSelection}
        showSelection={true}
        showSuperAdminFilters={true}
        defaultSorting={sortingOptions}
        defaultHiddenColumnNames={defaultHiddenColumns}
        rowDetailComponent={ApptRateCodeDetails}
        withDetail={true}
        customPagination={true}
        onPageSizeChange={handleSetPageSize}
        onCurrentPageChange={handleSetCurrentPage}
        totalCount={totalCount}
        onSortChange={handleSetSort}
        sortingColumnExtensions={sortingOptions}
        setSuperAdminFilters={setSuperAdminFilters}
        setSuperAdminFilterDocs={setSuperAdminFilterDocs}
        exportHandler={exportHandler}
      />
    </Box>
  );
};

export default React.memo(SuperAdminAppointmentsPage);
