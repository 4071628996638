import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { Dialog } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { agencyActions, agencySelectors, useDispatch, useSelector } from "../../../state";
import { formatDateTZ } from "../../../lib";
import { addDays } from "date-fns";

interface Props {
  setOpen: (v: boolean) => void;
  open: boolean;
  service?: Record<string, any>;
  view;
}

export function DueDateModal(props: Props) {
  const { setOpen, open, service, view } = props;
  const dispatch = useDispatch();
  const openedAppointment = useSelector(agencySelectors.openedAppointment);
  const agencyId = openedAppointment.patient.home_health_agency_id;
  const today = new Date();
  const YearFromToday = today.setFullYear(today.getFullYear() + 1);
  const [dueDate, setDueDateChange] = useState(
    service.due_date
      ? addDays(new Date(service.due_date), 1) //FOR EDT TO UTC
      : new Date(YearFromToday),
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleDateChange = useCallback((date) => {
    setDueDateChange(date);
  }, []);

  const handleSaveClick = useCallback(async () => {
    await dispatch(agencyActions.saveDueDate(agencyId, service.id, formatDateTZ(dueDate)));
    await dispatch(agencyActions.getAppointmentByID(agencyId, openedAppointment.id, view));
    setOpen(false);
  }, [dispatch, agencyId, service.id, dueDate, openedAppointment.id, view, setOpen]);

  return (
    <Dialog onClose={handleClose} open={open}>
      <Box style={{ minWidth: 400, padding: 32 }}>
        <Typography style={{ marginBottom: 12, fontWeight: 600, fontSize: 22 }}>
          Set Due Date
        </Typography>
        <Box style={{ width: "100%", marginBottom: 24 }}>
          <DatePicker
            value={dueDate}
            renderInput={(props) => <TextField {...props} fullWidth variant="outlined" />}
            disablePast={true}
            onChange={handleDateChange}
          />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Button variant={"contained"} fullWidth onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button color={"primary"} variant={"contained"} fullWidth onClick={handleSaveClick}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}
