import React, { useCallback, useState, useEffect } from "react";
import clsx from "clsx";
import {
  Box,
  Button,
  Grid,
  TextField,
  FormHelperText,
  Typography,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import FileUpload from "../FileUpload";

import {
  useAction,
  uiActions,
  agencyActions,
  useSelector,
  superAdminSelectors,
  useDispatch,
} from "../../../state";
import { US_STATES, CREATE_AGENCY_INITIAL_VALUES as initialValues } from "../../../constants";
import SuperAdminPages from "../../../pages/super-admin";
import { Form, Navigation, useFormik } from "../../../lib";
import { CREATE_AGENCY_VALIDATION_SCHEMA as validationSchema } from "../../../utils";

import { useStyles } from "./CreateAgencyForm.styles";
import { Field } from "formik";
import { EditorField } from "../..";

const CreateAgencyForm = ({ activeStep, handleNext, handleBack }) => {
  const agency = useSelector(superAdminSelectors.activeAgency);
  const isEditAgency = Boolean(agency?.id);
  const dispatch = useDispatch();
  const showSuccess = useAction(uiActions.showSuccess);
  const _initialValues = {
    isEditAgency,
    ...initialValues,
    ...(isEditAgency ? agency : {}),
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues: _initialValues,
    validationSchema,
    async onSubmit(values: any) {
      const { monthly_appointment_limit } = values;
      const data = values;
      data.monthly_appointment_limit = monthly_appointment_limit || null;
      let result;
      if (isEditAgency) {
        result = await dispatch(agencyActions.updateAgency(data));
      } else {
        result = await dispatch(agencyActions.createAgency(data));
      }
      if (isEditAgency) {
        showSuccess("Agency successfully saved!");
        Navigation.go(SuperAdminPages.agencies.path);
      } else if (result === 200) {
        handleNext();
      }
    },
  });

  const classes = useStyles({ isStatusError: !!form?.errors?.active });
  const [prefix, setPrefix] = useState<string | null>(
    isEditAgency ? agency.invoice_prefix : initialValues.invoice_prefix,
  );
  const [editor, setEditor] = useState(EditorState.createEmpty());
  const [loaded, setLoaded] = useState(false);

  const handleStatusChange = (event: React.MouseEvent<HTMLElement>, newStatus: boolean) => {
    form.setFieldValue("active", newStatus);
  };
  const handlePrefixChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newPrefix = event.target.value;
      if (newPrefix !== null) {
        setPrefix(newPrefix.toUpperCase());
        form.setFieldValue("invoice_prefix", newPrefix.toUpperCase());
      }
    },
    [form],
  );
  const allowSubmit =
    form.values.accepts_agency_payments ||
    form.values.accepts_insurance ||
    form.values.accepts_patient_payments;

  const handleEditorChange = (editorState) => {
    setEditor(editorState);
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    form.setFieldValue("requisition_note", html);
  };

  useEffect(() => {
    if (form.values?.requisition_note && !loaded) {
      const blocks = EditorState.createWithContent(
        ContentState.createFromBlockArray(htmlToDraft(form.values.requisition_note)),
      );
      setLoaded(true);
      setEditor(blocks);
    }
  }, [form.values?.requisition_note, loaded]);

  return (
    <Form form={form} className={classes.form}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item className={classes.controlsWrap}>
          <Box className={classes.sectionTitle}>
            <Typography>Agency details</Typography>
          </Box>
          <Grid container>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="name"
                name="name"
                label="Agency Name"
                value={form.values.name}
                onChange={form.handleChange}
                error={form.touched.name && Boolean(form.errors.name)}
                helperText={form.touched.name && form.errors.name}
              />
            </Grid>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="phone"
                name="phone"
                label="Agency phone number"
                value={form.values.phone}
                onChange={form.handleChange}
                error={form.touched.phone && Boolean(form.errors.phone)}
                helperText={form.touched.phone && form.errors.phone}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="address"
                name="address"
                label="Address"
                value={form.values.address}
                onChange={form.handleChange}
                error={form.touched.address && Boolean(form.errors.address)}
                helperText={form.touched.address && form.errors.address}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="city"
                name="city"
                label="City"
                value={form.values.city}
                onChange={form.handleChange}
                error={form.touched.city && Boolean(form.errors.city)}
                helperText={form.touched.city && form.errors.city}
              />
            </Grid>
            <Grid item className={clsx(classes.formControl, classes.state)}>
              <TextField
                fullWidth
                variant="outlined"
                select
                name="state"
                id="state"
                label="State"
                value={form.values.state}
                onChange={form.handleChange}
                error={form.touched.state && Boolean(form.errors.state)}
                helperText={form.touched.state && form.errors.state}
              >
                {US_STATES.map((state, i) => (
                  <MenuItem key={`state-${state.label}-${i}`} value={state.value}>
                    {state.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="zip"
                name="zip"
                label="Zip code"
                value={form.values.zip}
                onChange={form.handleChange}
                error={form.touched.zip && Boolean(form.errors.zip)}
                helperText={form.touched.zip && form.errors.zip}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-start" alignItems="center">
            <Grid item className={clsx(classes.formControl, classes.switcherWrap)}>
              <ToggleButtonGroup
                className={classes.switcherGroup}
                exclusive
                size="large"
                value={form.values.active}
                onChange={handleStatusChange}
              >
                <ToggleButton value={true} className={classes.switcher}>
                  Active
                </ToggleButton>
                <ToggleButton value={false} className={classes.switcher}>
                  Inactive
                </ToggleButton>
              </ToggleButtonGroup>
              {form.errors && !!form.errors.active && (
                <FormHelperText error id="active" variant="outlined">
                  {form.errors.active}
                </FormHelperText>
              )}
            </Grid>
            <Grid item className={classes.formControl} xs={4}>
              <TextField
                fullWidth
                variant="outlined"
                id="invoice_prefix"
                name="invoice_prefix"
                label="Invoice Prefix"
                value={prefix}
                onChange={handlePrefixChange}
                error={form.touched.invoice_prefix && Boolean(form.errors.invoice_prefix)}
                helperText={form.touched.invoice_prefix && form.errors.invoice_prefix}
              />
            </Grid>
            <Tooltip title="Monthly appointment limit for Pre-employment Physical Form and MMR services">
              <Grid item className={classes.formControl} xs={4}>
                <TextField
                  type="number"
                  fullWidth
                  variant="outlined"
                  id="monthly_appointment_limit"
                  name="monthly_appointment_limit"
                  label="Monthly Appointment Limit"
                  value={form.values.monthly_appointment_limit}
                  onChange={form.handleChange}
                  error={
                    form.touched.monthly_appointment_limit &&
                    Boolean(form.errors.monthly_appointment_limit)
                  }
                  helperText={
                    form.touched.monthly_appointment_limit && form.errors.monthly_appointment_limit
                  }
                />
              </Grid>
            </Tooltip>
            {form.values.monthly_appointment_limit && form.values.monthly_appointments_scheduled ? (
              <span>
                {form.values.monthly_appointments_scheduled} appointment(s) scheduled this month{" "}
              </span>
            ) : null}

            <Grid item xs={12}>
              <FormControlLabel
                name="allows_unscheduled_appts"
                control={
                  <Field
                    component={Checkbox}
                    type="checkbox"
                    name="allows_unscheduled_appts"
                    checked={form.values.allows_unscheduled_appts}
                    onChange={(e) =>
                      form.setFieldValue("allows_unscheduled_appts", e.target.checked)
                    }
                    color="primary"
                  />
                }
                label="Allows unscheduled appointments"
              />
            </Grid>
          </Grid>

          <Grid container style={{ marginBottom: 16, marginTop: 16 }}>
            <Grid item xs={12}>
              <Typography className={classes.title}>Payment Methods</Typography>
              <Typography className={classes.required}>(at least one is required)</Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                name="accepts_insurance"
                control={
                  <Field
                    component={Checkbox}
                    type="checkbox"
                    name="accepts_insurance"
                    checked={form.values.accepts_insurance}
                    onChange={(e) => form.setFieldValue("accepts_insurance", e.target.checked)}
                    color="primary"
                  />
                }
                label="Accepts caregivers insurance"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                name="accepts_patient_payment"
                control={
                  <Field
                    component={Checkbox}
                    type="checkbox"
                    name="accepts_patient_payments"
                    checked={form.values.accepts_patient_payments}
                    onChange={(e) =>
                      form.setFieldValue("accepts_patient_payments", e.target.checked)
                    }
                    color="primary"
                  />
                }
                label="Accepts caregiver payments (flex card)"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                name="accepts_agency_payments"
                control={
                  <Field
                    component={Checkbox}
                    type="checkbox"
                    name="accepts_agency_payments"
                    checked={form.values.accepts_agency_payments}
                    onChange={(e) =>
                      form.setFieldValue("accepts_agency_payments", e.target.checked)
                    }
                    color="primary"
                  />
                }
                label="Accepts agency payments"
              />
            </Grid>
          </Grid>

          {!isEditAgency && (
            <>
              <Box className={classes.sectionTitle}>
                <Typography>Agency admin</Typography>
              </Box>
              <Grid container>
                <Grid item className={classes.formControl}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="first_name"
                    name="first_name"
                    label="First Name"
                    value={form.values.first_name}
                    onChange={form.handleChange}
                    error={form.touched.first_name && Boolean(form.errors.first_name)}
                    helperText={form.touched.first_name && form.errors.first_name}
                  />
                </Grid>
                <Grid item className={classes.formControl}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="last_name"
                    name="last_name"
                    label="Last Name"
                    value={form.values.last_name}
                    onChange={form.handleChange}
                    error={form.touched.last_name && Boolean(form.errors.last_name)}
                    helperText={form.touched.last_name && form.errors.last_name}
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-start">
                <Grid item className={classes.formControl}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="email"
                    id="email"
                    name="email"
                    label="Email address"
                    value={form.values.email}
                    onChange={form.handleChange}
                    error={form.touched.email && Boolean(form.errors.email)}
                    helperText={form.touched.email && form.errors.email}
                  />
                </Grid>
              </Grid>
            </>
          )}

          <Box className={classes.sectionTitle}>
            <Typography>Logo upload</Typography>
          </Box>

          <Box className={classes.fileUploadWrap}>
            <FileUpload form={form} name="logo" accept="image/*" uploadType="public" />
            {form.touched.logo && Boolean(form.errors.logo) && (
              <FormHelperText error variant="outlined">
                {form.errors.logo}
              </FormHelperText>
            )}
          </Box>

          <Box className={classes.sectionTitle}>
            <Typography>Agency Requisition Note</Typography>
          </Box>

          <Box className={classes.formControl}>
            <EditorField onChange={handleEditorChange} editorState={editor} />
          </Box>

          <Grid container justifyContent="flex-end" className={classes.formActions} spacing={4}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={!allowSubmit}
              >
                {isEditAgency ? "Save" : "Next"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

export default React.memo(CreateAgencyForm);
