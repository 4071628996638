import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: "1px solid #E0E0E0",
    background: "#fff",
  },
  inputWrap: {
    flex: "1 1 auto",
  },
  input: {
    paddingLeft: 12,
    height: 48,
  },
}));
