import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  new: {
    width: 30,
    textAlign: "center",
    borderRadius: 3,
    backgroundColor: "#0F0190",
    color: "#FFF",
    fontSize: 10,
    lineHeight: "15px",
  },
}));
