import React, { useEffect, useState, useCallback } from "react";
import Slider from "react-slick";
import { zonedTimeToUtc } from "date-fns-tz";
import { addHours, isBefore, isSameHour } from "date-fns";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Box, Typography } from "@material-ui/core";
import { useStyles } from "./AppointmentTimeSlots.styles";
import { PrevArrow, NextArrow } from "./Arrows";
import { useAction, agencyActions, useSelector, agencySelectors } from "../../../../state";
import { createDateSlots, formatDateSlot, formatTimeSlot } from "../../../../utils";

const settings = {
  dots: false,
  infinite: false,
  speed: 250,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};
const isNextHour = (time) => {
  return isSameHour(time, addHours(new Date(), 1));
};

const AppointmentTimeSlots = ({ form, facility, originalTimeZone = "America/New York" }) => {
  const classes = useStyles();
  const getMedicalFacilityAvailability = useAction(agencyActions.getMedicalFacilityAvailability);
  const medicalFacilitiesAvailability = useSelector(agencySelectors.medicalFacilitiesAvailability);
  const [timeframe, setTimeframe] = useState<string>("");
  let first = true;
  let newClass = "";
  const facilityTimeZone = facility?.timezone ? facility?.timezone : originalTimeZone;
  const handleSelectTimeframe = useCallback(
    (event: React.MouseEvent<HTMLElement>, newTimeframe: string[]) => {
      if (newTimeframe !== null) {
        setTimeframe(newTimeframe.toString());
        form.setFieldValue(
          "timeframe",
          zonedTimeToUtc(new Date(newTimeframe.toString()), facilityTimeZone),
        );
      }
    },
    [form, facilityTimeZone],
  );

  useEffect(
    () => {
      if (facility?.entity_id) getMedicalFacilityAvailability(facility?.entity_id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [facility?.entity_id],
  );
  let availableDates: any = [];
  try {
    availableDates =
      facility?.hours && medicalFacilitiesAvailability?.length
        ? createDateSlots(facility?.hours, medicalFacilitiesAvailability)
        : null;
  } catch (e) {
    console.error(e);
  }

  return Boolean(availableDates) ? (
    <div style={{ marginLeft: 40 }}>
      <Slider {...settings}>
        {(availableDates || []).map((date) => (
          <Box key={formatDateSlot(date.date).toString()} className={classes.date}>
            <Typography className={classes.dayTitle}>
              {formatDateSlot(date.date).toString()}
            </Typography>
            <ToggleButtonGroup
              exclusive
              className={classes.timeSlotGroup}
              value={timeframe.toString()}
              onChange={handleSelectTimeframe}
            >
              {date.slots.map((time) => {
                if (time.available && first) {
                  newClass = "nextHr";
                }
                first = false;
                return (
                  <ToggleButton
                    type="button"
                    key={formatTimeSlot(time.time).toString()}
                    className={
                      classes.timeSlot +
                      " " +
                      (time.available && isNextHour(time.time) ? "nextHr" : "") +
                      (time.available ? newClass : "")
                    }
                    value={time.time.toString()}
                    disabled={!time.available || !isBefore(new Date(), time.time)}
                  >
                    {formatTimeSlot(time.time)}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </Box>
        ))}
      </Slider>
    </div>
  ) : (
    <div style={{ width: 1000 }}>
      <Typography align="center">No available time for selected medical facility.</Typography>
    </div>
  );
};

export default React.memo(AppointmentTimeSlots);
