import React, { useCallback, useEffect, useRef, useState, Fragment } from "react";
import { Box, Tabs, Tab, Divider } from "@material-ui/core";
import TabPanel from "../../components/tabs/TabPanel";

import MessagesChat from "./MessagesChat";
import MessagesHeader from "./MessagesHeader";
import MessagesChatList from "./MessagesChatList";
import MessagesChatInputArea from "./MessagesChatInputArea";

import {
  useAction,
  agencyActions,
  useSelector,
  uiSelectors,
  agencySelectors,
  authSelectors,
  uiActions,
} from "../../state";

import { useStyles } from "./Messages.styles";
const Messages = () => {
  const [markedAsUnread, setMarkedAsUnread] = useState(false);
  const [facilityMarkedAsUnread, setFacilityMarkedAsUnread] = useState(false);
  const [agencyMarkedAsUnread, setAgencyMarkedAsUnread] = useState(false);
  const messageSending = useSelector(agencySelectors.messageSending);
  const view = useSelector(authSelectors.activeEntityType);
  const oldAuth = useSelector(authSelectors.oldAuth);
  const isSuperAdmin = view === "super_admin" || !!oldAuth;
  const getMessages = useAction(agencyActions.getMessages);
  const getUnreadMessageCount = useAction(agencyActions.getUnreadMessageCount);
  const setReadMessages = useAction(agencyActions.readMessages);
  const [expandedMessages, toggleExpandMessages] = useState(false);
  const selectedTab = useSelector(uiSelectors.selectedTab);
  const setSelectedTab = useAction(uiActions.setSelectedTab);
  const openedAppointment = useSelector(agencySelectors.openedAppointment);
  const chatMessages = useSelector(agencySelectors.messages);
  const bodyRef = useRef<HTMLDivElement>(null);
  const classes = useStyles({ expandedMessages, isSuperAdmin });
  const isAppointmentOpened = Boolean(Object.keys(openedAppointment).length);
  const activeEntityId = useSelector(authSelectors.activeEntityId);
  const appointmentFacility = openedAppointment.medical_facility?.entity?.name || "";
  const appointmentAgency =
    openedAppointment.patient?.home_health_agency_profile?.entity?.name || "";
  const appointmentFacilityId = openedAppointment.medical_facility?.entity?.id || "";
  const appointmentAgencyId =
    openedAppointment.patient?.home_health_agency_profile?.entity?.id || "";
  const scrollToBottom = useCallback(() => {
    if (bodyRef && bodyRef.current) {
      bodyRef.current.scrollTop = bodyRef.current.scrollHeight;
    }
  }, []);

  const entityId = isSuperAdmin
    ? selectedTab === "medical_facility"
      ? appointmentFacilityId
      : appointmentAgencyId
    : activeEntityId;

  const refreshMessages = () => {
    getMessages(entityId, openedAppointment?.id, view, selectedTab, isSuperAdmin);
    if (isSuperAdmin) {
      getUnreadMessageCount(entityId, openedAppointment?.id, view, selectedTab, isSuperAdmin);
    }
  };

  const handleSwitchTabs = useCallback(
    (event, newValue) => {
      if (newValue === "home_health_agency" && !facilityMarkedAsUnread) {
        setReadMessages(
          appointmentFacilityId,
          openedAppointment?.id,
          view,
          "medical_facility",
          isSuperAdmin,
        );
      } else if (newValue === "medical_facility" && !agencyMarkedAsUnread) {
        setReadMessages(
          appointmentAgencyId,
          openedAppointment?.id,
          view,
          "home_health_agency",
          isSuperAdmin,
        );
      }
      setFacilityMarkedAsUnread(false);
      setAgencyMarkedAsUnread(false);
      setSelectedTab(newValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [facilityMarkedAsUnread, agencyMarkedAsUnread],
  );

  const handleCloseMessages = () => {
    if (!isSuperAdmin) {
      if (!markedAsUnread) {
        setReadMessages(entityId, openedAppointment?.id, view, selectedTab, isSuperAdmin);
      }
      setMarkedAsUnread(false);
    } else {
      if (isSuperAdmin && selectedTab === "medical_facility") {
        if (!facilityMarkedAsUnread) {
          setReadMessages(entityId, openedAppointment?.id, view, "medical_facility", isSuperAdmin);
        }
        setFacilityMarkedAsUnread(false);
      } else {
        if (isSuperAdmin && selectedTab === "home_health_agency") {
          if (!agencyMarkedAsUnread) {
            setReadMessages(
              entityId,
              openedAppointment?.id,
              view,
              "home_health_agency",
              isSuperAdmin,
            );
          }
          setAgencyMarkedAsUnread(false);
        }
      }
    }
  };
  const handleOpenMessages = useCallback(
    () => {
      if (expandedMessages) {
        handleCloseMessages();
      }
      toggleExpandMessages(!expandedMessages);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [expandedMessages, facilityMarkedAsUnread, agencyMarkedAsUnread, markedAsUnread],
  );
  useEffect(
    () => {
      if (!messageSending) {
        refreshMessages();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [entityId, isSuperAdmin, view, openedAppointment?.id, messageSending],
  );

  useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom, expandedMessages, chatMessages]);

  return isSuperAdmin ? (
    <Box className={classes.root}>
      <MessagesHeader handleOpenMessages={handleOpenMessages} />
      {expandedMessages && (
        <Fragment>
          <Tabs value={selectedTab} onChange={handleSwitchTabs}>
            <Tab
              value="home_health_agency"
              label={appointmentAgency}
              disableRipple
              className={classes.tab}
            />
            <Tab
              value="medical_facility"
              label={appointmentFacility}
              disableRipple
              className={classes.tab}
            />
          </Tabs>
          <Divider />

          <div className={classes.body} ref={bodyRef}>
            <TabPanel value={selectedTab} index="medical_facility">
              {isAppointmentOpened ? (
                <>
                  <MessagesChat
                    entity={selectedTab}
                    isSuperAdmin={true}
                    setMarkedAsUnread={setFacilityMarkedAsUnread}
                  />
                  <MessagesChatInputArea entity={selectedTab} isSuperAdmin={true} />
                </>
              ) : (
                <MessagesChatList entity={selectedTab} isSuperAdmin={true} />
              )}
            </TabPanel>
            <TabPanel value={selectedTab} index="home_health_agency">
              {isAppointmentOpened ? (
                <>
                  <MessagesChat
                    entity={selectedTab}
                    isSuperAdmin={true}
                    setMarkedAsUnread={setAgencyMarkedAsUnread}
                  />
                  <MessagesChatInputArea entity={selectedTab} isSuperAdmin={true} />
                </>
              ) : (
                <MessagesChatList entity={selectedTab} isSuperAdmin={true} />
              )}
            </TabPanel>
          </div>
        </Fragment>
      )}
    </Box>
  ) : (
    <Box className={classes.root}>
      <MessagesHeader handleOpenMessages={handleOpenMessages} />
      {expandedMessages && (
        <div className={classes.body} ref={bodyRef}>
          {isAppointmentOpened ? (
            <>
              <MessagesChat isSuperAdmin={false} setMarkedAsUnread={setMarkedAsUnread} />
              <MessagesChatInputArea isSuperAdmin={false} />
            </>
          ) : (
            <MessagesChatList isSuperAdmin={false} />
          )}
        </div>
      )}
    </Box>
  );
};

export default Messages;
