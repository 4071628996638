import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    // right: 50,
    left: 100,
    bottom: 0,
    width: 420,
    padding: (props: any) => (props.isSuperAdmin ? "0 20px 40px" : "0 20px"),
    border: "1px solid #E0E0E0",
    height: (props: any) => (props.expandedMessages ? 360 : 45),
    background: "#fff",
    zIndex: 999,
  },
  body: {
    overflow: "auto",
    height: "calc(100% - 45px)",
  },

  container: {
    marginTop: 20,
  },
  tab: {
    color: "#000",
    textTransform: "capitalize",
    "&.MuiTab-textColorInherit": {
      opacity: 0.5,

      "&.Mui-selected": {
        opacity: 1,
      },
    },
  },
}));
