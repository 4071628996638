import React, { useCallback, useEffect, useState } from "react";
import { Collapse, Grid } from "@material-ui/core";
import Table from "../Table";
import AppointmentsFilters from "./AppointmentsFilters";
import {
  useAction,
  agencyActions,
  useSelector,
  agencySelectors,
  authSelectors,
  Entity,
  facilityActions,
} from "../../../state";
import { APPOINTMENTS_FILTERS, APPOINTMENTS_FILTERS_EXTENSIONS } from "../../../constants";
import { useStyles } from "./AppointmentsTable.styles";
import qs from "query-string";

interface Props {
  id?: string;
  defaultHiddenColumnNames?: any[];
  showSuperAdminFilters?: boolean;
  entity?: Entity;
  selection?: [];
  onSelectionChange?: any;
  view: "agency" | "facility" | "super_admin" | "global";
  extensions?: any;
  isFiltersVisible: boolean;
  columns: any;
  tabView?: boolean;
  rowBaseUrl?: string;
  defaultSorting?: any;
  appointmentListType?: "current_fac" | "all_fac";
  showSelection?: boolean;
  rowDetailComponent?: any;
  withDetail?: boolean;
  customPagination?: boolean;
  onPageSizeChange?: any;
  onCurrentPageChange?: any;
  onSortChange?: any;
  totalCount?: number;
  sortingColumnExtensions?: any;
  setSuperAdminFilters?: any;
  setSuperAdminFilterDocs?: any;
  exportHandler?: (_handleExport: any) => Promise<void>;
}

const AppointmentsTable = (props: Props) => {
  const classes = useStyles(props);
  const exportAgencyAppointments = useAction(agencyActions.exportAgencyAppointments);
  const exportFacilityAppointments = useAction(facilityActions.exportFacilityAppointments);
  const getAppointments = useAction(agencyActions.getAppointments);
  const appointments = useSelector(agencySelectors.appointments);
  const appointmentsLoading = useSelector(agencySelectors.appointmentsLoading);
  const searchQuery = useSelector(agencySelectors.searchQuery);
  const activeEntityId = useSelector(authSelectors.activeEntityId);
  //** SERVER PAGINATION START */
  const [filters, setFilters] = useState(APPOINTMENTS_FILTERS);
  const totalCount = useSelector(agencySelectors.appointmentsCount);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [order, setOrder] = useState("");
  const [sort, setSort] = useState("DESC");
  //** SERVER PAGINATION END */

  const { showSuperAdminFilters, setSuperAdminFilters, setSuperAdminFilterDocs } = props;
  const handleRemoveFilter = useCallback(
    (status, filterColumn = "appointment_status_id") => {
      const updatedFilters = filters.map((filter) => {
        if (filter?.columnName !== filterColumn) {
          return { ...filter };
        } else {
          return {
            ...filter,
            value:
              typeof filter.value !== "string"
                ? filter.value.filter((filterValue) => status !== filterValue)
                : [],
          };
        }
      });
      setFilters(updatedFilters);
      if (showSuperAdminFilters) {
        setSuperAdminFilters(updatedFilters);
      }
    },
    [filters, setSuperAdminFilters, showSuperAdminFilters],
  );
  const handleFilterDocChange = useCallback(
    (value) => {
      setSuperAdminFilterDocs(value);
    },
    [setSuperAdminFilterDocs],
  );

  const handleFilterChange = useCallback(
    (fieldName, value) => {
      if (fieldName === "filterDocs") {
        handleFilterDocChange(value);
      } else {
        const updatedFilters = filters.map((filter) =>
          filter.columnName === fieldName ? { ...filter, value } : filter,
        );
        setFilters(updatedFilters);
        if (showSuperAdminFilters) {
          setSuperAdminFilters(updatedFilters);
        }
      }
    },
    [filters, showSuperAdminFilters, setSuperAdminFilters, handleFilterDocChange],
  );

  useEffect(() => {
    if (!props.entity && props.view !== "global") {
      return;
    }
    let _view;
    switch (props.view) {
      case "super_admin":
        _view = props.entity.type === "medical_facility" ? "facility" : "agency";
        break;
      case "agency":
        _view = "agency";
        break;
      case "facility":
        _view = "facility";
        break;
      case "global":
        _view = "global";
        break;
    }
    const entityId = props.view === "super_admin" ? props.entity.id : activeEntityId;
    const params = {
      offset,
      pageSize,
      order,
      sort,
      filters: JSON.stringify(filters),
      q: searchQuery,
    };
    getAppointments(entityId, _view, props.appointmentListType, qs.stringify(params));
  }, [
    getAppointments,
    props.view,
    props.entity,
    activeEntityId,
    props.appointmentListType,
    filters,
    offset,
    order,
    pageSize,
    sort,
    searchQuery,
  ]);

  const handleSetPageSize = useCallback(
    (value) => {
      setPageSize(value);
      setOffset(currentPage * value);
      props.onPageSizeChange && props.onPageSizeChange(value);
    },
    [currentPage, props],
  );

  const handleSetCurrentPage = useCallback(
    (value) => {
      setCurrentPage(value);
      setOffset(value * pageSize);
      props.onCurrentPageChange && props.onCurrentPageChange(value);
    },
    [pageSize, props],
  );

  const handleSetSort = useCallback(
    (value) => {
      console.log({ value });
      const sortColumn = value.find((c) => !!c.direction);
      setOrder(sortColumn.columnName);
      setSort(sortColumn.direction);
      props.onSortChange && props.onSortChange(value);
    },
    [props],
  );

  const handleExportClick = useCallback(
    (...args) => {
      const entityId = props.view === "super_admin" ? props.entity.id : activeEntityId;
      if (props.exportHandler) {
        props.exportHandler(args);
      } else if (
        props.view === "agency" ||
        (props.view === "super_admin" && props.entity.type === "home_health_agency")
      ) {
        exportAgencyAppointments(entityId);
      } else if (
        props.view === "facility" ||
        (props.view === "super_admin" && props.entity.type === "medical_facility")
      ) {
        exportFacilityAppointments(entityId);
      } else {
        console.error("export Click not handled for");
      }
    },
    [activeEntityId, exportAgencyAppointments, exportFacilityAppointments, props],
  );

  return (
    <>
      <Collapse
        in={props.isFiltersVisible}
        timeout="auto"
        unmountOnExit
        className={classes.container}
      >
        <Grid container>
          <AppointmentsFilters
            showSuperAdminFilters={showSuperAdminFilters}
            selectedFilters={filters}
            handleFilterChange={handleFilterChange}
            handleRemoveFilter={handleRemoveFilter}
            view={props.view}
          />
        </Grid>
      </Collapse>
      <Table
        id="appointments-table"
        {...props}
        rows={appointments}
        selection={props.selection}
        columns={props.columns}
        loading={appointmentsLoading}
        withFilters={true}
        selectedFilters={filters.map(({ columnName, value }) => ({
          columnName,
          value: Array.isArray(value) ? value.join(",") : value,
        }))}
        tableColumnExtensions={props.extensions}
        tableFiltersExtensions={APPOINTMENTS_FILTERS_EXTENSIONS}
        exportEnabled={true}
        exportedFileName="Appointments"
        showSelection={props.showSelection}
        withDetail={props.withDetail ?? false}
        rowDetailComponent={props.rowDetailComponent}
        onSelectionChange={props.onSelectionChange}
        customPagination={true}
        onPageSizeChange={handleSetPageSize}
        onCurrentPageChange={handleSetCurrentPage}
        onSortChange={handleSetSort}
        totalCount={totalCount}
        exportHandler={handleExportClick}
      />
    </>
  );
};

export default React.memo(AppointmentsTable);
