import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { createPrinter } from "../../dummyData/agency";
import {
  AppointmentPatientInitialValues,
  CreateAppointmentServicesInitialValues,
  AppointmentTimeInitialValues,
  AgencyInitialValues,
} from "../../constants";
import { AppointmentServices, AppointmentUpdates, AppointmentRateCode } from "../types";

type Payer = "agency" | "patient" | "insurance";
interface ActiveAppointment {
  patient: Patient;
  appointment: Appointment;
}

interface Patient {
  first_name: string;
  last_name: string;
  phone: string;
  date_of_birth: string;
  insured: boolean;
  notes: string;
  is_patient_paying: boolean;
  email: string;
  home_health_agency_id: number;
}

interface Appointment {
  medical_facility_id: number;
  patient_id: number;
  appointment_rate_codes: AppointmentRateCode[];
  datetime: string;
  payer: Payer;
  appointmentServices: AppointmentServices;
}

export interface AgencyState {
  services?: any[];
  users?: any[];
  usersLoading?: boolean;
  notificationSettings?: any[];
  notificationSettingsLoading?: boolean;
  notificationTypes?: any[];
  entities?: any[];
  entitiesLoading?: boolean;
  medicalFacilities?: any[];
  medicalFacilitiesLoading?: boolean;
  medicalFacilitiesAvailability?: any[];
  medicalFacilitiesAvailabilityLoading?: boolean;

  patients?: any[];
  patientsCount?: number;
  patientsLoading?: boolean;
  editablePatient?: any | null;

  appointments?: any[];
  appointmentsCount?: number;
  appointmentsLoading?: boolean;
  openedAppointment?: any;
  rescheduleAppointment?: any;

  searchQuery?: string;
  billingHistory?: any[];
  billingLoading?: boolean;
  appointmentPatient?: any;
  appointmentExistingPatientID?: number;
  appointmentServices?: any;
  appointmentTimeslot?: any;
  appointmentCode?: string;
  appointmentUpdates?: AppointmentUpdates;
  confirmedAppointment?: any;
  isAppointmentConfirmed?: boolean;
  appointmentsHistory?: any;
  appointmentHistoryList?: any;
  activeAppointment: ActiveAppointment;

  agency?: any;
  printers?: any[];
  updates?: any[];
  expectedUpdates?: any[];
  supportingDocs?: any[];
  rateCodes?: any[];
  patientRateCodes?: any[];
  rateCodesLoading?: boolean;
  patientRateCodesLoading?: boolean;

  regions?: any[];
  regionsLoading?: boolean;

  chats?: any;
  chatsLoading?: boolean;
  messageSending?: boolean;
  messages?: any;
  redLine?: any;
  facilityUnreads?: number;
  agencyUnreads?: number;
  superAdminUnreads?: number;

  bundles: any[];
}
const defaultActiveAppointment = {
  patient: undefined,
  appointment: undefined,
  appointmentServices: undefined,
};
// Fill state  with dummy data
const initialState: AgencyState = {
  billingHistory: [],
  appointmentCode: "",
  printers: [createPrinter(), createPrinter(), createPrinter()],
  updates: [],
  services: [],
  bundles: [],
  activeAppointment: defaultActiveAppointment,
  supportingDocs: [],
  appointments: [],
  appointmentsCount: 0,
};

export const agency = {
  ...createSlice({
    name: "agency",
    initialState,
    reducers: {
      // Common
      setAgencyState(state, { payload }: PayloadAction<AgencyState | undefined>) {
        if (!payload) return initialState;
        Object.assign(state, payload);
      },
      setSearchQuery(state, { payload }: PayloadAction<string>) {
        state.searchQuery = payload;
      },

      // Entities
      setEntities(state, { payload }: PayloadAction<any>) {
        state.entities = payload.rows;
      },
      setEntitiesLoading(state, { payload }: PayloadAction<boolean>) {
        state.entitiesLoading = payload;
      },
      setMedicalFacilities(state, { payload }: PayloadAction<any>) {
        state.medicalFacilities = payload.rows;
      },
      setMedicalFacilitiesLoading(state, { payload }: PayloadAction<boolean>) {
        state.medicalFacilitiesLoading = payload;
      },

      setMedicalFacilityAvailability(state, { payload }: PayloadAction<any>) {
        state.medicalFacilitiesAvailability = payload;
      },
      setMedicalFacilityAvailabilityLoading(state, { payload }: PayloadAction<boolean>) {
        state.medicalFacilitiesAvailabilityLoading = payload;
      },
      setBilling(state, { payload }: PayloadAction<any>) {
        state.billingHistory = payload;
      },
      setBillingLoading(state, { payload }: PayloadAction<boolean>) {
        state.billingLoading = payload;
      },
      // Users
      setUsers(state, { payload }: PayloadAction<any>) {
        state.users = payload.rows;
      },
      setUsersLoading(state, { payload }: PayloadAction<boolean>) {
        state.usersLoading = payload;
      },
      setNotificationSettings(state, { payload }: PayloadAction<any>) {
        state.notificationSettings = payload;
      },
      setNotificationSettingsLoading(state, { payload }: PayloadAction<boolean>) {
        state.notificationSettingsLoading = payload;
      },
      setNotificationTypes(state, { payload }: PayloadAction<any>) {
        state.notificationTypes = payload;
      },

      // Patients
      setPatients(state, { payload }: PayloadAction<{ count: number; rows: any[] }>) {
        state.patients = payload.rows;
        state.patientsCount = payload.count;
      },
      setPatientsLoading(state, { payload }: PayloadAction<boolean>) {
        state.patientsLoading = payload;
      },
      setEditablePatient(state, { payload }: PayloadAction<any>) {
        state.editablePatient = payload;
      },

      // Appointments
      setAppointments(state, { payload }: PayloadAction<{ count: number; rows: any[] }>) {
        state.appointments = payload.rows;
        state.appointmentsCount = payload.count;
      },
      setAppointmentsLoading(state, { payload }: PayloadAction<boolean>) {
        state.appointmentsLoading = payload;
      },
      clearOpenedAppointment(state) {
        state.openedAppointment = null;
        state.supportingDocs = [];
      },
      setRescheduleAppointment(state, { payload }: PayloadAction<AppointmentPatientInitialValues>) {
        state.rescheduleAppointment = payload;
      },

      /* ACTIVE APPOINTMENT */
      resetActiveAppointment(state, { payload }: PayloadAction<ActiveAppointment>) {
        state.activeAppointment = defaultActiveAppointment;
      },
      setActiveAppointment(state, { payload }: PayloadAction<ActiveAppointment>) {
        state.activeAppointment = payload;
      },
      setActiveAppointmentPatient(state, { payload }: PayloadAction<Patient>) {
        state.activeAppointment.patient = payload;
      },
      setActiveAppointmentPayer(state, { payload }: PayloadAction<Payer>) {
        const appointment = {
          ...state.activeAppointment.appointment,
          payer: payload,
        };
        state.activeAppointment.appointment = appointment;
      },
      setActiveAppointmentServices(state, { payload }: PayloadAction<AppointmentServices>) {
        const appointment = {
          ...state.activeAppointment.appointment,
          appointmentServices: payload,
        };
        state.activeAppointment.appointment = appointment;
      },
      setAppointmentUpdates(state, { payload }: PayloadAction<AppointmentUpdates>) {
        state.appointmentUpdates = payload;
      },

      // Create Appointment
      setAppointmentPatient(state, { payload }: PayloadAction<AppointmentPatientInitialValues>) {
        state.appointmentPatient = payload;
      },
      clearAppointmentPatient(state) {
        state.appointmentPatient = undefined;
      },
      setAppointmentExistingPatientID(state, { payload }: PayloadAction<number>) {
        state.appointmentExistingPatientID = payload;
      },
      saveAppointmentServices(
        state,
        { payload }: PayloadAction<CreateAppointmentServicesInitialValues>,
      ) {
        state.appointmentServices = payload;
      },
      setAppointmentTimeslot(state, { payload }: PayloadAction<AppointmentTimeInitialValues>) {
        state.appointmentTimeslot = payload;
      },
      setConfirmedAppointment(state, { payload }: PayloadAction<any>) {
        state.confirmedAppointment = payload;
      },
      setAppointmentConfirmed(state, { payload }: PayloadAction<boolean>) {
        state.isAppointmentConfirmed = payload;
      },
      setAppointmentsHistory(state, { payload }: PayloadAction<any>) {
        state.appointmentsHistory = payload;
      },
      setAppointmentHistoryList(state, { payload }: PayloadAction<any>) {
        state.appointmentHistoryList = payload;
      },

      // Agency
      setAgency(state, { payload }: PayloadAction<AgencyInitialValues>) {
        state.agency = payload;
      },
      updateAgency(state, { payload }: PayloadAction<AgencyInitialValues>) {
        state.agency = { ...state.agency, ...payload };
      },

      // Rate codes
      setRateCodes(state, { payload: { rateCodes, setFullResponse } }: PayloadAction<any>) {
        // Transform rate from string to number - API related bug
        state.rateCodes = rateCodes.map((code) => {
          return {
            ...(setFullResponse ? code : {}),
            active: code.active,
            id: code.id,
            code: code.default_rate_code ? code.default_rate_code.code : code.code,
            description: code.default_rate_code
              ? code.default_rate_code.description
              : code.description,
            rate: Number(code.rate),
            form_location: code.form_location,
            bundle: code.home_health_agency_bundle,
          };
        });
      },
      setBundles(state, { payload }: PayloadAction<any>) {
        state.bundles = payload.bundles ? payload.bundles : payload;
      },
      setServices(state, { payload }: PayloadAction<any>) {
        state.services = payload.services ? payload.services : payload;
      },

      setRateCodesLoading(state, { payload }: PayloadAction<boolean>) {
        state.rateCodesLoading = payload;
      },
      setPatientRateCodes(state, { payload }: PayloadAction<any>) {
        state.patientRateCodes = payload;
      },
      setPatientRateCodesLoading(state, { payload }: PayloadAction<boolean>) {
        state.patientRateCodesLoading = payload;
      },
      // Regions
      setRegions(state, { payload }: PayloadAction<any>) {
        state.regions = payload;
      },
      setRegionsLoading(state, { payload }: PayloadAction<boolean>) {
        state.regionsLoading = payload;
      },
      // Updates
      setUpdates(state, { payload }: PayloadAction<Record<string, any>[]>) {
        state.updates = payload.map((r) => ({ ...r, patient: r.appointment.patient }));
      },
      setExpectedUpdates(state, { payload }: PayloadAction<Record<string, any>[]>) {
        state.expectedUpdates = payload.map((r) => ({ ...r, patient: r.appointment.patient }));
      },
      setOpenedAppointment(state, { payload }: PayloadAction<any>) {
        const { created_by_user } = payload;
        state.openedAppointment = {
          ...payload,
          created_by: !!created_by_user
            ? `${created_by_user.first_name} ${created_by_user?.last_name}`
            : "unknown",
        };
      },
      setSupportingDocs(state, { payload }: PayloadAction<any>) {
        state.supportingDocs = payload;
      },
      // Messages
      setChats(state, { payload }: PayloadAction<any>) {
        state.chats = payload;
      },
      setChatsLoading(state, { payload }: PayloadAction<boolean>) {
        state.chatsLoading = payload;
      },
      setMessageSending(state, { payload }: PayloadAction<boolean>) {
        state.messageSending = payload;
      },
      setMessages(state, { payload }: PayloadAction<any>) {
        state.messages = payload;
      },
      setRedLine(state, { payload }: PayloadAction<any>) {
        state.redLine = payload;
      },
      setFacilityUnreads(state, { payload }: PayloadAction<any>) {
        state.facilityUnreads = payload;
      },
      setAgencyUnreads(state, { payload }: PayloadAction<any>) {
        state.agencyUnreads = payload;
      },
      setSuperAdminUnreads(state, { payload }: PayloadAction<any>) {
        state.superAdminUnreads = payload;
      },
    },
  }),
  persist: false,
};
