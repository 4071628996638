import {
  add,
  eachDayOfInterval,
  eachHourOfInterval,
  format,
  isFriday,
  isMonday,
  isSunday,
  isThursday,
  isTuesday,
  isWednesday,
  set,
} from "date-fns";
const timeZoneOffset = (date) => {
  return new Date(new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60000);
};

export const formatToDate = (date) => date && format(timeZoneOffset(date), "MM/dd/yyyy");
export const formatToDateTime = (date) => (date ? format(timeZoneOffset(date), "M/d/yyyy ") : "");
export const formatToDateTimeMinutes = (date) => format(new Date(date), "M/d/yy - h:mm a");
export const formatToTime = (date) => {
  try {
    return format(date, "h a");
  } catch (e) {
    console.error("Invalid Time", date, e);
    return "Invalid Time";
  }
};
export const formatToShortDayOfWeek = (date) => format(timeZoneOffset(date), "EEE");
export const formatToFullDayOfWeek = (date) => format(timeZoneOffset(date), "EEEE");
export const formatDateSlot = (date) => {
  try {
    return format(new Date(date), "EEEE - M/d/yyyy");
  } catch (e) {
    console.error("Invalid Date", date, e);
    return "Invalid Date";
  }
};
export const formatTimeSlot = (time) =>
  `${formatToTime(time)} - ${formatToTime(add(time, { hours: 1 }))}`;

export const showApptTime = (appt) => new Date(appt.created_at) >= new Date("10/19/2023"); //Hide time on appointments created before timeslots were reinstated

export const formatAppointmentsHistoryPeriod = (date) => format(timeZoneOffset(date), "yyyy-M-d");

export function createTimeSlots(day, hours, availability) {
  const hoursKey = isSunday(day)
    ? "sun"
    : isMonday(day)
    ? "mon"
    : isTuesday(day)
    ? "tue"
    : isWednesday(day)
    ? "wed"
    : isThursday(day)
    ? "thu"
    : isFriday(day)
    ? "fri"
    : "sat";
  // if (hours[hoursKey].open == "" && hours[hoursKey].close == "") {
  //   return null;
  // }

  const lastAppointmentSlot =
    hours[hoursKey].close !== "" ? Number(hours[hoursKey].close) - 3 : hours[hoursKey].close;
  const openLessThanThreeHours =
    hours[hoursKey].close !== "" ? lastAppointmentSlot - Number(hours[hoursKey].open) < 0 : true;
  const interval = {
    start: set(day, { hours: hours[hoursKey].open }),
    end: set(day, {
      hours: openLessThanThreeHours ? hours[hoursKey].close : lastAppointmentSlot,
    }),
  };
  return eachHourOfInterval(interval).map((hour) => ({
    id: Math.round(Math.random() * 10000),
    time: hour,
    available: availability ? availability[hour.getHours()] : false,
  }));
}

export function createDateSlots(hours, availableDates) {
  const interval = {
    start: new Date(),
    end: add(new Date(), { months: 3 }),
  };

  return eachDayOfInterval(interval).map((day) => {
    const availableHours = availableDates?.find(
      (row) =>
        row.date ===
        day.toLocaleDateString("en-US", { day: "2-digit", month: "2-digit", year: "numeric" }),
    )?.hours;
    return {
      id: Math.round(Math.random() * 10000),
      date: day,
      slots: createTimeSlots(day, hours, availableHours).slice(0, -1), //TODO:Kludge to remove last slot this way but blame datefns
    };
  });
}
