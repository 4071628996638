import React, { useCallback, useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Checkbox,
  TextField,
} from "@material-ui/core";
import {
  APPOINTMENT_STATUSES_STATES,
  APPOINTMENT_STATUSES_STATES_ARRAY,
} from "../../../../constants";
import { getStatusLabel } from "../../../tags/StatusTag";
import { useStyles } from "./AppointmentsFilters.styles";
import { View, listsActions, listsSelectors, useAction, useSelector } from "../../../../state";
import { DateRangePicker, DateRangeDelimiter, DateRange } from "@material-ui/pickers";
import { format, isValid } from "date-fns";

interface Props {
  selectedFilters: any;
  handleFilterChange: (fieldName: string, value: any) => void;
  handleRemoveFilter: (filter: any, field: any) => void;
  showSuperAdminFilters?: boolean;
  view: View;
}

const timeZoneOffset = (date) => {
  return new Date(new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60000);
};
const AppointmentsFilters = (props: Props) => {
  const {
    selectedFilters,
    handleFilterChange,
    handleRemoveFilter,
    showSuperAdminFilters = false,
    view,
  } = props;
  const classes = useStyles();
  const statusFilterValue = selectedFilters.find(
    (filter) => filter.columnName === "appointment_status_id",
  ).value;
  const facFilterValue = selectedFilters.find((filter) => filter.columnName === "facility")?.value;
  const agencyFilterValue = selectedFilters.find((filter) => filter.columnName === "agency")?.value;
  const reviewFilterValue = selectedFilters.find(
    (filter) => filter.columnName === "reviewed",
  )?.value;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [superAdminFilterDocs, setSuperAdminFilterDocs] = useState(false);
  const [selectedDate, handleDateChange] = useState<DateRange<Date>>([null, null]);
  const [selectedDueDate, handleDueDateChange] = useState<DateRange<Date>>([null, null]);

  const getFacilities = useAction(listsActions.getFacilitiesList);
  const facilities = useSelector(listsSelectors.facilitiesList);
  const getAgencies = useAction(listsActions.getAgenciesList);
  const agencies = useSelector(listsSelectors.agenciesList);

  useEffect(() => {
    if (!!showSuperAdminFilters || view === "facility" || view === "super_admin") {
      getAgencies();
      getFacilities();
    }
    if (!!showSuperAdminFilters || view === "agency" || view === "super_admin") {
      getFacilities();
    }
  }, [getFacilities, getAgencies, showSuperAdminFilters, view]);

  const handleFiltersChange = useCallback(
    (fieldName, { target: { value } }) => {
      handleFilterChange(fieldName, value);
      setIsOpen(false);
    },
    [handleFilterChange],
  );

  const handleDateFilterChange = useCallback(
    (value) => {
      if (value && isValid(value[0]) && isValid(value[1])) {
        const dateFrom = format(timeZoneOffset(value[0]), "yyyy-MM-dd");
        const dateTo = format(timeZoneOffset(value[1]), "yyyy-MM-dd");
        handleFilterChange("datetime", [dateFrom, dateTo]);
      } else {
        handleFilterChange("datetime", value);
      }
      handleDateChange(value);
    },
    [handleFilterChange],
  );
  const handleDueDateFilterChange = useCallback(
    (value) => {
      if (value && isValid(value[0]) && isValid(value[1])) {
        const dateFrom = format(new Date(value[0]), "yyyy-MM-dd");
        const dateTo = format(new Date(value[1]), "yyyy-MM-dd");
        handleFilterChange("due_date", [dateFrom, dateTo]);
      } else {
        handleFilterChange("due_date", value);
      }
      handleDueDateChange(value);
    },
    [handleFilterChange],
  );

  const handleReviewedFilterChange = useCallback(
    ({ target: { checked } }) => {
      handleFilterChange("reviewed", checked);
    },
    [handleFilterChange],
  );
  const handleHasDocsFilterChange = useCallback(
    async ({ target: { checked } }) => {
      setSuperAdminFilterDocs(checked);
      handleFilterChange("filterDocs", checked);
    },
    [handleFilterChange, setSuperAdminFilterDocs],
  );

  return (
    <>
      <FormControl variant="outlined" className={classes.statusFormControl} size="small">
        <InputLabel id="latest-appointment-status-label">Appointment status</InputLabel>
        <Select
          multiple
          id="appointment-status"
          label="Appointment status"
          labelId="appointment-status-label"
          value={statusFilterValue}
          onChange={(e) => handleFiltersChange("appointment_status_id", e)}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          open={isOpen}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          }}
          renderValue={(selected: any) =>
            selected.map((selectedFilter, i) => (
              <Chip
                key={`selected-filter-${selectedFilter}-${i}`}
                size="small"
                variant="outlined"
                color="primary"
                label={getStatusLabel(selectedFilter)}
                style={{ marginRight: 8 }}
                onDelete={() => handleRemoveFilter(selectedFilter, "appointment_status_id")}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
              />
            ))
          }
        >
          {APPOINTMENT_STATUSES_STATES_ARRAY.map((status, i) => (
            <MenuItem
              key={`statuses-${APPOINTMENT_STATUSES_STATES[status]}-${i}`}
              value={APPOINTMENT_STATUSES_STATES[status]}
            >
              {getStatusLabel(APPOINTMENT_STATUSES_STATES[status])}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" className={classes.statusFormControl} size="small">
        <InputLabel id="facility-label">Facility scheduled at</InputLabel>
        <Select
          id="facility"
          label="Facility scheduled at"
          labelId="facility-label"
          value={facFilterValue}
          onChange={(e) => handleFiltersChange("facility", e)}
        >
          <MenuItem value="">ALL</MenuItem>
          {facilities?.map((type, idx) => (
            <MenuItem key={idx} value={type?.name}>
              {type?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {(view === "agency" || !!showSuperAdminFilters) && (
        <FormControl variant="outlined" className={classes.statusFormControl} size="small">
          <DateRangePicker
            startText="From Due Date"
            endText="To Due Date"
            inputFormat="MM/dd/yyyy"
            label="From Due Date"
            value={selectedDueDate}
            onChange={handleDueDateFilterChange}
            renderInput={(startProps, endProps) => (
              <React.Fragment>
                <TextField {...startProps} size="small" />
                <DateRangeDelimiter> to </DateRangeDelimiter>
                <TextField {...endProps} size="small" />
              </React.Fragment>
            )}
          />
        </FormControl>
      )}
      {!!showSuperAdminFilters ||
        (view === "facility" && (
          <FormControl variant="outlined" className={classes.statusFormControl} size="small">
            <InputLabel id="facility-label">Agency scheduled by</InputLabel>
            <Select
              id="agency"
              label="Agency scheduled by"
              labelId="agency-label"
              value={agencyFilterValue}
              onChange={(e) => handleFiltersChange("agency", e)}
            >
              <MenuItem value="">ALL</MenuItem>
              {agencies?.map((type) => (
                <MenuItem key={type?.id} value={type?.name}>
                  {type?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ))}

      {!!showSuperAdminFilters && (
        <>
          <FormControl size="small" className={classes.statusFormControl}>
            <DateRangePicker
              startText="From Appointment Date"
              endText="To Appointment Date"
              inputFormat="MM/dd/yyyy"
              label="From Date"
              value={selectedDate}
              onChange={handleDateFilterChange}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} size="small" />
                  <DateRangeDelimiter> to </DateRangeDelimiter>
                  <TextField {...endProps} size="small" />
                </React.Fragment>
              )}
            />
          </FormControl>
          <FormControl
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              paddingBottom: 24,
            }}
            size="small"
          >
            <Checkbox checked={reviewFilterValue} onChange={handleReviewedFilterChange} /> Reviewed
          </FormControl>

          <FormControl
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              paddingBottom: 24,
            }}
            size="small"
          >
            <Checkbox checked={superAdminFilterDocs} onChange={handleHasDocsFilterChange} /> Has
            pending documents
          </FormControl>
        </>
      )}
    </>
  );
};

export default React.memo(AppointmentsFilters);
