import { createSelector } from "@reduxjs/toolkit";
import { isToday } from "date-fns";
import { AppState } from "../types";
import { groupBy } from "../../lib";
import { APPOINTMENT_STATUSES_STATES } from "../../constants";
const timeZoneOffset = (date) => {
  return new Date(new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60000);
};
const _state = (state: AppState) => state.agency;

// users
const _users = createSelector(_state, (state) => state.users ?? []);
const _usersLoading = createSelector(_state, (state) => state.usersLoading ?? false);

// patients
const _patients = createSelector(_state, (state) => {
  return (
    state.patients?.map((patient) => {
      const p = {
        ...patient,
        appointments:
          patient?.appointments?.map((appointment) => ({
            ...appointment,
            medical_facility_name: appointment.medical_facility_profile?.entity?.name,
          })) ?? [],
        latest_appointment_status_id:
          patient?.appointments && patient?.appointments[0]
            ? patient.appointments[0].appointment_status_id
            : null,
      };
      return p;
    }) ?? []
  );
});

const _patientsLoading = createSelector(_state, (state) => state.patientsLoading ?? false);
const _editablePatient = createSelector(_state, (state) => state.editablePatient ?? null);

const _appointments = createSelector(_state, (state) => state.appointments ?? []);
const _upcomingAppointments = createSelector(_state, (state) =>
  (state.appointments || []).filter((appointment) => isToday(timeZoneOffset(appointment.datetime))),
);
const _checkedInAppointments = createSelector(_state, (state) =>
  (state.appointments || []).filter(
    (appointment) =>
      appointment.appointment_status_id === APPOINTMENT_STATUSES_STATES.CHECKED_IN &&
      isToday(timeZoneOffset(appointment.datetime)),
  ),
);
const _scheduledAppointments = createSelector(
  _state,
  (state) =>
    //groupBy(
    (state.appointments || []).filter(
      (appointment) =>
        appointment.appointment_status_id === APPOINTMENT_STATUSES_STATES.SCHEDULED &&
        isToday(timeZoneOffset(appointment.datetime)),
    ),
  // convert hour field to local so it could be grouped properly
  //   .map(appointment => ({
  //     ...appointment,
  //     hour: format(new Date(appointment.datetime), "H:mm"),
  //   })),
  // "hour",
  //),
);
const _appointmentsLoading = createSelector(_state, (state) => state.appointmentsLoading ?? false);
const _openedAppointment = createSelector(_state, (state) => {
  return {
    ...state.openedAppointment,
    medical_facility: state.openedAppointment?.medical_facility_profile,
  };
});
const _openApptRateCodes = createSelector(_state, (state) => {
  return (
    state.openedAppointment?.appointment_rate_codes?.filter((r) => r.type === "rate_code") ?? []
  );
});
const _openApptBundles = createSelector(_state, (state) => {
  return (
    state.openedAppointment?.appointment_rate_codes?.filter((r) => r.type !== "rate_code") ?? []
  );
});

const _rescheduleAppointment = createSelector(
  _state,
  (state) => state.rescheduleAppointment ?? null,
);
const _appointmentsByHour = createSelector(_state, (state) =>
  state.appointments ? groupBy(state.appointments, "hour") ?? [] : [],
);
const _searchQuery = createSelector(_state, (state) => state.searchQuery ?? "");
const _billingHistory = createSelector(_state, (state) => state.billingHistory ?? []);

const _regions = createSelector(_state, (state) => state.regions ?? []);
const _regionsLoading = createSelector(_state, (state) => state.regionsLoading ?? false);

const _entities = createSelector(_state, (state) => state.entities ?? []);
const _entitiesLoading = createSelector(_state, (state) => state.entitiesLoading ?? false);

const _medicalFacilities = createSelector(_state, (state) => state.medicalFacilities ?? []);
const _medicalFacilitiesLoading = createSelector(
  _state,
  (state) => state.medicalFacilitiesLoading ?? false,
);

const _medicalFacilitiesAvailability = createSelector(
  _state,
  (state) => state.medicalFacilitiesAvailability ?? [],
);
const _medicalFacilitiesAvailabilityLoading = createSelector(
  _state,
  (state) => state.medicalFacilitiesAvailabilityLoading ?? false,
);

const _appointmentPatient = createSelector(_state, (state) => state.appointmentPatient ?? null);
const _appointmentExistingPatientID = createSelector(
  _state,
  (state) => state.appointmentExistingPatientID ?? null,
);
const _appointmentServices = createSelector(_state, (state) => state.appointmentServices ?? null);
const _appointmentTimeslot = createSelector(_state, (state) => state.appointmentTimeslot ?? null);
const _appointmentCode = createSelector(_state, (state) => state.appointmentCode ?? null);
const _appointmentUpdates = createSelector(_state, (state) => state.appointmentUpdates?.rows);
const _confirmedAppointment = createSelector(_state, (state) => state.confirmedAppointment ?? null);
const _isAppointmentConfirmed = createSelector(
  _state,
  (state) => state.isAppointmentConfirmed ?? false,
);
const _appointmentsHistory = createSelector(_state, (state) => state.appointmentsHistory ?? null);
const _appointmentHistoryList = createSelector(
  _state,
  (state) => state.appointmentHistoryList ?? [],
);

const _agency = createSelector(_state, (state) => state.agency ?? null);

const _rateCodes = createSelector(_state, (state) => state.rateCodes ?? []);
const _bundles = createSelector(_state, (state) => state.bundles ?? []);
const _activeRateCodes = createSelector(_state, (state) =>
  (state.rateCodes ?? []).filter((code) => Boolean(code.active)),
);
const _patientRateCodes = createSelector(_state, (state) => state.patientRateCodes ?? []);
const _rateCodesLoading = createSelector(_state, (state) => state.rateCodesLoading ?? false);
const _patientRateCodesLoading = createSelector(
  _state,
  (state) => state.patientRateCodesLoading ?? false,
);

const _printers = createSelector(_state, (state) => state.printers ?? []);
const _updates = createSelector(_state, (state) => state.updates ?? []);
const _updatesAmount = createSelector(_state, (state) => state.updates?.length ?? 0);
const _updatesExpected = createSelector(_state, (state) => state.expectedUpdates ?? []);
const _updatesExpectedAmount = createSelector(
  _state,
  (state) => state.expectedUpdates?.length ?? 0,
);

const _supportingDocs = createSelector(_state, (state) => state.supportingDocs ?? []);

const _notificationSettings = createSelector(_state, (state) => state.notificationSettings ?? []);
const _notificationSettingsLoading = createSelector(
  _state,
  (state) => state.notificationSettingsLoading ?? false,
);
const _notificationTypes = createSelector(_state, (state) => state.notificationTypes ?? []);

const _billingHistoryForTable = createSelector(_state, (state) =>
  (state.billingHistory || []).map(({ id, first_name, last_name, rate_codes }) => ({
    ...rate_codes[0],
    id,
    first_name,
    last_name,
  })),
);

const _chats = createSelector(_state, (state) => state.chats ?? []);
const _services = createSelector(_state, (state) => state.services ?? []);
const _messages = createSelector(_state, (state) => state.messages ?? []);
const _redLine = createSelector(_state, (state) => state.redLine ?? []);
const _facilityUnreads = createSelector(_state, (state) => state.facilityUnreads ?? 0);
const _agencyUnreads = createSelector(_state, (state) => state.agencyUnreads ?? 0);
const _superAdminUnreads = createSelector(_state, (state) => state.superAdminUnreads ?? 0);
const _messageSending = createSelector(_state, (state) => state.messageSending ?? false);

export const agencySelectors = {
  users: _users,
  usersLoading: _usersLoading,
  patients: _patients,
  patientsCount: (state: AppState) => state.agency.patientsCount,
  patientsLoading: _patientsLoading,
  editablePatient: _editablePatient,
  activeAppointment: (state: AppState) => state.agency.activeAppointment.appointment,
  appointments: _appointments,
  appointmentsCount: (state: AppState) => state.agency.appointmentsCount,
  appointmentsLoading: _appointmentsLoading,
  appointmentsByHour: _appointmentsByHour,
  upcomingAppointments: _upcomingAppointments,
  checkedInAppointments: _checkedInAppointments,
  scheduledAppointments: _scheduledAppointments,
  appointmentsHistory: _appointmentsHistory,
  appointmentHistoryList: _appointmentHistoryList,

  openedAppointment: _openedAppointment,
  openApptRateCodes: _openApptRateCodes,
  openApptBundles: _openApptBundles,
  rescheduleAppointment: _rescheduleAppointment,

  searchQuery: _searchQuery,
  billingHistory: _billingHistory,
  billingHistoryForTable: _billingHistoryForTable,

  regions: _regions,
  regionsLoading: _regionsLoading,

  entities: _entities,
  entitiesLoading: _entitiesLoading,
  medicalFacilities: _medicalFacilities,
  medicalFacilitiesLoading: _medicalFacilitiesLoading,
  medicalFacilitiesAvailability: _medicalFacilitiesAvailability,
  medicalFacilitiesAvailabilityLoading: _medicalFacilitiesAvailabilityLoading,
  appointmentPatient: _appointmentPatient,
  appointmentExistingPatientID: _appointmentExistingPatientID,
  appointmentServices: _appointmentServices,
  appointmentTimeslot: _appointmentTimeslot,
  appointmentCode: _appointmentCode,
  confirmedAppointment: _confirmedAppointment,
  isAppointmentConfirmed: _isAppointmentConfirmed,
  appointmentUpdates: _appointmentUpdates,
  agency: _agency,

  rateCodes: _rateCodes,
  activeRateCodes: _activeRateCodes,
  patientRateCodes: _patientRateCodes,
  rateCodesLoading: _rateCodesLoading,
  patientRateCodesLoading: _patientRateCodesLoading,

  printers: _printers,
  updates: _updates,
  updatesAmount: _updatesAmount,
  updatesExpected: _updatesExpected,
  updatesExpectedAmount: _updatesExpectedAmount,

  chats: _chats,
  messages: _messages,
  redLine: _redLine,
  facilityUnreads: _facilityUnreads,
  agencyUnreads: _agencyUnreads,
  superAdminUnreads: _superAdminUnreads,
  messageSending: _messageSending,
  notificationSettings: _notificationSettings,
  notificationTypes: _notificationTypes,
  notificationSettingsLoading: _notificationSettingsLoading,
  supportingDocs: _supportingDocs,
  bundles: _bundles,
  services: _services,
};
