import React, { useEffect, useState } from "react";
import { Button, Card, Grid, InputAdornment, Link, TextField, Typography } from "@material-ui/core";
import NumberFormat from "react-number-format";
import {
  useSelector,
  agencySelectors,
  useAction,
  facilityActions,
  authSelectors,
  useDispatch,
  uiActions,
  agencyActions,
} from "../../../../../state";
import { formatToDate, formatTimeSlot, showApptTime } from "../../../../../utils";
import { useStyles } from "./AppointmentInfo.styles";
import { useRole } from "../../../../../hooks";
import { StatusStateTag } from "../../../../../components/tags";
import { EventOutlined, History } from "@material-ui/icons";
import { CheckCircleOutlineIcon, EditOutlinedIcon } from "../../../../../components";
import { APPOINTMENT_STATUSES_STATES } from "../../../../../constants";
import ScheduleAppointmentModal from "../../../../../components/modals/ScheduleAppointmentModal";
import AppointmentHistoryModal from "./AppointmentHistoryModal";
import { formatDateTZ } from "../../../../../lib";

const AppointmentInfo = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const markAppointmentPaid = useAction(facilityActions.markAppointmentPaid);
  const openedAppointment = useSelector(agencySelectors.openedAppointment);
  const [paymentAmt, setPaymentAmt] = useState(0);
  const [amountToPay, setAmountToPay] = useState(0);
  const { patient = {} } = openedAppointment;
  const activeEntity = useSelector(authSelectors.activeEntity);
  const activeEntityType = useSelector(authSelectors.activeEntityType);
  const facilityName = openedAppointment?.medical_facility?.entity?.name;
  const agencyName = openedAppointment?.patient?.home_health_agency_profile?.entity?.name;
  const { isAgencyRole, isSuperAdmin, isFacilityRole } = useRole();
  const [scheduledText, setScheduledText] = useState("");
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const entity =
    activeEntityType === "facility"
      ? openedAppointment?.patient?.home_health_agency_profile?.entity
      : openedAppointment?.medical_facility?.entity;
  const [open, setOpen] = useState(false);
  const getAppointmentHistoryList = useAction(agencyActions.getAppointmentHistoryList);
  const activeEntityId = useSelector(authSelectors.activeEntityId);
  const [editMode, setEditMode] = useState(false);
  const facilityTimeZone = openedAppointment?.medical_facility?.timezone
    ? openedAppointment?.medical_facility?.timezone
    : "America/New York";
  useEffect(() => {
    switch (activeEntityType) {
      case "facility":
        setScheduledText(`Scheduled by ${openedAppointment.created_by} from ${agencyName}`);
        break;
      case "agency":
        if (openedAppointment?.appointment_status_id === APPOINTMENT_STATUSES_STATES.UNSCHEDULED) {
          setScheduledText(`Created by ${openedAppointment.created_by}`);
        } else {
          setScheduledText(`Scheduled at ${facilityName} by ${openedAppointment.created_by}`);
        }
        break;
      case "super_admin":
        if (
          openedAppointment?.appointment_status_id === APPOINTMENT_STATUSES_STATES.UNSCHEDULED ||
          !facilityName
        ) {
          setScheduledText(`Created by ${openedAppointment.created_by} from ${agencyName}`);
        } else {
          setScheduledText(
            `Scheduled at ${facilityName} by ${openedAppointment.created_by} from ${agencyName}`,
          );
        }
    }
  }, [
    activeEntityType,
    agencyName,
    facilityName,
    openedAppointment.created_by,
    openedAppointment?.appointment_status_id,
  ]);

  const handleMarkAsPaid = () => {
    if (+amountToPay > openedAppointment.total) {
      dispatch(
        uiActions.showError(
          `Payment amount cannot be more than the appointment total of $${openedAppointment.total}`,
        ),
      );
      return;
    }
    markAppointmentPaid(openedAppointment.medical_facility_id, openedAppointment.id, +amountToPay);
  };

  const handlePmtAmtChange = (e) => {
    setAmountToPay(e.target.value);
  };

  const handleScheduleAppt = () => {
    setShowScheduleModal(true);
  };
  const handleEdit = () => {
    setEditMode(true);
    setShowScheduleModal(true);
  };

  useEffect(() => {
    if (openedAppointment.total) {
      setPaymentAmt(openedAppointment.amount_paid);
      setAmountToPay(0);
    }
  }, [openedAppointment.amount_paid, openedAppointment.total]);

  return (
    <Card variant="outlined" className={classes.cardWithDetails}>
      <Typography className={classes.title}>Appointment Information</Typography>
      <StatusStateTag status={Number(openedAppointment.appointment_status_id)} />
      {!!openedAppointment?.original_apt_datetime && (
        <Grid container alignItems="center" justifyContent="flex-start" style={{ marginTop: 8 }}>
          <EventOutlined style={{ color: "#D11D1D" }} />
          <Typography className={classes.originalAptDatetime} style={{ marginLeft: 8 }}>
            {openedAppointment?.original_apt_datetime &&
              formatDateTZ(
                openedAppointment?.original_apt_datetime,
                facilityTimeZone,
                "EEEE - M/d/yyyy",
              ).toString()}
          </Typography>
        </Grid>
      )}
      {openedAppointment?.datetime && (
        <Grid container alignItems="center" justifyContent="flex-start" style={{ marginTop: 8 }}>
          <EventOutlined />
          <div style={{ marginLeft: 8 }}>
            <Typography>
              {openedAppointment?.datetime &&
                formatDateTZ(
                  openedAppointment?.datetime,
                  facilityTimeZone,
                  "EEEE - M/d/yyyy",
                ).toString()}
            </Typography>{" "}
            <Typography>
              {openedAppointment?.datetime &&
                showApptTime(openedAppointment) &&
                formatTimeSlot(new Date(openedAppointment?.datetime)).toString()}
            </Typography>
          </div>
          {openedAppointment?.appointment_status_id === APPOINTMENT_STATUSES_STATES.SCHEDULED &&
            !isFacilityRole && (
              <EditOutlinedIcon
                onClick={handleEdit}
                style={{ marginLeft: 16, cursor: "pointer" }}
              />
            )}
        </Grid>
      )}
      {openedAppointment?.appointment_status_id === APPOINTMENT_STATUSES_STATES.UNSCHEDULED &&
        !isFacilityRole && (
          <Button
            variant="text"
            color="primary"
            onClick={handleScheduleAppt}
            className={classes.scheduleBtn}
          >
            Schedule Appointment
          </Button>
        )}
      <Typography className={classes.subTitle}>{scheduledText}</Typography>
      {activeEntityType !== "super_admin" &&
        entity &&
        openedAppointment?.appointment_status_id !== APPOINTMENT_STATUSES_STATES.UNSCHEDULED && (
          <>
            <Typography>{entity.address}</Typography>
            <Typography>
              {entity.city}, {entity.state} {entity.zip}
            </Typography>
            <NumberFormat
              format="+1 (###) ###-####"
              mask="_"
              value={entity.phone}
              displayType="text"
            />
          </>
        )}
      {openedAppointment.reviewed && activeEntityType === "super_admin" && (
        <Typography className={classes.reviewedIcon}>
          <CheckCircleOutlineIcon fontSize="small" />
          Reviewed{" "}
        </Typography>
      )}

      {isSuperAdmin && (
        <Grid container alignItems="center" justifyContent="flex-start" className={classes.modal}>
          <Button
            onClick={() => {
              getAppointmentHistoryList(activeEntityId, openedAppointment.id);
              setOpen(true);
            }}
          >
            <History style={{ color: "#A41717" }} />
            <Typography style={{ marginLeft: 8, color: "#A41717", fontWeight: 600 }}>
              All activity
            </Typography>
          </Button>
        </Grid>
      )}

      <Typography className={classes.subTitle}>Payment</Typography>
      <Typography className={classes.totalPaid}>
        Total paid: ${parseFloat(openedAppointment.amount_paid).toFixed(2)}
      </Typography>
      <Grid container>
        <Grid item xs={8}>
          <TextField
            label="Add Payment"
            variant="outlined"
            size="small"
            value={amountToPay}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            onChange={handlePmtAmtChange}
            disabled={!isFacilityRole && !isSuperAdmin}
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            color="primary"
            className={classes.addBtn}
            onClick={handleMarkAsPaid}
            disabled={
              isAgencyRole ||
              (openedAppointment.medical_facility_id !== activeEntity.id && !isSuperAdmin) ||
              openedAppointment?.appointment_status_id === APPOINTMENT_STATUSES_STATES.UNSCHEDULED
            }
          >
            Add
          </Button>
        </Grid>
      </Grid>
      {!!isAgencyRole ||
        (openedAppointment?.appointment_status_id !== APPOINTMENT_STATUSES_STATES.UNSCHEDULED && (
          <Link
            className={classes.link}
            target={"_blank"}
            href={`https://levuppay.com/insurance/pay?amount=${paymentAmt}&description=LU-ID-${
              openedAppointment.patient_id
            } / MRN - ${patient?.mrn} / Appt-${openedAppointment.id} / ${formatToDate(
              openedAppointment.datetime,
            )}`}
          >
            Pay here
          </Link>
        ))}
      {showScheduleModal && (
        <ScheduleAppointmentModal
          open={showScheduleModal}
          setOpen={setShowScheduleModal}
          apptId={openedAppointment.id}
          editMode={editMode}
          setEditMode={setEditMode}
        />
      )}
      <AppointmentHistoryModal open={open} setOpen={setOpen} />
    </Card>
  );
};

export default React.memo(AppointmentInfo);
