import React from "react";
import { add } from "date-fns";
import {
  getRole,
  getFullName,
  getFullLocation,
  formatToDate,
  formatToDateTime,
  formatToTime,
  statusCompareFunc,
  getNotificationType,
  reviewedCompareFunc,
  sortDate,
  sortAmount,
  stringCompareFunc,
  currencyFormatter,
  formatTimeSlot,
  showApptTime,
} from "../utils";

import { renderInvoiceLink } from "../components/links";
import { renderStatusStateCell } from "../components/tags";
import { renderActiveCell, renderFormAvailable } from "../components/tags";
import { renderPhoneNumberCell } from "../components/text/PhoneNumberCell";
import DueDate from "../components/tables/AppointmentsTable/DueDate";
import { USER_TYPE_INSURED_TYPE, USER_TYPE_UNINSURED_TYPE } from "./users";
import {
  getStatusLabel,
  RCStatusStateTag,
  renderFormRequired,
  renderAptReviewed,
} from "../components/tags/StatusTag";
import { formatDateOnly, formatPhone, Navigation } from "../lib";
import { MarkPaidButton, VisibilityOutlinedIcon, LaunchIcon, MarkUnreadIcon } from "../components";
import { UpdatesActionButtons } from "../pages/agency/UpdatesPage";
import { Button, Tooltip } from "@material-ui/core";
import NumberFormat from "react-number-format";
import { RATE_CODE_STATUSES_STATES } from ".";
const timeZoneOffset = (date) => {
  return new Date(new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60000);
};
const ServicesToolTip = (text) => {
  return (
    <Tooltip title={text}>
      <span>{text}</span>
    </Tooltip>
  );
};
// TODO add types and interfaces

// COLUMNS
export const ID_COLUMN = { name: "id", title: "Id", align: "right", width: 60 };
export const NAME_COLUMN = {
  name: "name",
  title: "Name",
  getCellValue: getFullName,
};
export const PATIENT_NAME_COLUMN = {
  ...NAME_COLUMN,
  getCellValue: (row) => getFullName(row.patient),
};
export const HHA_NAME_COLUMN = {
  name: "name",
  title: "Name",
  getCellValue: (row) => getFullName(row.appointment.patient),
};
export const TYPE_COLUMN_FIELDS = {
  name: "type",
  title: "Type",
};
export const TYPE_COLUMN = {
  ...TYPE_COLUMN_FIELDS,
  getCellValue: (row) =>
    (typeof row.insured !== "undefined" ? row.insured : row.patient.insured)
      ? USER_TYPE_INSURED_TYPE
      : USER_TYPE_UNINSURED_TYPE,
};
export const APPOINTMENT_PATIENT_TYPE_COLUMN = {
  ...TYPE_COLUMN_FIELDS,
  getCellValue: (row) => (row.patient.insured ? USER_TYPE_INSURED_TYPE : USER_TYPE_UNINSURED_TYPE),
};
export const UPDATE_APPOINTMENT_PATIENT_TYPE_COLUMN = {
  ...TYPE_COLUMN_FIELDS,
  getCellValue: (row) =>
    row.appointment.patient.insured ? USER_TYPE_INSURED_TYPE : USER_TYPE_UNINSURED_TYPE,
};
export const PHONE_NUMBER_COLUMN = {
  name: "phone",
  title: "Phone",
  getCellValue: renderPhoneNumberCell,
  getExportValue: (row) =>
    row.patient?.phone
      ? formatPhone(row.patient.phone.toString())
      : formatPhone(row.phone.toString()),
};
export const PATIENT_PHONE_COLUMN = {
  ...PHONE_NUMBER_COLUMN,
  title: "Phone number",
};
export const PATIENT_EMPLOYEE_CODE_COLUMN = {
  name: "employee_id",
  title: "Caregiver code",
};
export const STATUS_COLUMN = {
  name: "status",
  getCellValue: renderStatusStateCell,
  getExportValue: (row) => getStatusLabel(row.appointment_status_id),
};
export const ACTIVE_COLUMN = {
  name: "active",
  getCellValue: renderStatusStateCell,
  getExportValue: (row) => getStatusLabel(row.appointment_status_id),
};
export const EMAIL_COLUMN = { name: "email", title: "Email" };
export const CREATED_AT_COLUMN = {
  name: "createdAt",
  title: "Caregiver since",
  getCellValue: (row) =>
    row.createdAt
      ? formatToDate(row.createdAt)
      : row.created_at
      ? formatToDate(row.created_at)
      : "-",
};
export const HHA_CREATED_AT_COLUMN = {
  name: "datetime",
  title: "Appointment Date",
  getCellValue: (row) => (row.appointment.datetime ? formatToDate(row.appointment.datetime) : "-"),
};
export const LOCATION_COLUMN = { name: "location", title: "Location" };
export const SERVICES_COLUMN = {
  name: "appointment_rate_codes",
  title: "Services",
  getCellValue: (row) => {
    const rc = row.appointment_rate_codes || row.appointment_rate_codes_details || [];
    const names = rc?.map((code) => code.name || code.description || "");
    return ServicesToolTip(!!names.length ? names.join(", ") : "-");
  },
};
export const COMPLIANCE_DATE_COLUMN = {
  name: "compliance_due_date",
  title: "Compliance Due Date",
  width: 200,
  getCellValue: (row) => {
    return <DueDate dueDate={row.compliance_due_date} />;
  },
};
export const getServiceBundles = (row) => {
  const bundles = row?.appointment_rate_codes?.filter((row) => row?.home_health_agency_bundle);
  const bundleIds = bundles?.map((row) => row.home_health_agency_bundle.id) || [];
  const bundleNames = row?.appointment_rate_codes
    ?.filter((code) => code?.rate_code_status_id !== 4 && !code?.home_health_agency_rate_code)
    ?.map((row) => row?.home_health_agency_bundle?.description);
  const individualServices = row?.appointment_rate_codes
    ?.filter(
      (code) =>
        code?.rate_code_status_id !== 4 &&
        !code?.home_health_agency_rate_code?.home_health_agency_bundle_rate_codes?.filter((row) =>
          bundleIds?.includes(row.bundle_id),
        ).length,
    )
    ?.map((row) => row?.home_health_agency_rate_code?.default_rate_code?.description)
    .sort()
    .filter((row) => row);

  return ServicesToolTip(
    !!individualServices?.length || !!bundleNames?.length
      ? `${bundleNames?.join(", ")}${
          !!individualServices?.length && !!bundleNames?.length ? ", " : ""
        }${individualServices?.join(", ")}`
      : "-",
  );
};
export const exportServiceBundles = (row) => {
  const bundles = row?.appointment_rate_codes?.filter((row) => row?.home_health_agency_bundle);
  const bundleIds = bundles.map((row) => row.home_health_agency_bundle.id);
  const bundleNames = row?.appointment_rate_codes
    ?.filter((code) => code?.rate_code_status_id !== 4 && !code?.home_health_agency_rate_code)
    ?.map((row) => row?.home_health_agency_bundle?.description);
  const individualServices = row?.appointment_rate_codes
    ?.filter(
      (code) =>
        code?.rate_code_status_id !== 4 &&
        !code?.home_health_agency_rate_code?.home_health_agency_bundle_rate_codes?.filter((row) =>
          bundleIds?.includes(row.bundle_id),
        ).length,
    )
    ?.map((row) => row?.home_health_agency_rate_code?.default_rate_code?.description)
    .sort()
    .filter((row) => row);
  return `${bundleNames?.join(";")}${
    !!individualServices?.length && !!bundleNames?.length ? ";" : ""
  }${individualServices?.join(";")}`;
};
export const APPOINTMENT_SERVICES_COLUMN = {
  ...SERVICES_COLUMN,
  getCellValue: (row) => {
    return getServiceBundles(row);
  },

  getExportValue: (row) => {
    return exportServiceBundles(row);
  },
};

export const SU_RATE_CODE_COLUMN = {
  name: "code",
  title: "Rate code",
};

export const HHA_RATE_CODE_COLUMN = {
  name: "code",
  title: "Rate code",
  getCellValue: (row) =>
    row?.home_health_agency_service?.home_health_agency_rate_code?.map(
      (rc) => rc.default_rate_code.code,
    ),
};
export const RATE_CODE_COLUMN = {
  name: "code",
  title: "Rate code",
  getCellValue: (row) => row.default_rate_code.code,
};
export const DESCRIPTION_COLUMN = {
  name: "description",
  title: "Description",
  getCellValue: (row) => ServicesToolTip(row.default_rate_code.description),
};

export const HHA_DESCRIPTION_COLUMN = {
  name: "description",
  title: "Description",
  getCellValue: (row) => row?.home_health_agency_rate_code?.default_rate_code?.description,
};
export const SU_DESCRIPTION_COLUMN = {
  name: "description",
  title: "Description",
};

export const FORM_REQUIRED_COLUMN = {
  name: "form_required",
  title: "Form Required",
  getCellValue: renderFormRequired,
};

export const DEFAULT_CHARGE_COLUMN = {
  name: "default_rate",
  title: "Default Charge",
  getCellValue: (row) => <NumberFormat value={row?.default_rate} displayType="text" prefix="$" />,
};

export const CHARGE_COLUMN = {
  name: "rate",
  title: "Charge",
};
export const SU_CHARGE_COLUMN = {
  name: "default_rate",
  title: "Default Charge",
  getCellValue: (row) => {
    const amount = !!row.default_rate_code ? row.default_rate_code.default_rate : row.default_rate;
    return <NumberFormat value={amount} prefix="$" displayType="text" />;
  },
};

export const SCHEDULED_BY_COLUMN = {
  name: "scheduled_by",
  title: "Scheduled By",
  getCellValue: (row) =>
    `${row.created_by_user?.first_name ?? ""} ${row.created_by_user?.last_name ?? ""}`,
};

export const DOB_COLUMN = {
  name: "date_of_birth",
  title: "DOB",
  getCellValue: (row) =>
    row.patient
      ? row.patient?.date_of_birth
        ? formatToDate(row.patient.date_of_birth)
        : "-"
      : row.date_of_birth
      ? formatToDate(row.date_of_birth)
      : "-",
};

export const APPT_DATE_COLUMN = {
  name: "datetime",
  title: "Appointment Date",
  width: "15%",
  getCellValue: (row) => {
    return `${formatToDateTime(row.datetime)}  ${
      showApptTime(row) ? formatTimeSlot(new Date(row.datetime)).toString() : ""
    }`;
  },
};

export const AGENCY_APPOINTMENTS_TABLE_COLUMNS = [
  { ...ID_COLUMN },
  {
    name: "unread_messages",
    title: " ",
    getCellValue: (row) => {
      return row.unread_messages ? (
        <div style={{ display: "flex", color: "#1155CC" }}>
          <MarkUnreadIcon />
          <div style={{ paddingLeft: 5 }}>{row.unread_messages}</div>
        </div>
      ) : null;
    },
    width: 110,
  },
  { ...NAME_COLUMN, getCellValue: (row) => getFullName(row.patient) },
  { ...DOB_COLUMN },
  { ...STATUS_COLUMN, title: "Status" },
  {
    name: "appt_due_date",
    title: "Due Date",
    getCellValue: (row) =>
      row.appt_due_date ? formatDateOnly(row.appt_due_date, "MM/dd/yyyy") : "-",
    width: 110,
  },
  PHONE_NUMBER_COLUMN,
  APPT_DATE_COLUMN,
  {
    ...LOCATION_COLUMN,
    getCellValue: (row) => row.medical_facility_profile?.entity?.name,
  },
  {
    ...SERVICES_COLUMN,
    getCellValue: (row) => {
      return getServiceBundles(row);
    },

    getExportValue: (row) => {
      return exportServiceBundles(row);
    },
  },
  SCHEDULED_BY_COLUMN,
  { ...CREATED_AT_COLUMN, title: "Date Created" },
];

export const AGENCY_USERS_TABLE_COLUMNS = [
  NAME_COLUMN,
  EMAIL_COLUMN,
  { name: "role", title: "Role", getCellValue: getRole },
];
export const NOTIFICATION_SETTINGS_TABLE_COLUMNS = [
  { name: "Method", title: "Method", getCellValue: (row) => row.method },
  { name: "Type", title: "Type", getCellValue: getNotificationType },
  { name: "Target", title: "Target", getCellValue: (row) => row.target },
  // EMAIL_COLUMN,
  // { name: "role", title: "Role", getCellValue: getRole },
];

export const FACILITY_USERS_TABLE_COLUMNS = [
  NAME_COLUMN,
  EMAIL_COLUMN,
  { name: "role", title: "Role", getCellValue: getRole },
];

export const SUPER_ADMIN_AVG_LAB_RESULTS_TABLE_COLUMNS = [
  { ...NAME_COLUMN, getCellValue: getFullLocation },
  LOCATION_COLUMN,
  { name: "result", title: "Avg lab results" },
];

export const AGENCY_PATIENTS_TABLE_COLUMNS = [
  ID_COLUMN,
  NAME_COLUMN,
  DOB_COLUMN,
  {
    name: "status",
    title: "Latest Appointment Status",
    getCellValue: (row) =>
      Boolean(row.appointments.length) ? renderStatusStateCell(row.appointments[0]) : "-",
    getExportValue: (row) =>
      row.appointments[0]?.appointment_status_id
        ? getStatusLabel(row.appointments[0].appointment_status_id)
        : "-",
  },
  COMPLIANCE_DATE_COLUMN,
  PHONE_NUMBER_COLUMN,
  PATIENT_EMPLOYEE_CODE_COLUMN,
  CREATED_AT_COLUMN,
];
export const FACILITY_PATIENTS_TABLE_COLUMNS = [
  ID_COLUMN,
  NAME_COLUMN,
  DOB_COLUMN,
  { name: "mrn", title: "MRN" },
  {
    name: "status",
    title: "Latest Appointment Status",
    getCellValue: (row) =>
      Boolean(row.appointments.length) ? renderStatusStateCell(row.appointments[0]) : "-",
    getExportValue: (row) =>
      row.appointments[0]?.appointment_status_id
        ? getStatusLabel(row.appointments[0].appointment_status_id)
        : "-",
  },
  PHONE_NUMBER_COLUMN,
  CREATED_AT_COLUMN,
];
export const SUPER_ADMIN_PATIENTS_TABLE_COLUMNS = [
  ID_COLUMN,
  NAME_COLUMN,
  DOB_COLUMN,
  { name: "mrn", title: "MRN" },
  {
    name: "status",
    title: "Latest Appointment Status",
    getCellValue: (row) =>
      Boolean(row.appointments.length) ? renderStatusStateCell(row.appointments[0]) : "-",
    getExportValue: (row) =>
      row.appointments[0]?.appointment_status_id
        ? getStatusLabel(row.appointments[0].appointment_status_id)
        : "-",
  },
  COMPLIANCE_DATE_COLUMN,
  {
    name: "agency_name",
    title: "Agency",
    // getCellValue: (row) => row.home_health_agency_profile?.entity?.name,
  },
  PHONE_NUMBER_COLUMN,
  PATIENT_EMPLOYEE_CODE_COLUMN,
  CREATED_AT_COLUMN,
];

export const APPOINTMENT_HISTORY_TABLE_COLUMNS = [
  { name: "datetime", getCellValue: (row) => formatToDateTime(row.datetime) },
  { ...NAME_COLUMN, getCellValue: (row) => row.medical_facility_name },
  STATUS_COLUMN,
  {
    name: "appointment_rate_codes",
    getCellValue: (row) => {
      const arc = row.appointment_rate_codes || row.appointment_rate_codes_details;
      const names = arc.map((code) => {
        const rc = code.appointment_rc || code.arc;
        return rc?.default_rate_code?.description || code.description;
      });
      return Boolean(names.length) ? names.join(", ") : "-";
    },
  },
];

export const SUPER_ADMIN_AGENCIES_TABLE_COLUMNS = [
  { name: "name", title: "Name" },
  { ...ACTIVE_COLUMN, title: "Active", getCellValue: renderActiveCell },
  PHONE_NUMBER_COLUMN,
  { ...CREATED_AT_COLUMN, title: "Member since" },
];
export const SUPER_ADMIN_AGENCY_BUNDLE_COLUMNS = [
  { name: "name", title: "Name" },
  {
    name: "services",
    title: "Services",
    getCellValue: (row) => {
      const activeRateCodes = row.rate_codes.filter((r) => Object.keys(r).length !== 0);
      return ServicesToolTip(activeRateCodes?.map((r) => r.description)?.join(", "));
    },
  },
  DEFAULT_CHARGE_COLUMN,
  CHARGE_COLUMN,
  {
    name: "filenames_id",
    title: "Form Provided",
    getCellValue: renderFormAvailable,
  },
  FORM_REQUIRED_COLUMN,
  { name: "active", title: "Status", getCellValue: renderActiveCell },
];

export const SUPER_ADMIN_AGENCY_RATE_CODES_TABLE_COLUMNS = [
  RATE_CODE_COLUMN,
  DESCRIPTION_COLUMN,
  SU_CHARGE_COLUMN,
  CHARGE_COLUMN,
  {
    name: "form_location",
    title: "Form Provided",
    getCellValue: renderFormAvailable,
  },
  FORM_REQUIRED_COLUMN,
  { ...ACTIVE_COLUMN, title: "Status", getCellValue: renderActiveCell },
];

export const SUPER_ADMIN_DEFAULT_RATE_CODES_TABLE_COLUMNS = [
  SU_DESCRIPTION_COLUMN,
  SU_CHARGE_COLUMN,
  {
    name: "provider_cost",
    title: "Cost price",
    getCellValue: (row) => <NumberFormat value={row.provider_cost} prefix="$" displayType="text" />,
  },
  {
    name: "form_location",
    title: "Form Provided",
    getCellValue: renderFormAvailable,
  },
  FORM_REQUIRED_COLUMN,
];

export const SUPER_ADMIN_DEFAULT_BUNDLES_COLUMNS = [
  {
    name: "name",
    title: "Name",
  },
  SU_CHARGE_COLUMN,
  {
    ...SERVICES_COLUMN,
    getCellValue: (row) => {
      const names = row?.default_bundle_rate_codes?.map((rc) => rc?.default_rate_code?.description);
      return ServicesToolTip(!!names?.length ? names.join(", ") : "-");
    },
  },
  {
    name: "filenames_id",
    title: "Form Provided",
    getCellValue: renderFormAvailable,
  },
  FORM_REQUIRED_COLUMN,
];

export const AGENCY_RATE_CODES_TABLE_COLUMNS = [
  RATE_CODE_COLUMN,
  DESCRIPTION_COLUMN,
  CHARGE_COLUMN,
  {
    name: "form_name",
    title: "Form",
    getCellValue: renderFormAvailable,
  },
  { ...ACTIVE_COLUMN, title: "Active", getCellValue: renderActiveCell },
];

export const AGENCY_VIEW_RATE_CODES_TABLE_COLUMNS = [
  { name: "code", title: "Rate code" },
  { name: "description", title: "Description" },
  CHARGE_COLUMN,
  {
    name: "form_name",
    title: "Form",
    getCellValue: renderFormAvailable,
  },
  FORM_REQUIRED_COLUMN,
];

export const SUPER_ADMIN_AGENCY_APPOINTMENTS_TABLE_COLUMNS = [
  {
    name: "view",
    title: " ",
    getCellValue: (row) => (
      <Button onClick={() => Navigation.go(`/super-admin/appointment/${row.id}`)}>
        <VisibilityOutlinedIcon />
      </Button>
    ),
  },
  {
    name: "launch",
    title: " ",
    getCellValue: (row) => (
      <Button onClick={() => window.open(`/super-admin/appointment/${row.id}`, "_blank")}>
        <LaunchIcon />
      </Button>
    ),
  },
  { ...ID_COLUMN },
  {
    name: "unread_messages",
    title: " ",
    getCellValue: (row) => {
      return row.unread_messages ? (
        <div style={{ display: "flex", color: "#1155CC" }}>
          <MarkUnreadIcon />
          <div style={{ paddingLeft: 5 }}>{row.unread_messages}</div>
        </div>
      ) : null;
    },
    width: 110,
  },
  { ...NAME_COLUMN, getCellValue: (row) => getFullName(row.patient) },
  { ...DOB_COLUMN },
  { ...STATUS_COLUMN, title: "Status" },
  {
    name: "appt_due_date",
    title: "Due Date",
    getCellValue: (row) =>
      row.appt_due_date ? formatDateOnly(row.appt_due_date, "MM/dd/yyyy") : "-",
    width: 110,
  },
  PHONE_NUMBER_COLUMN,
  APPT_DATE_COLUMN,
  {
    ...LOCATION_COLUMN,
    getCellValue: (row) => row.medical_facility_profile?.entity?.name,
  },
  {
    ...SERVICES_COLUMN,
    getCellValue: (row) => {
      return getServiceBundles(row);
    },

    getExportValue: (row) => {
      return exportServiceBundles(row);
    },
  },
  SCHEDULED_BY_COLUMN,
  { ...CREATED_AT_COLUMN, title: "Date Created" },
];
export const SUPER_ADMIN_ALL_APPOINTMENTS_TABLE_COLUMNS = [
  {
    name: "view",
    title: " ",
    getCellValue: (row) => (
      <Button onClick={() => Navigation.go(`/super-admin/appointment/${row.id}`)}>
        <VisibilityOutlinedIcon />
      </Button>
    ),
  },
  {
    name: "launch",
    title: " ",
    getCellValue: (row) => (
      <Button onClick={() => window.open(`/super-admin/appointment/${row.id}`, "_blank")}>
        {" "}
        <LaunchIcon />
      </Button>
    ),
  },
  { ...ID_COLUMN },
  {
    name: "unread_messages",
    title: " ",
    getCellValue: (row) => {
      return row.unread_messages ? (
        <div style={{ display: "flex", color: "#1155CC" }}>
          <MarkUnreadIcon />
          <div style={{ paddingLeft: 5 }}>{row.unread_messages}</div>
        </div>
      ) : null;
    },
    width: 110,
  },
  { ...STATUS_COLUMN, title: "Status" },
  { ...NAME_COLUMN, getCellValue: (row) => getFullName(row.patient) },
  { ...DOB_COLUMN },
  {
    name: "appt_due_date",
    title: "Due Date",
    getCellValue: (row) =>
      row.appt_due_date ? formatDateOnly(row.appt_due_date, "MM/dd/yyyy") : "-",
    width: 110,
  },
  {
    name: "mrn",
    title: "MRN",
    getCellValue: (row) => row.patient?.mrn,
  },
  {
    name: "agency",
    title: "Agency",
    getCellValue: (row) => row.patient?.home_health_agency_profile?.entity?.name,
  },
  {
    name: "facility",
    title: "Facility",
    getCellValue: (row) => row.medical_facility_profile?.entity?.name,
  },
  APPT_DATE_COLUMN,
  {
    name: "completed_at",
    title: "Completed Date",
    getCellValue: (row) => formatToDateTime(row.completed_at),
  },
  SCHEDULED_BY_COLUMN,
  { ...CREATED_AT_COLUMN, title: "Date Created" },
  {
    ...SERVICES_COLUMN,
    name: "services",
    getCellValue: (row) => {
      return getServiceBundles(row);
    },

    getExportValue: (row) => {
      return exportServiceBundles(row);
    },
  },
  {
    name: "amount_paid",
    title: "Total paid",
    getCellValue: (row) => currencyFormatter({ value: row.amount_paid || 0 }),
  },
  {
    name: "total",
    title: "Total charged",
    getCellValue: (row) => {
      const providedTotals = row.appointment_rate_codes?.filter(
        ({ rate_code_status_id: id }) =>
          id === RATE_CODE_STATUSES_STATES.PROVIDED ||
          id === RATE_CODE_STATUSES_STATES.COMPLETED ||
          id === RATE_CODE_STATUSES_STATES.PENDING,
      );
      const total = providedTotals?.reduce((acc, code) => acc + Number(code.rate), 0);
      return currencyFormatter({ value: total || 0 });
    },
  },
  {
    name: "cost",
    title: "Total cost",
    width: 80,
    getCellValue: (row) => {
      const cost = row.appointment_rate_codes?.filter(
        ({ rate_code_status_id: id }) =>
          id === RATE_CODE_STATUSES_STATES.PROVIDED ||
          id === RATE_CODE_STATUSES_STATES.COMPLETED ||
          id === RATE_CODE_STATUSES_STATES.PENDING,
      );
      const total = cost?.reduce((acc, code) => acc + Number(code.provider_cost), 0);
      return currencyFormatter({ value: total || 0 });
    },
  },
  {
    name: "days_count",
    title: "Completed Days",
    width: 80,
  },
  {
    name: "reviewed",
    title: "Reviewed",
    getCellValue: renderAptReviewed,
  },
];

export const FACILITY_APPOINTMENTS_TABLE_COLUMNS = [
  ID_COLUMN,
  {
    name: "unread_messages",
    title: " ",
    getCellValue: (row) => {
      return row.unread_messages ? (
        <div style={{ display: "flex", color: "#1155CC" }}>
          <MarkUnreadIcon />
          <div style={{ paddingLeft: 5 }}>{row.unread_messages}</div>
        </div>
      ) : null;
    },
    width: 110,
  },
  { ...NAME_COLUMN, getCellValue: (row) => getFullName(row.patient) },
  { ...DOB_COLUMN },
  { ...STATUS_COLUMN, title: "Status" },
  PHONE_NUMBER_COLUMN,
  APPT_DATE_COLUMN,
  {
    ...SERVICES_COLUMN,
    getCellValue: (row) => {
      return getServiceBundles(row);
    },

    getExportValue: (row) => {
      return exportServiceBundles(row);
    },
  },
];
export const ALL_FACILITY_APPOINTMENTS_TABLE_COLUMNS = [
  ...FACILITY_APPOINTMENTS_TABLE_COLUMNS,
  {
    ...LOCATION_COLUMN,
    getCellValue: (row) =>
      `${row.medical_facility_profile?.entity?.name}: ${row.medical_facility_profile?.entity?.city}, ${row.medical_facility_profile?.entity?.state} - ${row.medical_facility_profile?.entity?.address}`,
  },
];

export const SUPER_ADMIN_AGENCY_INVOICES_TABLE_COLUMNS = [
  { name: "filename", title: "Invoice", getCellValue: renderInvoiceLink },
  {
    name: "invoice_date",
    getCellValue: (row) => (row.invoice_date ? formatToDate(row.invoice_date) : "-"),
    title: "Created",
  },
  {
    name: "paid",
    title: "Paid",
    getCellValue: (row) => <MarkPaidButton {...row} />,
  },
];

export const SUPER_ADMIN_AGENCY_BILLING_HISTORY_TABLE_COLUMNS = [
  { name: "name", title: "Name", getCellValue: (row) => `${row.first_name} ${row.last_name}` },
  {
    name: "code",
    title: "Rate code",
    getCellValue: (row) => row?.code,
  },
  {
    name: "description",
    title: "Description",
    getCellValue: (row) => row.description,
  },
  CHARGE_COLUMN,
];

export const SUPER_ADMIN_LABS_TABLE_COLUMNS = [
  { name: "name", title: "Name" },
  PHONE_NUMBER_COLUMN,
  EMAIL_COLUMN,
];
export const SUPER_ADMIN_REGION_TABLE_COLUMNS = [
  { name: "id", title: "ID" },
  { name: "name", title: "Name" },
];

export const SUPER_ADMIN_FACILITIES_TABLE_COLUMNS = [
  { name: "name", title: "Name" },
  { ...ACTIVE_COLUMN, title: "Active", getCellValue: renderActiveCell },
  PHONE_NUMBER_COLUMN,
  // EMAIL_COLUMN,
  { name: "region", title: "Region" },
];

export const SUPER_ADMIN_FACILITY_APPOINTMENTS_TABLE_COLUMNS = [
  {
    name: "view",
    title: " ",
    getCellValue: (row) => (
      <Button onClick={() => Navigation.go(`/super-admin/appointment/${row.id}`)}>
        <VisibilityOutlinedIcon />
      </Button>
    ),
  },
  {
    name: "launch",
    title: " ",
    getCellValue: (row) => (
      <Button onClick={() => window.open(`/super-admin/appointment/${row.id}`, "_blank")}>
        <LaunchIcon />
      </Button>
    ),
  },
  ID_COLUMN,
  {
    name: "unread_messages",
    title: " ",
    getCellValue: (row) => {
      return row.unread_messages ? (
        <div style={{ display: "flex", color: "#1155CC" }}>
          <MarkUnreadIcon />
          <div style={{ paddingLeft: 5 }}>{row.unread_messages}</div>
        </div>
      ) : null;
    },
    width: 110,
  },
  PATIENT_NAME_COLUMN,
  DOB_COLUMN,
  { ...STATUS_COLUMN, title: "Status" },
  PATIENT_PHONE_COLUMN,
  APPT_DATE_COLUMN,
  // TYPE_COLUMN,
  APPOINTMENT_SERVICES_COLUMN,
];

export const UPLOAD_UPDATES_TABLE_COLUMNS = [
  { ...ID_COLUMN, name: "appointment_id" },
  {
    name: "appointment",
    title: "Appointment Time",
    getCellValue: (row) => formatToDateTime(row.appointment.datetime),
  },
  PATIENT_NAME_COLUMN,
  PHONE_NUMBER_COLUMN,
  HHA_RATE_CODE_COLUMN,
];

export const APPOINTMENTS_BY_DATE_TABLE_COLUMNS = [
  ID_COLUMN,
  NAME_COLUMN,
  PHONE_NUMBER_COLUMN,
  { ...STATUS_COLUMN, title: "Status" },
  {
    name: "datetime",
    title: "Time",
    getCellValue: (row) =>
      `${formatToTime(row.datetime)} - ${formatToTime(
        add(timeZoneOffset(row.datetime), { hours: 1 }),
      )}`,
  },
  { ...LOCATION_COLUMN, getCellValue: getFullLocation },
];

export const APPOINTMENTS_BY_HOUR_TABLE_COLUMNS = [
  ID_COLUMN,
  NAME_COLUMN,
  PHONE_NUMBER_COLUMN,
  { ...STATUS_COLUMN, title: "Status" },
  { ...LOCATION_COLUMN, getCellValue: getFullLocation },
  SERVICES_COLUMN,
];

export const UPCOMING_APPOINTMENTS_TABLE_COLUMNS = [
  {
    name: "datetime",
    title: "Checked In",
    getCellValue: (row) => formatTimeSlot(new Date(row.datetime)).toString(),
  },
  ID_COLUMN,
  PATIENT_NAME_COLUMN,
  PATIENT_PHONE_COLUMN,
  APPOINTMENT_SERVICES_COLUMN,
];

export const SCHEDULED_APPOINTMENTS_TABLE_COLUMNS = [
  {
    name: "datetime",
    title: "Appointment Time",
    width: 200,
    getCellValue: (row) => {
      return showApptTime(row) ? formatTimeSlot(new Date(row.datetime)).toString() : "";
    },
  },
  ID_COLUMN,
  PATIENT_NAME_COLUMN,
  PATIENT_PHONE_COLUMN,
  APPOINTMENT_SERVICES_COLUMN,
];

export const UPDATES_APPOINTMENTS_TABLE_COLUMNS = [
  ...SCHEDULED_APPOINTMENTS_TABLE_COLUMNS,
  { ...STATUS_COLUMN, title: "Status" },
  {
    name: "actions",
    title: "Actions",
    getCellValue: (row) => {
      return <UpdatesActionButtons {...row} />;
    },
  },
];

export const UPDATES_APPOINTMENTS_RC_TABLE_COLUMNS = [
  { ...ID_COLUMN },
  {
    name: "name",
    title: "Service Name",
    getCellValue: (row) =>
      row?.home_health_agency_rate_code?.default_rate_code?.description ||
      row?.home_health_agency_bundle?.description,
  },
  {
    name: "has_download",
    title: "Has Download",
    getCellValue: (row) =>
      row?.forms.length ? (
        <div>
          {row?.forms.length} Form {row.forms.length > 1 ? "files" : "file"} ready for download
        </div>
      ) : (
        <div></div>
      ),
  },
  {
    name: "form_required",
    title: "Form",
    getCellValue: (row) =>
      (row.type === "rate_code" && !!row?.home_health_agency_rate_code?.form_required) ||
      (row.type === "bundle" && !!row.home_health_agency_bundle?.form_required) ? (
        <div>Form required</div>
      ) : (
        <div>Form not required</div>
      ),
  },
  {
    name: "status",
    title: "Status",
    getCellValue: (row) => {
      return <RCStatusStateTag status={row.rate_code_status_id} documents_pending={true} />;
    },
  },
];

// EXTENSIONS
export const TABLE_COLUMN_EXTENSIONS_DEFAULT = [
  // TODO define more default presets
  { columnName: "id", align: "left", width: 70 },
  { columnName: "actions", align: "center", width: 120 },
];

export const SUPER_ADMIN_ALL_APPTS_DEFAULT_HIDDEN_COLUMNS = ["amount_paid", "total"];

export const SUPER_ADMIN_AGENCY_BUNDLES_TABLE_COLUMN_EXTENSIONS = [
  ...TABLE_COLUMN_EXTENSIONS_DEFAULT,
  { columnName: "name", width: 300 },
  { columnName: "default_rate", width: 160 },
  { columnName: "form_required", width: 180 },
  { columnName: "filenames_id", width: 180 },
  { columnName: "rate", width: 100 },
  { columnName: "active", width: 100 },
  { columnName: "actions", align: "right", width: 100 },
];

export const AGENCY_PATIENTS_TABLE_COLUMN_EXTENSIONS = [
  ...TABLE_COLUMN_EXTENSIONS_DEFAULT,
  { columnName: "id", align: "right", width: 60 },
  { columnName: "created_at", align: "center", width: 140 },
  { columnName: "status", width: 300 },
  { columnName: "name", width: 300 },
  { columnName: "phone", width: 200 },
  { columnName: "mrn", width: 250 },
  { columnName: "employee_id", width: 200 },
];

export const SUPER_ADMIN_AGENCIES_TABLE_COLUMN_EXTENSIONS = [
  ...TABLE_COLUMN_EXTENSIONS_DEFAULT,
  { columnName: "status", width: 160 },
  { columnName: "phone", width: 160 },
  { columnName: "created_at", align: "center", width: 140 },
];

export const SUPER_ADMIN_AGENCY_APPOINTMENTS_TABLE_COLUMN_EXTENSIONS = [
  { columnName: "id", width: "5%" },
  { columnName: "name", width: "10%" },
  { columnName: "date_of_birth", width: "10%" },
  { columnName: "status", width: "10%" },
  { columnName: "phone", width: "10%" },
  { columnName: "datetime", width: "10%" },
  { columnName: "location", width: "10%" },
  { columnName: "services", width: "25%" },
  { columnName: "createdAt", width: "10%" },
  { columnName: "scheduled_by", width: "10%" },
  { columnName: "view", width: "6%" },
  { columnName: "launch", width: "6%" },
];
export const SUPER_ADMIN_ALL_APPOINTMENTS_COLUMN_EXTENSIONS = [
  { columnName: "id", width: "6%" },
  { columnName: "status", width: "8%" },
  { columnName: "name", width: "10%" },
  { columnName: "date_of_birth", width: "10%" },
  { columnName: "reviewed", width: "8%", align: "center" },
  { columnName: "mrn", width: "8%" },
  { columnName: "datetime", width: "11%" },
  { columnName: "completed_at", width: "11%" },
  { columnName: "scheduled_by", width: "10%" },
  { columnName: "createdAt", width: "10%" },
  { columnName: "facility", width: "10%" },
  { columnName: "agency", width: "10%" },
  { columnName: "services", width: "15%" },
  { columnName: "days_count", width: "11%", align: "center" },
  { columnName: "total", width: "8%" },
  { columnName: "amount_paid", width: "10%" },
  { columnName: "view", width: "5%" },
  { columnName: "launch", width: "5%" },
];

export const SUPER_ADMIN_AGENCY_BILLING_HISTORY_TABLE_COLUMN_EXTENSIONS = [
  { columnName: "actions", align: "center", width: 200 },
];

export const SUPER_ADMIN_LABS_TABLE_COLUMN_EXTENSIONS = [
  ...TABLE_COLUMN_EXTENSIONS_DEFAULT,
  { columnName: "phone", width: 120 },
];

export const SUPER_ADMIN_FACILITIES_TABLE_COLUMN_EXTENSIONS = [
  ...TABLE_COLUMN_EXTENSIONS_DEFAULT,
  { columnName: "status", width: 160 },
  { columnName: "phone", width: 160 },
];

export const UPDATES_APPOINTMENTS_TABLE_EXTENSIONS = [
  { columnName: "id", width: 80 },
  { columnName: "name", width: 160 },
  { columnName: "phone", width: 160 },
  { columnName: "status", width: 100 },
  { columnName: "actions", width: 190 },
];

export const SUPER_ADMIN_DEFAULT_BUNDLES_COLUMNS_EXTENSIONS = [
  ...TABLE_COLUMN_EXTENSIONS_DEFAULT,
  { columnName: "name", width: 300 },
  { columnName: "default_rate", width: 160 },
  { columnName: "filenames_id", width: 160 },
  { columnName: "form_required", width: 160 },
];

export const SUPER_ADMIN_FACILITY_APPOINTMENTS_TABLE_COLUMN_EXTENSIONS = [
  { columnName: "id", width: 100 },
  { columnName: "name", width: 280 },
  { columnName: "status", width: 150 },
  { columnName: "phone", width: 200 },
  { columnName: "datetime", width: 180 },
  { columnName: "view", width: 100 },
  { columnName: "launch", width: 100 },
];

export const UPLOAD_UPDATES_TABLE_COLUMN_EXTENSIONS = [
  { columnName: "appointment_id", align: "right", width: 80 },
  { columnName: "appointment", width: 160 },
  { columnName: "status", width: 100 },
  { columnName: "phone", width: 200 },
  { columnName: "actions", align: "right", width: 200 },
];

export const APPOINTMENTS_BY_DATE_TABLE_COLUMN_EXTENSIONS = [
  { columnName: "id", align: "right", width: 70 },
  { columnName: "phone", width: 130 },
  { columnName: "status", width: 100 },
  { columnName: "datetime", width: 110 },
];

export const APPOINTMENTS_BY_HOUR_TABLE_COLUMN_EXTENSIONS = [
  { columnName: "id", align: "right", width: 70 },
  { columnName: "phone", width: 130 },
  { columnName: "status", width: 100 },
  { columnName: "datetime", width: 110 },
];

export const UPCOMING_APPOINTMENTS_TABLE_EXTENSION = [
  ...TABLE_COLUMN_EXTENSIONS_DEFAULT,
  { columnName: "datetime", width: 200 },
  { columnName: "id", align: "right", width: 150 },
  { columnName: "actions", align: "right", width: 300 },
];

// DEFAULT SORTING
export const TABLE_DEFAULT_SORTING = [{ columnName: "name", direction: "asc" }];
export const PATIENTS_DEFAULT_SORTING = [{ columnName: "id", direction: "desc" }];
export const SUPER_ADMIN_APPOINTMENTS_TABLE_SORTING = [
  { columnName: "services", sortingEnabled: false },
  { columnName: "total", sortingEnabled: false },
];
export const SUPER_ADMIN_AGENCY_APPOINTMENTS_TABLE_SORTING = [
  { columnName: "id", direction: "desc" },
];
export const APPOINTMENTS_DATETIME_SORTING = [{ columnName: "datetime", direction: "asc" }];
// SORT EXTENSIONS
export const DEFAULT_SORTING_EXTENSIONS = [
  { columnName: "status", compare: statusCompareFunc, sortingEnabled: false },
  { columnName: "active", compare: statusCompareFunc },
  { columnName: "reviewed", compare: reviewedCompareFunc },
  { columnName: "datetime", compare: sortDate },
  { columnName: "default_rate", compare: sortAmount },
  { columnName: "rate", compare: sortAmount },
  { columnName: "form_required", compare: reviewedCompareFunc },
  { columnName: "filenames_id", compare: reviewedCompareFunc },
  { columnName: "form_location", compare: reviewedCompareFunc },
  { columnName: "description", compare: stringCompareFunc },
  { columnName: "name", compare: stringCompareFunc },
  { columnName: "services", compare: stringCompareFunc },
  { columnName: "appointment_rate_codes", compare: stringCompareFunc, sortingEnabled: false },
  { columnName: "code", compare: stringCompareFunc },
  { columnName: "location", compare: stringCompareFunc, sortingEnabled: false },
  //TODO: remove sort for phone - this isn't working
  // { columnName: "phone", sortingEnabled: false },
];

// OTHER
export const TABLE_ROW_EDIT_ACTION: string = "EDIT";
export const TABLE_ROW_DELETE_ACTION: string = "DELETE";
export const TABLE_ROW_DOWNLOAD_ACTION: string = "DOWNLOAD";
export const TABLE_ROW_VIEW_ACTION: string = "VIEW";
export const TABLE_ROW_UPLOAD_ACTION: string = "UPLOAD";
export const TABLE_ROW_CHECKIN_ACTION: string = "CHECKIN";
export const ROWS_PER_PAGE_OPTIONS: number[] = [10, 25, 50, 100];
