import { isBefore, isValid, isAfter, isEqual } from "date-fns";
const multipleFiltersPredicateFunc = (value, filter) =>
  !filter.value.length ? true : filter?.value?.indexOf(value) !== -1;

const singleStringPredicateFunc = (value, filter) =>
  !filter.value.length ? true : value?.toLowerCase() === filter.value.toLowerCase();

const booleanPredicateFunc = (value, filter) =>
  !filter?.value ? true : value?.props?.titleAccess === filter?.value;

const datePredicateFunc = (value, filter) => {
  if (!isValid(new Date(value)) || !filter?.value?.length) return true;
  if (isEqual(filter.value[0], new Date(value)) || isEqual(filter.value[1], new Date(value))) {
    return true;
  }
  const isAfterFromDate = filter.value[0] ? isAfter(new Date(value), filter.value[0]) : true;
  const isBeforeEndDate = filter.value[1] ? isBefore(new Date(value), filter.value[1]) : true;
  return isAfterFromDate && isBeforeEndDate;
};

const RATE_CODE_FILTER = { columnName: "code", value: [] };

const LATEST_APPOINTMENT_STATUS_FILTER_EXTENSION = {
  columnName: "latest_appointment_status_id",
  predicate: multipleFiltersPredicateFunc,
};

const APPOINTMENT_STATUS_FILTER_EXTENSION = {
  columnName: "appointment_status_id",
  predicate: multipleFiltersPredicateFunc,
};

const APPOINTMENT_FACILITY_FILTER_EXTENSION = {
  columnName: "facility",
  predicate: singleStringPredicateFunc,
};
const APPOINTMENT_DATE_FILTER_EXTENSION = {
  columnName: "datetime",
  predicate: datePredicateFunc,
};
const APPOINTMENT_AGENCY_FILTER_EXTENSION = {
  columnName: "agency",
  predicate: singleStringPredicateFunc,
};
const APPOINTMENT_REVIEWED_FILTER_EXTENSION = {
  columnName: "reviewed",
  predicate: booleanPredicateFunc,
};
const APPOINTMENT_HAS_PENDING_DOCS_FILTER_EXTENSION = {
  columnName: "has_pending_docs",
  predicate: booleanPredicateFunc,
};

const RATE_CODE_FILTER_EXTENSION = {
  columnName: "code",
  predicate: multipleFiltersPredicateFunc,
};

export const PATIENTS_FILTERS = [
  //TYPE_FILTER,
  { columnName: "latest_appointment_status_id", value: [] },
  { columnName: "agency", value: [] },
];

export const APPOINTMENTS_FILTERS = [
  { columnName: "appointment_status_id", value: [] },
  { columnName: "facility", value: [] },
  { columnName: "agency", value: [] },
  { columnName: "reviewed", value: null },
  { columnName: "has_pending_docs", value: null },
  { columnName: "datetime", value: [] },
  { columnName: "due_date", value: [] },
];

export const BILLING_FILTERS = [RATE_CODE_FILTER];

export const UPDATES_FILTERS = [
  RATE_CODE_FILTER,
  //TYPE_FILTER
];

export const PATIENTS_FILTERS_EXTENSIONS = [
  //TYPE_FILTER_EXTENSION,
  LATEST_APPOINTMENT_STATUS_FILTER_EXTENSION,
];

export const APPOINTMENTS_FILTERS_EXTENSIONS = [
  APPOINTMENT_FACILITY_FILTER_EXTENSION,
  APPOINTMENT_AGENCY_FILTER_EXTENSION,
  APPOINTMENT_STATUS_FILTER_EXTENSION,
  APPOINTMENT_REVIEWED_FILTER_EXTENSION,
  APPOINTMENT_DATE_FILTER_EXTENSION,
  APPOINTMENT_HAS_PENDING_DOCS_FILTER_EXTENSION,
];

export const BILLING_FILTERS_EXTENSIONS = [RATE_CODE_FILTER_EXTENSION];

export const UPDATES_FILTERS_EXTENSIONS = [RATE_CODE_FILTER_EXTENSION];
