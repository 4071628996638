import React, { useState } from "react";
import { Grid, Box, Typography, Menu, MenuItem, IconButton, Divider } from "@material-ui/core";
import MoreVertTwoToneIcon from "@material-ui/icons/MoreVertTwoTone";

import {
  useAction,
  agencyActions,
  useSelector,
  agencySelectors,
  authSelectors,
} from "../../../state";
import { formatToDateTimeMinutes } from "../../../utils";
import { useStyles } from "./MessagesChat.styles";

const MessagesChat = ({ entity = "", isSuperAdmin, setMarkedAsUnread }) => {
  const classes = useStyles();

  const markUnreadMessage = useAction(agencyActions.markUnreadMessage);
  const chatMessages = useSelector(agencySelectors.messages);
  const redLine = useSelector(agencySelectors.redLine);
  const [anchorEl, setAnchorEl] = useState(null);
  const openedAppointment = useSelector(agencySelectors.openedAppointment);
  const appointmentID = openedAppointment.id;
  const activeEntity = useSelector(authSelectors.activeEntity);
  const activeEntityId = activeEntity.id;
  const view = isSuperAdmin
    ? "super_admin"
    : activeEntity.type === "home_health_agency"
    ? "agency_admin"
    : "facility_admin";
  const type = isSuperAdmin ? "super_admin" : activeEntity.type;
  const appointmentFacilityId = openedAppointment.medical_facility?.entity?.id || "";
  const appointmentAgencyId =
    openedAppointment.patient?.home_health_agency_profile?.entity?.id || "";
  const entityId = isSuperAdmin
    ? entity === "medical_facility"
      ? appointmentFacilityId
      : appointmentAgencyId
    : activeEntityId;
  const [selectedMessage, setselectedMessage] = useState(null);
  const handleClick = (event, message) => {
    setselectedMessage(message);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setselectedMessage(null);
    setAnchorEl(null);
  };
  const setUnreadMessage = async (message) => {
    setMarkedAsUnread(true);
    await markUnreadMessage(entityId, appointmentID, message.id, view, entity, isSuperAdmin);
    handleClose();
  };
  return (
    <Box className={classes.customerChatWrapper}>
      {chatMessages?.map((message) => (
        <Box key={message.id} className={classes.chatBlurbWrap}>
          {message.id === redLine && (
            <div>
              <Divider className={classes.divider} variant="fullWidth" />
              <div
                style={{
                  color: "red",
                  textAlign: "right",
                  marginRight: -35,
                  marginTop: -20,
                  paddingBottom: 20,
                }}
              >
                New
              </div>
            </div>
          )}
          <Box
            className={
              type === message.from ? classes.customerChatBlurb : classes.facilityChatBlurb
            }
          >
            <div style={{ display: "flex" }} key={message.id}>
              <Typography className={classes.messageBody}>{message.body}</Typography>
              {type !== message.from && !!message.read_by && (
                <div style={{ marginRight: -20, marginTop: -10 }}>
                  <IconButton onClick={(e) => handleClick(e, message)}>
                    <MoreVertTwoToneIcon />
                  </IconButton>
                </div>
              )}
            </div>
          </Box>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.messageMeta}
          >
            <Grid item>
              <Typography>{`${message?.user?.first_name} ${message?.user?.last_name}`} </Typography>
            </Grid>
            <Grid item>
              <Typography>{formatToDateTimeMinutes(message.message_at)}</Typography>
            </Grid>
          </Grid>
        </Box>
      ))}
      {!!selectedMessage && (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          variant="selectedMenu"
          style={{ marginLeft: 30, marginTop: -10, width: 200 }}
        >
          <MenuItem style={{ height: 5 }}>
            <span style={{ fontSize: 14 }} onClick={() => setUnreadMessage(selectedMessage)}>
              Mark unread
            </span>
          </MenuItem>
        </Menu>
      )}
    </Box>
  );
};

export default MessagesChat;
