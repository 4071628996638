import React from "react";
import { Button } from "@material-ui/core";

import { DownloadIcon } from "../../icons";
import { RouterLink } from "../../../lib";

import { useStyles } from "./ExportButton.styles";

const ExportButton = (props) => {
  const classes = useStyles();
  const to = props.to ?? "";

  return (
    <Button
      variant="contained"
      color="primary"
      component={RouterLink}
      classes={classes}
      startIcon={<DownloadIcon fontSize="small" />}
      {...{ ...props, to }}
    >
      Export
    </Button>
  );
};

export default React.memo(ExportButton);
