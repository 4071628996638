import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Card, Grid, IconButton, TextField, Typography } from "@material-ui/core";
import { useRole } from "../../../../../hooks";
import { EditIcon } from "../../../../../components/icons";
import {
  agencyActions,
  agencySelectors,
  authSelectors,
  useAction,
  useSelector,
} from "../../../../../state";
import { useStyles } from "./AppointmentNotes.styles";

const AppointmentNotes = () => {
  const classes = useStyles();
  const updateAppointmentNote = useAction(agencyActions.updateAppintmentNote);
  const activeEntityId = useSelector(authSelectors.activeEntityId);
  const { isFacilityRole } = useRole();
  const openedAppointment = useSelector(agencySelectors.openedAppointment);
  const [openNotesBox, setOpenNotesBox] = useState(false);
  const [notes, setNote] = useState(openedAppointment?.notes);

  useEffect(() => {
    setNote(openedAppointment?.notes);
  }, [openedAppointment]);

  const handleEdit = useCallback(() => {
    setOpenNotesBox(!openNotesBox);
  }, [openNotesBox]);

  const handleAdd = () => {
    setOpenNotesBox(true);
  };

  const handleChange = useCallback((e) => {
    setNote(e.target.value);
  }, []);

  const handleSaveNotes = useCallback(() => {
    updateAppointmentNote({ ...openedAppointment, notes }, activeEntityId);
    setOpenNotesBox(false);
  }, [updateAppointmentNote, openedAppointment, notes, activeEntityId]);

  const handleCancelNotes = useCallback(() => {
    setNote(openedAppointment?.notes);
    setOpenNotesBox(false);
  }, [openedAppointment]);

  return (
    <Card variant="outlined" className={classes.root}>
      <Grid
        container
        component="header"
        alignItems="center"
        justifyContent="space-between"
        className={classes.titleContainer}
      >
        <Grid item>
          <Typography className={classes.title}>Appointment Notes</Typography>
        </Grid>
        {!!openedAppointment?.notes?.length ? (
          <Grid item>
            <IconButton
              disabled={isFacilityRole && openedAppointment.medical_facility_id !== activeEntityId}
              onClick={handleEdit}
            >
              <EditIcon />
            </IconButton>
          </Grid>
        ) : (
          <>
            {!openNotesBox && (
              <Button
                style={{ textTransform: "capitalize" }}
                color="primary"
                onClick={() => handleAdd()}
                disabled={
                  isFacilityRole && openedAppointment.medical_facility_id !== activeEntityId
                }
              >
                Add note
              </Button>
            )}
          </>
        )}
      </Grid>

      <Box className={classes.notes}>
        {openNotesBox ? (
          <>
            <TextField
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              id="notes"
              name="notes"
              label="Notes"
              value={notes}
              onChange={handleChange}
            />

            <Grid container justify="flex-end" className={classes.actionsWrap} spacing={3}>
              <Grid item>
                <Button variant="outlined" color="primary" onClick={handleCancelNotes}>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSaveNotes}
                  disabled={!notes?.length}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <Typography variant="body1">{notes}</Typography>
        )}
      </Box>
    </Card>
  );
};

export default AppointmentNotes;
