import React, { useState } from "react";
import { Grid, Button, Stepper, Step, StepLabel } from "@material-ui/core";

import { useRole } from "../../../hooks";
import { isError, getActiveStep, getAppointmentSteps } from "./utils";
import { useSelector, agencySelectors } from "../../../state";

import { useStyles } from "./AppointmentStepper.styles";
import ScheduleAppointmentModal from "../../modals/ScheduleAppointmentModal";
import { APPOINTMENT_STATUSES_STATES } from "../../../constants";

const AppointmentStepper = () => {
  const { isAgencyRole } = useRole();
  const { isSuperAdmin } = useRole();
  const [showScheduleModal, setShowScheduleModal] = useState(false);

  const openedAppointment = useSelector(agencySelectors.openedAppointment);

  const { appointment_status_id: status } = openedAppointment;
  const showReschedule =
    (status === APPOINTMENT_STATUSES_STATES.CANCELED ||
      status === APPOINTMENT_STATUSES_STATES.MISSED) &&
    (isAgencyRole || isSuperAdmin);
  const classes = useStyles({ status });
  const [editMode, setEditMode] = useState(false);

  const handleReschedule = () => {
    setEditMode(true);
    setShowScheduleModal(true);
  };

  return (
    <Grid container alignItems="center" justifyContent="space-between" style={{ marginBottom: 20 }}>
      <div className={classes.root}>
        <Stepper activeStep={getActiveStep(status)} className={classes.stepper}>
          {getAppointmentSteps(status).map((label, index) => (
            <Step key={label}>
              <StepLabel error={isError(status, index)}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>

      {showReschedule && (
        <Button color="primary" variant="contained" onClick={handleReschedule}>
          Reschedule
        </Button>
      )}
      {showScheduleModal && (
        <ScheduleAppointmentModal
          open={showScheduleModal}
          setOpen={setShowScheduleModal}
          apptId={openedAppointment.id}
          editMode={editMode}
          setEditMode={setEditMode}
        />
      )}
    </Grid>
  );
};

export default React.memo(AppointmentStepper);
