import React, { useEffect } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { io } from "socket.io-client";
import Pages from "./pages";
import { AuthLayout, MainLayout } from "./layouts";
import { RouteSecurityProvider, history, renderPageRoutes } from "./lib";
import { REACT_APP_SOCKET_URL } from "./config";
import {
  useSelector,
  authSelectors,
  useDispatch,
  uiActions,
  agencyActions,
  useAction,
} from "./state";

export const App = React.memo(function App() {
  const auth = useSelector(authSelectors.route);
  const token = useSelector(authSelectors.token);
  const setSelectedTab = useAction(uiActions.setSelectedTab);
  const dispatch = useDispatch();
  const view = useSelector(authSelectors.activeEntityType);
  const activeEntityId = useSelector(authSelectors.activeEntityId);
  const activeEntityType = useSelector(authSelectors.activeEntityType);
  const oldAuth = useSelector(authSelectors.oldAuth);
  const isSuperAdmin = (activeEntityType === "super_admin" && view === "super_admin") || !!oldAuth;
  useEffect(() => {
    const socket = io(`${REACT_APP_SOCKET_URL}`, {
      transports: ["websocket"],
      auth: { token: token },
    });

    socket.on("error", (reason) => {
      dispatch(uiActions.showError(`Chat error: ${reason}`));
    });

    socket.on("new_message", ({ action }, appointment_id, entity_id, entity) => {
      if (action === "refresh") {
        if (isSuperAdmin) {
          dispatch(
            agencyActions.getUnreadMessageCount(
              entity_id,
              appointment_id,
              view,
              entity,
              isSuperAdmin,
            ),
          );
          dispatch(
            agencyActions.getMessages(entity_id, appointment_id, view, entity, isSuperAdmin),
          );
          setSelectedTab(entity);
        } else {
          dispatch(
            agencyActions.getMessages(
              activeEntityId,
              appointment_id,
              view,
              activeEntityType,
              isSuperAdmin,
            ),
          );
        }
      }
    });
  }, [activeEntityId, activeEntityType, setSelectedTab, dispatch, token, view, isSuperAdmin]);

  return (
    <RouteSecurityProvider auth={auth} check={checkAuth}>
      <Router history={history}>
        <Switch>
          <Route path="/auth">
            <AuthLayout>
              <Switch>{renderPageRoutes(Pages.auth)}</Switch>
            </AuthLayout>
          </Route>
          <Route path="/agency">
            <MainLayout>
              <Switch>{renderPageRoutes(Pages.agency)}</Switch>
            </MainLayout>
          </Route>
          <Route path="/super-admin">
            <MainLayout>
              <Switch>{renderPageRoutes(Pages.superAdmin)}</Switch>
            </MainLayout>
          </Route>
          <Route path="/facility">
            <MainLayout>
              <Switch>{renderPageRoutes(Pages.facilities)}</Switch>
            </MainLayout>
          </Route>
          <Route path="/">
            <MainLayout>
              <Switch>{renderPageRoutes(Pages.main)}</Switch>
            </MainLayout>
          </Route>
        </Switch>
      </Router>
    </RouteSecurityProvider>
  );
});

/**
 * Function that should return true to allow the guarded route to render or
 * false to redirect using the default redirect which was passed to
 * `RouteSecurityProvider` (if any) or a `LocationDescriptor` object to redirect
 * to a specific `pathname` and `search` query.
 *
 * @param {RouteCheckProps<ReturnType<typeof authSelectors.route>>} props
 * @returns {ReturnType<RouteCheckProps["check"]>}
 */
function checkAuth(props: any) {
  const { auth, location, roles: requiredRoles } = props;
  let allowed = false;

  if (auth.isAdmin === true) return true;

  if (requiredRoles) {
    allowed = requiredRoles.includes(auth.activeRole);
  } else {
    allowed = auth.isLoggedIn;
  }

  if (!allowed) {
    // Return a LocationDescriptor object to redirect to login, then back to
    // the current location after login...
    return {
      pathname: Pages.auth.login.path,
      search: "?after=" + location.pathname + location.search,
    };
  }
  // Return true to allow the route to render.
  return true;
}
