import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Button, Typography, AccordionDetails } from "@material-ui/core";
import Summary from "./Accordions/Summary";
import FileUpload from "../../../../../components/forms/FileUpload";
import { AddIcon } from "../../../../../components/icons";
import {
  useAction,
  agencyActions,
  useDispatch,
  useSelector,
  agencySelectors,
  authSelectors,
} from "../../../../../state";
import { useRole } from "../../../../../hooks";
import { currencyFormatter } from "../../../../../utils";
import { RATE_CODE_STATUSES_STATES, APPOINTMENT_STATUSES_STATES } from "../../../../../constants";
import { Accordion, useStyles } from "./AppointmentServices.styles";
import AppointmentRateCodesModal from "./AppointmentRateCodesModal";
import LocationUpdateConfirmationModal from "./LocationUpdateConfirmationModal";

const AppointmentServices = () => {
  const classes = useStyles();
  const {
    isAgencyRole,
    isAgencyScheduler,
    isAgencyAdmin,
    isFacilityAdmin,
    isFacilityIntake,
    isFacilityProvider,
    isFacilityRole,
    isSuperAdmin,
  } = useRole();
  const dispatch = useDispatch();
  const [showAddServiceBtn, setShowAddServiceBtn] = useState(false);
  const [disableAddServiceBtn, setDisableAddServiceBtn] = useState(true);
  let emptyAny: any[] = [];
  const [manualOpen, setManualOpen] = useState(emptyAny);
  const params: { id: string } = useParams();
  const { id: appointmentId } = params;
  const openedAppointment = useSelector(agencySelectors.openedAppointment);
  const openApptRateCodes = useSelector(agencySelectors.openApptRateCodes);
  const openApptBundles = useSelector(agencySelectors.openApptBundles);
  const activeEntityType = useSelector(authSelectors.activeEntityType);
  const getAppointmentByID = useAction(agencyActions.getAppointmentByID);
  const getRateCodeByPatientId = useAction(agencyActions.getRateCodeByPatientId);
  const [open, setOpen] = useState(false);
  const [keepOriginalLocation, setKeepOriginalLocation] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const activeEntityId = useSelector(authSelectors.activeEntityId);
  const [activeFileId, setActiveFileId] = useState(null);
  const [activeFileNames, setActiveFileNames] = useState(null);

  const isAppointmentCanceled =
    openedAppointment.appointment_status_id === APPOINTMENT_STATUSES_STATES.CANCELED;

  const rateCodes = useMemo(
    () => openedAppointment?.appointment_rate_codes || [],
    [openedAppointment?.appointment_rate_codes],
  );
  const handleSetLocation = (updateLocation) => {
    setKeepOriginalLocation(!updateLocation);
    handleRateCodeUpload(activeFileId, activeFileNames, updateLocation);
    setActiveFileId(null);
    setActiveFileNames(null);
    setOpenConfirm(false);
  };
  const providedRateCodes = rateCodes.filter(
    ({ rate_code_status_id: id }) =>
      id === RATE_CODE_STATUSES_STATES.PROVIDED ||
      id === RATE_CODE_STATUSES_STATES.COMPLETED ||
      id === RATE_CODE_STATUSES_STATES.PENDING,
  );

  useEffect(() => {
    if (
      isFacilityAdmin ||
      isFacilityIntake ||
      isFacilityProvider ||
      isAgencyAdmin ||
      isAgencyScheduler ||
      isSuperAdmin
    ) {
      setShowAddServiceBtn(true);
    }
  }, [
    isFacilityAdmin,
    isFacilityIntake,
    isFacilityProvider,
    isAgencyRole,
    isAgencyAdmin,
    isAgencyScheduler,
    isSuperAdmin,
  ]);

  useEffect(() => {
    if (
      openedAppointment.appointment_status_id === APPOINTMENT_STATUSES_STATES.COMPLETED ||
      (activeEntityType === "facility" &&
        activeEntityId !== openedAppointment.medical_facility_id &&
        !isSuperAdmin)
    ) {
      setDisableAddServiceBtn(true);
    } else {
      setDisableAddServiceBtn(false);
    }
  }, [
    activeEntityId,
    activeEntityType,
    isSuperAdmin,
    openedAppointment.appointment_status_id,
    openedAppointment.medical_facility_id,
  ]);

  const shouldExpandAccordion = (service) => {
    return (
      !isAppointmentCanceled &&
      (((service.type === "bundle"
        ? service.home_health_agency_bundle?.form_required
        : service.home_health_agency_rate_code?.form_required) &&
        service.forms.length === 0 &&
        service.rate_code_status_id !== RATE_CODE_STATUSES_STATES.CANCELED &&
        service.rate_code_status_id !== RATE_CODE_STATUSES_STATES.COMPLETED) ||
        (manualOpen.includes(service.id) &&
          service.rate_code_status_id !== RATE_CODE_STATUSES_STATES.COMPLETED))
    );
  };
  const handleRateCodeUpload = useCallback(
    async (id, fileNames, changeLocation = false) => {
      // Get only necessary fields for payload
      const {
        id: rateCodeID,
        appointment_id,
        rate,
        // rate_code_status_id,
        home_health_agency_rate_code,
        home_health_agency_bundle,
        type,
      } = rateCodes.find((code) => code.id === id);

      //Send rate code status to know if should update to pending if theres forms (even if not approved)
      //used to send back completed, but now new uploaded forms aren't approved yet
      // const rateCodeStatusId =
      // rate_code_status_id === RATE_CODE_STATUSES_STATES.COMPLETED
      // ? rate_code_status_id
      // : RATE_CODE_STATUSES_STATES.PENDING;
      if (changeLocation) {
        openedAppointment["updateLocationId"] = activeEntityId;
      }
      await dispatch(
        agencyActions.updateAppointmentRateCode({
          openedAppointment,
          rateCode: {
            // Set only necessary fields for payload
            id: rateCodeID,
            hha_service_id: home_health_agency_rate_code?.id,
            description:
              type === "bundle"
                ? home_health_agency_bundle?.description
                : home_health_agency_rate_code?.default_rate_code?.description,
            filenames: fileNames,
            appointment_id,
            rate,
            rate_code_status_id: RATE_CODE_STATUSES_STATES.PENDING,
          },
          activeEntityId,
        }),
      );
      getAppointmentByID(activeEntityId, +appointmentId, activeEntityType);
      setManualOpen(manualOpen.filter((code) => code !== rateCodeID));
    },
    [
      rateCodes,
      dispatch,
      openedAppointment,
      activeEntityId,
      getAppointmentByID,
      appointmentId,
      activeEntityType,
      manualOpen,
    ],
  );

  const handleRateCodeUploadClick = useCallback(
    async ({ id, fileNames }) => {
      setActiveFileId(id);
      setActiveFileNames(fileNames);
      if (
        activeEntityId !== openedAppointment.medical_facility_id &&
        openedAppointment.appointment_status_id === APPOINTMENT_STATUSES_STATES.SCHEDULED &&
        !keepOriginalLocation &&
        isFacilityRole
      ) {
        setOpenConfirm(true);
        return;
      }
      handleRateCodeUpload(id, fileNames);
    },
    [activeEntityId, handleRateCodeUpload, isFacilityRole, keepOriginalLocation, openedAppointment],
  );

  const providedTotal = useMemo(
    () => providedRateCodes.reduce((acc, code) => acc + Number(code.rate), 0),
    [providedRateCodes],
  );

  const openAddServiceModal = useCallback(() => {
    setOpen(true);
    getRateCodeByPatientId(openedAppointment?.patient?.id, activeEntityId);
  }, [getRateCodeByPatientId, openedAppointment?.patient?.id, activeEntityId]);

  const expandAccordion = (service) => {
    return (
      !isAppointmentCanceled &&
      (manualOpen.includes(service.id) || !!service?.forms?.length) &&
      //only expand for agency role if form is approved
      (!isAgencyRole || service?.forms?.find((f) => f.approved))
    );
  };

  return (
    <Box className={classes.root}>
      <Grid
        container
        component="header"
        alignItems="center"
        justifyContent="space-between"
        className={classes.servicesTitle}
      >
        <Grid item>
          <Typography>Bundles</Typography>
        </Grid>
        {!!providedRateCodes.length && !!providedTotal && (
          <Grid item>
            <Typography>
              Provided Total {currencyFormatter({ value: providedTotal || 0 })}
            </Typography>
          </Grid>
        )}
      </Grid>

      <Box className={classes.serviceListWrap}>
        {openApptBundles.map((bundle) => (
          <Accordion key={`rateCode-${bundle.id}`} square expanded={expandAccordion(bundle)}>
            <Summary
              service={bundle}
              manualOpen={manualOpen}
              setManualOpen={setManualOpen}
              isBundle={true}
            />
            <AccordionDetails>
              <Box className={classes.fileUploadWrap}>
                <FileUpload
                  id={bundle.id}
                  showZone={shouldExpandAccordion(bundle)}
                  accept="application/pdf"
                  uploadType="private"
                  classNames={{ wrap: classes.fileUploadComponentWrap }}
                  onUpload={handleRateCodeUploadClick}
                  // 0 means there is no limitation to how many files are accepted
                  maxFiles={0}
                  multiple
                  downloadEnabled
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      <Grid
        container
        component="header"
        alignItems="center"
        justifyContent="space-between"
        className={classes.servicesTitle}
      >
        <Grid item>
          <Typography>Services</Typography>
        </Grid>
      </Grid>
      <Box className={classes.serviceListWrap}>
        {openApptRateCodes.map((rateCode) => (
          <Accordion key={`rateCode-${rateCode.id}`} square expanded={expandAccordion(rateCode)}>
            <Summary
              service={rateCode}
              manualOpen={manualOpen}
              setManualOpen={setManualOpen}
              isBundle={false}
            />
            <AccordionDetails>
              <Box className={classes.fileUploadWrap}>
                <FileUpload
                  id={rateCode.id}
                  showZone={!rateCode?.forms?.length || manualOpen.includes(rateCode.id)}
                  accept="application/pdf"
                  uploadType="private"
                  classNames={{ wrap: classes.fileUploadComponentWrap }}
                  onUpload={handleRateCodeUploadClick}
                  // 0 means there is no limitation to how many files are accepted
                  maxFiles={0}
                  multiple
                  downloadEnabled
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      {showAddServiceBtn && Boolean(rateCodes.length) && (
        <Button
          color="primary"
          startIcon={<AddIcon fontSize="large" />}
          onClick={openAddServiceModal}
          disabled={disableAddServiceBtn}
        >
          Add Service(s)
        </Button>
      )}

      <LocationUpdateConfirmationModal
        open={openConfirm}
        setOpen={setOpenConfirm}
        setUpdateLocation={handleSetLocation}
      />
      <AppointmentRateCodesModal open={open} setOpen={setOpen} />
    </Box>
  );
};

export default AppointmentServices;
