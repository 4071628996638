import { facility } from "./state";

import { uiActions } from "../ui/actions";
import { authClient } from "../auth/actions";
import { agencyActions, AppThunk } from "..";
import { FACILITIES_API } from "../../constants";

const { actions } = facility;

export const facilityActions = {
  ...actions,
  markAppointmentPaid(entityId, appointmentId, amount) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.put(
        `/facilities/${entityId}/appointment-payment/${appointmentId}`,
        { amount },
      );
      if (status === 200) {
        dispatch(uiActions.showSuccess("Appointment payment has been updated."));
        dispatch(agencyActions.setOpenedAppointment(data));
      } else {
        dispatch(uiActions.showError("Failed to update appointment"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getFileDownloadLink(entityID, appointmentId, rateCodeId): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.get(
        `/facilities/${entityID}/appointments/${appointmentId}/rate_code/${rateCodeId}/form`,
      );
      dispatch(uiActions.setLoading(false));
      if (status === 200) {
        window.open(data.url, "_blank");
      } else {
        dispatch(uiActions.showError("Failed to get file"));
      }
    };
  },
  getPatientByAppointment(entityId, appointmentId): AppThunk<any> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.get(
        `/facilities/${entityId}/appointments/${appointmentId}`,
      );
      if (status === 200) {
        dispatch(agencyActions.setAppointmentPatient(data.patient));
        dispatch(agencyActions.setAppointmentExistingPatientID(data.patient.id));
      } else {
        dispatch(uiActions.showError("Failed to get caregiver"));
      }
      // dispatch load rate codes
      // dispatch setAppointmentPatient

      dispatch(uiActions.setLoading(false));
      return Promise.resolve(data);
    };
  },
  getRateCodeByPatientId() {
    return async (dispatch, getState) => {
      const {
        agency: { appointmentPatient },
        auth: {
          user: { entities },
        },
      } = getState();
      if (!appointmentPatient || !entities) return;
      dispatch(agencyActions.setPatientRateCodesLoading(true));

      const { data, status } = await authClient.get(
        `${FACILITIES_API}/${entities[0].id}/patients/${appointmentPatient.id}/rate-codes`,
      );
      if (status === 200) {
        dispatch(agencyActions.setRateCodes({ rateCodes: data.rateCodeResult }));
        dispatch(agencyActions.setBundles({ bundles: data.bundlesResult }));
      } else {
        dispatch(uiActions.showError("Error loading potential rate codes " + data.message));
      }
      dispatch(agencyActions.setPatientRateCodesLoading(false));

      return data.rows;
    };
  },
  exportFacilityAppointments(entity_id: number) {
    return async (dispatch) => {
      dispatch(agencyActions.setAppointmentsLoading(true));

      await authClient.download(
        `/facilities/${entity_id}/appointments/download`,
        "Appointments.csv",
      );
      dispatch(agencyActions.setAppointmentsLoading(false));
    };
  },
};
