import React, { useEffect, useState } from "react";
import { Button, Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import Modal from "../modals/Modal";
import {
  useAction,
  uiActions,
  agencyActions,
  useSelector,
  agencySelectors,
  authSelectors,
} from "../../state";
import { Form, useFormik } from "../../lib";
import { useStyles } from "./EditAgencyBundle.styles";
import AppointmentTimeSlots from "../forms/CreateAppointmentForms/AppointmentTimeSlots";
import { SCHEDULE_APPOINTMENT_TIME_VALIDATION_SCHEMA as validationSchema } from "../../utils";

interface ScheduleApptProps {
  open: boolean;
  setOpen: any;
  apptId: number | string;
  editMode?: boolean;
  setEditMode: any;
}

const ScheduleAppointmentModal = (props: ScheduleApptProps) => {
  const { open, setOpen, apptId, editMode, setEditMode } = props;
  const classes = useStyles();
  const setLoading = useAction(uiActions.setLoading);
  const getRegions = useAction(agencyActions.getRegions);
  const regions = useSelector(agencySelectors.regions);
  const appointment = useSelector(agencySelectors.openedAppointment);
  const scheduleAppointment = useAction(agencyActions.scheduleAppointment);
  const activeEntityId = useSelector(authSelectors.activeEntityId);
  const view = useSelector(authSelectors.activeEntityType);
  const [facilities, setFacilities] = useState([]);
  const [selectedFacility, setSelectedFacility]: any = useState({});
  const [initialValues, setInitialValues] = useState({
    appointment_id: apptId,
    location: "",
    facility: "",
    timeframe: "",
    appointment_status_id: null,
    original_facility_id: null,
    original_datetime: null,
  });
  useEffect(() => {
    if (editMode && appointment) {
      setInitialValues({
        appointment_id: appointment.id,
        location: appointment.medical_facility_profile?.region_id,
        facility: appointment.medical_facility_profile?.entity_id,
        timeframe: appointment.datetime,
        appointment_status_id: appointment.appointment_status_id,
        original_facility_id: appointment.medical_facility_id,
        original_datetime: appointment.datetime,
      });
    }
  }, [editMode, appointment]);

  useEffect(() => {
    getRegions();
  }, [getRegions, activeEntityId]);

  const form = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: initialValues,
    async onSubmit(values) {
      setLoading(true);
      await scheduleAppointment(values, view, activeEntityId, editMode);
      setLoading(false);
      setOpen(false);
      setEditMode(false);
    },
  });

  useEffect(() => {
    if (form.values.location && regions)
      setFacilities(
        regions.find((region) => region.id === form.values.location)?.medical_facility_profiles ??
          [],
      );
  }, [regions, form.values.location]);

  useEffect(() => {
    if (form.values.facility && facilities) {
      setSelectedFacility(
        facilities.find((facility) => facility.entity_id === form.values.facility),
      );
    }
  }, [form.values.facility, facilities]);

  const handleCloseModal = () => {
    setEditMode(false);
    setOpen(false);
  };

  return (
    <Modal open={open} handleClose={handleCloseModal}>
      <Form form={form}>
        <Typography variant="subtitle2" className={classes.header}>
          Schedule Appointment
        </Typography>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={6}>
            <TextField
              style={{ maxWidth: 275 }}
              variant="outlined"
              select
              name="location"
              id="location"
              label="Region"
              fullWidth
              value={form.values.location || ""}
              onChange={form.handleChange}
              error={form.touched.location && Boolean(form.errors.location)}
              helperText={form.touched.location && form.errors.location}
              disabled={!Boolean(regions.length)}
            >
              {regions.map((region) => (
                <MenuItem id={region.name} key={`location-${region.id}`} value={region.id}>
                  {region.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={6}>
            <TextField
              style={{ maxWidth: 275 }}
              variant="outlined"
              fullWidth
              select
              name="facility"
              id="facility"
              label="Medical facility"
              value={form.values.facility || ""}
              onChange={form.handleChange}
              error={form.touched.facility && Boolean(form.errors.facility)}
              helperText={form.touched.facility && form.errors.facility}
              disabled={!form.values.location}
            >
              {(facilities || [])
                .filter((f) => !!f.entity?.active)
                .map((facility) => (
                  <MenuItem
                    id={facility.entity.name.split(" ").join("-")}
                    key={`facility-${facility.entity_id}`}
                    value={facility.entity_id}
                  >
                    {facility.entity.name}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
        </Grid>
        {editMode && form.values?.facility !== initialValues.facility ? (
          <Typography color="primary" style={{ fontSize: 14 }}>
            Note: Chat messages linked to the original facility will be archived.
          </Typography>
        ) : null}
        {!!selectedFacility ? (
          <Grid container direction="column" spacing={2}>
            <Grid item xs={5} className={classes.field}>
              Select a date
            </Grid>
            <br></br>
            <Grid item xs={10} className={classes.field}>
              <AppointmentTimeSlots
                form={form}
                facility={selectedFacility}
                originalTimeZone={appointment?.medical_facility_profile?.timezone}
              />
            </Grid>
            <Grid item>
              {selectedFacility?.entity?.note && (
                <Typography style={{ marginLeft: 8 }}>{selectedFacility.entity.note}</Typography>
              )}
            </Grid>
          </Grid>
        ) : (
          <div style={{ width: 1000 }}></div>
        )}
        <Grid
          component="footer"
          container
          justifyContent="flex-end"
          alignItems="center"
          classes={{ container: classes.modalFooter }}
        >
          <Grid item>
            <Button color="primary" size="large" onClick={handleCloseModal}>
              Cancel
            </Button>
          </Grid>

          <Grid item>
            <Button color="primary" size="large" type="submit">
              Schedule
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Modal>
  );
};

export default React.memo(ScheduleAppointmentModal);
