import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
} from "@material-ui/core";

import { LaunchIcon } from "../../icons";
import NewMessageBadge from "../NewMessageBadge";

import { useRole } from "../../../hooks";
import AgencyPages from "../../../pages/agency";
import FacilityPages from "../../../pages/facility";
import { useSelector, authSelectors, agencySelectors } from "../../../state";

import { useStyles } from "./MessagesChatList.styles";

const MessagesChatList = ({ entity = "", isSuperAdmin }) => {
  const classes = useStyles();
  const history = useHistory();
  const { isFacilityRole } = useRole();

  const userId = useSelector(authSelectors.userId);
  const chats = useSelector(agencySelectors.chats);

  const handleActivateChat = useCallback(
    (id: number) => {
      history.push(
        isFacilityRole || (isSuperAdmin && entity === "medical_facility")
          ? FacilityPages.appointment.path.replace(":id", `${id}`)
          : AgencyPages.appointment.path.replace(":id", `${id}`),
      );
    },
    [history, isFacilityRole, isSuperAdmin, entity],
  );

  return Boolean(chats?.length) ? (
    <List disablePadding>
      {chats.map((chat) => (
        <ListItem
          key={chat.appt_id}
          divider
          button
          onClick={() => handleActivateChat(chat.appt_id)}
        >
          <ListItemText
            primary={chat.entity_name}
            secondary={`${chat.first_name} ${chat.last_name} - ${chat.appt_id}`}
          />
          <ListItemSecondaryAction>
            <Grid container justifyContent="flex-end" alignItems="center">
              {!(chat.read_by && chat.read_by[userId]) && <NewMessageBadge />}
              <IconButton edge="end">
                <LaunchIcon fontSize="small" />
              </IconButton>
            </Grid>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  ) : (
    <Typography className={classes.noChats}>No chats here yet</Typography>
  );
};

export default MessagesChatList;
