import React from "react";
import { Grid, InputBase, IconButton } from "@material-ui/core";

import { SendIcon } from "../../icons";

import { Form, useFormik, yup } from "../../../lib";
import {
  useAction,
  agencyActions,
  useSelector,
  agencySelectors,
  authSelectors,
} from "../../../state";

import { useStyles } from "./MessagesChatInputArea.styles";

const validationSchema = yup.object({
  message: yup.string().min(1).max(1000),
});

const MessagesChatInputArea = ({ entity = "", isSuperAdmin }) => {
  const classes = useStyles();
  const messageSending = useSelector(agencySelectors.messageSending);
  const openedAppointment = useSelector(agencySelectors.openedAppointment);
  const sendMessage = useAction(agencyActions.sendMessage);
  const setReadMessages = useAction(agencyActions.readMessages);
  const appointmentID = openedAppointment.id;
  const appointmentFacility = openedAppointment.medical_facility?.entity?.name || "";
  const appointmentHomeHealthAgency =
    openedAppointment.patient?.home_health_agency_profile?.entity?.name || "";
  const appointmentFacilityId = openedAppointment.medical_facility?.entity?.id || "";
  const appointmentAgencyId =
    openedAppointment.patient?.home_health_agency_profile?.entity?.id || "";
  const activeEntity = useSelector(authSelectors.activeEntity);
  const activeEntityId = activeEntity.id;
  const view = isSuperAdmin
    ? "super_admin"
    : activeEntity.type === "home_health_agency"
    ? "agency_admin"
    : "facility_admin";
  const entityId = isSuperAdmin
    ? entity === "medical_facility"
      ? appointmentFacilityId
      : appointmentAgencyId
    : activeEntityId;

  const form = useFormik({
    initialValues: { message: "" },
    validationSchema,
    async onSubmit({ message }, { resetForm }) {
      if (message.length > 0) {
        sendMessage(entityId, appointmentID, message, view, entity, isSuperAdmin);
        setReadMessages(entityId, appointmentID, view, entity, isSuperAdmin);
        resetForm();
      }
      document.getElementById("message")?.focus();
    },
  });

  return (
    <Form form={form} className={classes.form}>
      <Grid container>
        <Grid item className={classes.inputWrap}>
          <InputBase
            autoComplete="off"
            autoFocus
            fullWidth
            id="message"
            name="message"
            className={classes.input}
            placeholder={`Message ${
              !isSuperAdmin
                ? "Super Admin"
                : entity === "medical_facility"
                ? appointmentFacility
                : appointmentHomeHealthAgency
            }`}
            value={messageSending ? "Sending..." : form.values.message}
            onChange={form.handleChange}
            error={form.touched.message && Boolean(form.errors.message)}
            disabled={messageSending}
          />
        </Grid>
        <Grid item>
          <IconButton type="submit">
            <SendIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Form>
  );
};

export default MessagesChatInputArea;
