import { createSelector } from "@reduxjs/toolkit";
import { capitalizeFirstLetter } from "../../utils";

import { AppState } from "../types";

const _state = (state: AppState) => state.superAdmin;

// users
const _users = createSelector(_state, (state) => state.users ?? []);
const _usersLoading = createSelector(_state, (state) => state.usersLoading ?? false);

const _agencies = createSelector(_state, (state) => state.agencies);
const _activeAgency = createSelector(_state, (state) => state.activeAgency ?? {});
const _activeDefaultBundle = createSelector(_state, (state) => state.activeDefaultBundle ?? {});
const _agenciesCount = createSelector(_state, (state) => state.agencies.length);
const _agencyBundles = createSelector(_state, (state) => state.agencyBundles ?? []);
const _facilities = createSelector(_state, (state) => state.facilities ?? []);
const _facilitiesCount = createSelector(_state, (state) => state.facilities.length);

const _labResults = createSelector(_state, (state) => state.labResults ?? []);
const _searchQuery = createSelector(_state, (state) => state.searchQuery ?? "");
const _rateCodes = createSelector(_state, (state) => state.rateCodes ?? []);
const _defaultRateCodes = createSelector(_state, (state) => state.defaultRateCodes ?? []);
const _defaultBundles = createSelector(_state, (state) => state.defaultBundles ?? []);
const _defaultRateCode = createSelector(_state, (state) => state.defaultRateCode ?? {});
const _invoices = createSelector(_state, (state) => state.invoices ?? []);
const _facility = createSelector(_state, (state) => state.facility ?? null);
const _labs = createSelector(_state, (state) => state.labs ?? []);
const _appointmentTotalCount = createSelector(_state, (state) => state.appointmentTotalCount ?? 0);
const _regions = createSelector(_state, (state) => state.regions ?? []);
const _availableRoles = createSelector(_state, (state) =>
  (state.availableRoles || []).map((role) => ({
    ...role,
    label: capitalizeFirstLetter(role.name.split("_").join(" ")),
  })),
);
const _bundlesForRateCodes = createSelector(_state, (state) => state.bundlesForRateCodes ?? []);

export const superAdminSelectors = {
  agencies: _agencies,
  activeAgency: _activeAgency,
  agenciesCount: _agenciesCount,
  agencyBundles: _agencyBundles,
  facilities: _facilities,
  facilitiesCount: _facilitiesCount,
  activeDefaultBundle: _activeDefaultBundle,

  labResults: _labResults,
  searchQuery: _searchQuery,
  rateCodes: _rateCodes,
  defaultRateCodes: _defaultRateCodes,
  defaultBundles: _defaultBundles,
  defaultRateCode: _defaultRateCode,
  invoices: _invoices,
  facility: _facility,
  labs: _labs,
  regions: _regions,
  users: _users,
  usersLoading: _usersLoading,
  availableRoles: _availableRoles,
  bundlesForRateCodes: _bundlesForRateCodes,
  appointmentTotalCount: _appointmentTotalCount,
};
