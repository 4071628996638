import React, { useCallback } from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";

import MainMenu from "./MainMenu";

import Notification from "../../components/Notification";

import {
  useAction,
  authActions,
  useSelector,
  authSelectors,
  uiSelectors,
  agencySelectors,
} from "../../state";

import { useStyles } from "./MainLayout.styles";
import Messages from "../../components/messages";

// CONSIDER: Use <Alert /> inside of <Snackbar> to display different types of
// notifications. See https://material-ui.com/components/snackbars/#customized-snackbars

function _MainLayout({ children }) {
  const classes = useStyles();

  const setActiveEntityId = useAction(authActions.setActiveEntityId);
  const exitImpersonateMode = useAction(authActions.exitImpersonateMode);

  const loading = useSelector(uiSelectors.loading);
  const activeEntity = useSelector(authSelectors.activeEntity);
  const oldAuth = useSelector(authSelectors.oldAuth);
  const entities = useSelector(authSelectors.entities);
  const activeEntityId = useSelector(authSelectors.activeEntityId);
  const activeEntityType = useSelector(authSelectors.activeEntityType);
  const openedAppointment = useSelector(agencySelectors.openedAppointment);

  const handleExitImpersonate = useCallback(() => {
    exitImpersonateMode();
  }, [exitImpersonateMode]);

  return (
    <>
      {!!oldAuth && (
        <Typography
          style={{
            backgroundColor: "#E0E0E0",
            textAlign: "center",
            padding: 5,
          }}
        >
          Logged in as {activeEntity?.name}
          <Link onClick={handleExitImpersonate} style={{ marginLeft: 20, cursor: "pointer" }}>
            EXIT
          </Link>
        </Typography>
      )}

      {!!entities && entities.length > 1 && (
        <Box
          style={{
            backgroundColor: "#E0E0E0",
            textAlign: "center",
            padding: 5,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            gap: 16,
            alignItems: "center",
          }}
        >
          <Typography style={{ minWidth: 200, textAlign: "right" }}>Logged in as</Typography>
          <TextField style={{ minWidth: 200 }} select value={activeEntityId}>
            {entities?.map((entity) => (
              <MenuItem
                onClick={() => setActiveEntityId(entity.id)}
                key={entity.id}
                value={entity.id}
              >
                {entity.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      )}

      {!!activeEntity && activeEntityType === "facility" && (
        <Box
          style={{
            backgroundColor: "#E0E0E0",
            textAlign: "center",
            padding: 5,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            gap: 16,
            alignItems: "center",
          }}
        >
          <Typography style={{ minWidth: 200, textAlign: "right" }}>
            Currently offering flu vaccines. | For portal support and assistance contact
            212-863-0599.
          </Typography>
        </Box>
      )}

      <Container className={classes.root} maxWidth="xl" disableGutters={true}>
        <MainMenu />
        <div className={classes.content}>{children}</div>
      </Container>

      <Notification />
      {!!openedAppointment.medical_facility &&
        (activeEntityType !== "facility" ||
          activeEntityId === openedAppointment.medical_facility_id) && <Messages />}
      {loading && (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress size={32} />
        </Backdrop>
      )}
    </>
  );
}

export const MainLayout = React.memo(_MainLayout);
