import React, { useCallback } from "react";

import { CheckIcon, CloseIcon } from "../../icons";
import {
  APPOINTMENT_STATUSES_STATES,
  RATE_CODE_STATUSES_STATES,
  STATUSES_AVAILABILITY,
} from "../../../constants";

import { useStyles } from "./StatusTag.styles";

export const getStatusLabel = (status) => {
  switch (status) {
    case APPOINTMENT_STATUSES_STATES.SCHEDULED:
      return "Scheduled";
    case APPOINTMENT_STATUSES_STATES.UNSCHEDULED:
      return "Unscheduled";
    case APPOINTMENT_STATUSES_STATES.CHECKED_IN:
      return "Pending";
    case APPOINTMENT_STATUSES_STATES.PROVIDED:
      return "Pending";
    case APPOINTMENT_STATUSES_STATES.COMPLETED:
      return "Completed";
    case APPOINTMENT_STATUSES_STATES.CANCELED:
      return "Canceled";
    case APPOINTMENT_STATUSES_STATES.MISSED:
      return "Missed";
    case STATUSES_AVAILABILITY.TRUE:
      return "Active";
    case STATUSES_AVAILABILITY.FALSE:
      return "Inactive";
    default:
      return "-";
  }
};
export const getRCStatusLabel = (status, documents_pending = false) => {
  switch (status) {
    case RATE_CODE_STATUSES_STATES.SCHEDULED:
      return "Scheduled";
    case RATE_CODE_STATUSES_STATES.PENDING:
      return documents_pending ? "Documents Pending" : "Pending";
    case RATE_CODE_STATUSES_STATES.PROVIDED:
      return "Provided";
    case RATE_CODE_STATUSES_STATES.COMPLETED:
      return "Completed";
    case RATE_CODE_STATUSES_STATES.CANCELED:
      return "Canceled";

    case STATUSES_AVAILABILITY.TRUE:
      return "Active";
    case STATUSES_AVAILABILITY.FALSE:
      return "Inactive";
    default:
      return "-";
  }
};

export const StatusStateTag = ({ status }) => {
  const tagStyles = useCallback(() => {
    switch (status) {
      case APPOINTMENT_STATUSES_STATES.COMPLETED:
        return { backgroundColor: "#17A45E", color: "#fff" };
      case APPOINTMENT_STATUSES_STATES.SCHEDULED:
        return { backgroundColor: "#EEEEEE", color: "#000" };
      case APPOINTMENT_STATUSES_STATES.UNSCHEDULED:
        return { backgroundColor: "#733A9A", color: "#fff" };
      case APPOINTMENT_STATUSES_STATES.CHECKED_IN:
        return { backgroundColor: "#1155CC", color: "#fff" };
      case APPOINTMENT_STATUSES_STATES.MISSED:
        return { backgroundColor: "#D11D1D", color: "#fff" };
      case APPOINTMENT_STATUSES_STATES.CANCELED:
        return { backgroundColor: "#403E3E", color: "#fff" };
      case APPOINTMENT_STATUSES_STATES.PROVIDED:
        return { backgroundColor: "#1155CC", color: "#fff" };
      default:
        return { backgroundColor: "#000", color: "#fff" };
    }
  }, [status]);

  const classes = useStyles(tagStyles());

  return <div className={classes.tag}>{getStatusLabel(status)}</div>;
};
export const RCStatusStateTag = ({ status, documents_pending }) => {
  const tagStyles = useCallback(() => {
    switch (status) {
      case RATE_CODE_STATUSES_STATES.COMPLETED:
        return { backgroundColor: "#17A45E", color: "#fff" };
      case RATE_CODE_STATUSES_STATES.SCHEDULED:
        return { backgroundColor: "#EEEEEE", color: "#000" };
      case RATE_CODE_STATUSES_STATES.PENDING:
        return { backgroundColor: documents_pending ? "#733A9A" : "#1155CC", color: "#fff" };
      case RATE_CODE_STATUSES_STATES.CANCELED:
        return { backgroundColor: "#D11D1D", color: "#fff" };
      case RATE_CODE_STATUSES_STATES.PROVIDED:
        return { backgroundColor: "#FFBD20", color: "#000" };
      default:
        return { backgroundColor: "#000", color: "#fff" };
    }
  }, [status, documents_pending]);

  const classes = useStyles(tagStyles());

  return (
    <div className={classes.rcTag} style={{ width: "fit-content" }}>
      {getRCStatusLabel(status, documents_pending)}
    </div>
  );
};

export const renderStatusStateCell = (row) => <StatusStateTag status={row.appointment_status_id} />;

export const StatusAvailabilityTag = ({ status }) => {
  const tagAvailabilityStyles = useCallback(() => {
    switch (status) {
      case STATUSES_AVAILABILITY.ACTIVE:
        return { backgroundColor: "#AFDD45" };
      case STATUSES_AVAILABILITY.INACTIVE:
        return { backgroundColor: "#E91F31" };
      case STATUSES_AVAILABILITY.TRUE:
        return { backgroundColor: "#AFDD45" };
      case STATUSES_AVAILABILITY.FALSE:
        return { backgroundColor: "#E91F31" };
      default:
        return { backgroundColor: "rgba(0, 0, 0, 0.5)" };
    }
  }, [status]);

  const classes = useStyles(tagAvailabilityStyles());

  return <div className={classes.tagAvailability}>{status ? "Active" : "Inactive"}</div>;
};

export const renderStatusAvailabilityCell = (row) => <StatusAvailabilityTag status={row.status} />;
export const renderActiveCell = (row) => <StatusAvailabilityTag status={row.active} />;
export const renderFormAvailable = (row) =>
  !!row.form_location && !!row.filenames_id ? (
    <CloseIcon color="error" titleAccess={row.form_location} />
  ) : row.form_location || row.filenames_id ? (
    <CheckIcon
      htmlColor="green"
      titleAccess={row.form_location ? row.form_location : row.filenames_id}
    />
  ) : (
    <CloseIcon
      color="error"
      titleAccess={row.form_location ? row.form_location : row.filenames_id}
    />
  );
export const renderFormRequired = (row) =>
  row.form_required === null ? (
    <CloseIcon color="error" titleAccess={row.form_required} />
  ) : row.form_required ? (
    <CheckIcon htmlColor="green" titleAccess={row.form_required} />
  ) : (
    <CloseIcon color="error" titleAccess={row.form_required} />
  );
export const renderAptReviewed = (row) =>
  row.reviewed ? (
    <CheckIcon htmlColor="green" titleAccess={row.reviewed.toString()} />
  ) : (
    <CloseIcon color="error" titleAccess={row.reviewed.toString()} />
  );
