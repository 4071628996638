import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { TodayIcon } from "../../../components/icons";
import UpcomingAppointmentsTabs from "../../../components/tabs/UpcomingAppointmentsTabs";
import {
  useAction,
  agencyActions,
  superAdminActions,
  useSelector,
  authSelectors,
} from "../../../state";
import { useRole } from "../../../hooks";
import { TODAY } from "../../../constants";
import { useInputDebounced } from "../../../lib";
import { useStyles } from "./UpcomingAppointmentsPage.styles";
import { SearchField } from "../../../components/inputs";

// TODO add types and interfaces
const UpcomingAppointmentsPage = () => {
  const { isFacilityIntake } = useRole();
  const classes = useStyles({ isFacilityIntake });

  const activeEntity = useSelector(authSelectors.activeEntity);
  const activeEntityId = activeEntity.id;
  const view = activeEntity.type === "home_health_agency" ? "agency" : "facility";
  const getUpcomingAppointments = useAction(agencyActions.getUpcomingAppointments);
  // TODO refactor into separate component/hooks
  const setSearchQuery = useAction(superAdminActions.setSearchQuery);

  const firstName = useSelector(authSelectors.userFirstName);

  const [searchQuery, debouncedSearchQuery, handleChange] = useInputDebounced("", 250);

  useEffect(() => {
    setSearchQuery(debouncedSearchQuery);
  }, [setSearchQuery, debouncedSearchQuery]);

  useEffect(() => {
    getUpcomingAppointments(activeEntityId, view, "updates_fac");
  }, [activeEntityId, getUpcomingAppointments, view]);

  return (
    <Box>
      <Grid container justifyContent="space-between" component="header" className={classes.header}>
        <Grid item>
          <Grid container direction="column">
            <Grid item className={classes.title}>
              <Typography variant="h3" component="h1">
                Welcome, {firstName}!
              </Typography>
            </Grid>

            {!isFacilityIntake && (
              <Grid item className={classes.searchWrap}>
                <SearchField
                  id="search"
                  label="Search for caregiver id or name"
                  value={searchQuery}
                  onChange={handleChange}
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item>
          <Grid container>
            <Typography variant="h6">
              <TodayIcon /> {TODAY}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <UpcomingAppointmentsTabs />
    </Box>
  );
};

export default UpcomingAppointmentsPage;
