import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { formatDateOnly } from "../../../lib";
import { ErrorOutline } from "@material-ui/icons";
import { useDispatch, agencyActions, useSelector, authSelectors } from "../../../state";

// const useStyles = makeStyles((theme) => ({
//   header: {
//     padding: 8,
//     textTransform: "uppercase",
//     fontSize: 16,
//     fontWeight: "bold",
//     letterSpacing: 0.12,
//   },
// }));

const DueDate = ({ styles = {}, service = undefined, appointment = undefined, dueDate }) => {
  const activeEntityId = useSelector(authSelectors.activeEntityId);
  const view = useSelector(authSelectors.activeEntityType);
  const dispatch = useDispatch();
  const [hasPastAppts, setHasPastAppts] = useState(false);
  const [hasFutureAppts, setHasFutureAppts] = useState(false);
  const [showStatusColors, setShowStatusColors] = useState(false);
  useEffect(() => {
    const getFutureAppts = async () => {
      const { pastAppts, futureAppts }: any = await dispatch(
        agencyActions.getFutureAppointments(service, appointment, activeEntityId, view),
      );
      setHasPastAppts(pastAppts);
      setHasFutureAppts(futureAppts);
      setShowStatusColors(true);
    };
    if (service && dueDate) {
      getFutureAppts();
    } else if (!service && dueDate) {
      setShowStatusColors(true);
    }
  }, [service, appointment, activeEntityId, view, dispatch, dueDate]);
  if (!dueDate) return null;
  const today = new Date();
  const serviceDate = new Date(dueDate);
  const formattedSvcDate = formatDateOnly(dueDate);
  const oneMonthFromToday = new Date(today);
  oneMonthFromToday.setMonth(today.getMonth() + 1);
  const twoMonthsFromToday = new Date(today);
  twoMonthsFromToday.setMonth(today.getMonth() + 2);
  const threeMonthsFromToday = new Date(today);
  threeMonthsFromToday.setMonth(today.getMonth() + 3);
  const conditionalPadding = service ? 2 : 0;
  if (showStatusColors) {
    if (hasPastAppts) {
      return (
        <Box
          style={{
            display: "flex",
            alignItems: "end",
            color: "green",
          }}
          p={conditionalPadding}
        >
          <ErrorOutline
            style={{
              marginRight: "3px",
            }}
          />
          Completed
        </Box>
      );
    } else if (hasFutureAppts) {
      return (
        <Box
          style={{
            display: "flex",
            alignItems: "end",
            color: "blue",
          }}
          p={conditionalPadding}
        >
          <ErrorOutline
            style={{
              marginRight: "3px",
            }}
          />
          Due {formattedSvcDate}
        </Box>
      );
    } else if (serviceDate < today) {
      return (
        <Box
          style={{
            display: "flex",
            alignItems: "end",
            color: "#A41717",
          }}
          p={conditionalPadding}
        >
          <ErrorOutline
            style={{
              marginRight: "3px",
            }}
          />
          Overdue {}
        </Box>
      );
    } else if (serviceDate >= today && serviceDate <= oneMonthFromToday) {
      return (
        <Box
          style={{
            display: "flex",
            alignItems: "end",
            color: "#E12727",
          }}
          p={conditionalPadding}
        >
          <ErrorOutline
            style={{
              marginRight: "3px",
            }}
          />
          Due {formattedSvcDate}
        </Box>
      );
    } else if (serviceDate > oneMonthFromToday && serviceDate <= twoMonthsFromToday) {
      return (
        <Box
          style={{
            display: "flex",
            alignItems: "end",
            color: "#e38c09",
          }}
          p={conditionalPadding}
        >
          <ErrorOutline
            style={{
              marginRight: "3px",
            }}
          />
          Due {formattedSvcDate}
        </Box>
      );
    } else if (serviceDate > twoMonthsFromToday && serviceDate <= threeMonthsFromToday) {
      return (
        <Box
          style={{
            display: "flex",
            alignItems: "end",
            color: "#FFBD20",
          }}
          p={conditionalPadding}
        >
          <ErrorOutline
            style={{
              marginRight: "3px",
            }}
          />
          Due {formattedSvcDate}
        </Box>
      );
    }
  }
  return (
    <Box
      style={{
        display: "flex",
        alignItems: "end",
      }}
      p={conditionalPadding}
    >
      Due {formattedSvcDate}
    </Box>
  );
};

export default DueDate;
